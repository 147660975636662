import React from 'react';
import { Redirect } from 'react-router-dom'
import moment from 'moment'

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';

import { useTranslation } from 'react-i18next'

export default function ClientItem(props) {

  const { t } = useTranslation('clients');
  let { client, getClient } = props;

  return (
      <Grid container alignItems="center" className={"p-1"}>
        <Grid item xs={4} md={4} >
          {client.name}
        </Grid>
        <Grid item xs={4} md={3}>
          {client.phone}
        </Grid>
        <Grid item xs={4} md={3}>
          {client.email}
        </Grid>
        <Grid item xs={12} md={2} className={'text-right'}>
          <Tooltip title={t('client_item.view_btn')} enterDelay={700}>
            <Button variant="outlined" color="primary" onClick={() => getClient(client)}>
              <AssignmentIndOutlinedIcon />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
  );
}
