import React from 'react';
import axios from 'axios';
import { withSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';

import { withTranslation } from 'react-i18next';

class PlaceSettings extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      placeInfo: {
        ski: false,
        snowboard: false,
        selfManage: false,
        sendSms: false,
        publicProfile: false,
        publicRequest: false,
        publicRequestMember: false,
        onlinePayments: false,
        overview: false,
        payAtStaff: false,
        hidePhone: false,
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.placeInfo !== this.props.placeInfo) {
      this.setState({ placeInfo: this.props.placeInfo })
    }
  }

  handleChange = name => event => {
    const newVal = event.target.checked;
    this.setState(prevState => ({ placeInfo: { ...prevState.placeInfo, [name]: newVal } }), () => {
      axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.placeLink + '/info', this.state.placeInfo)
        .then(
          res => {
            console.log('handleEditPlaceInfo request : ', res);
            this.props.enqueueSnackbar('Informatii actualizate cu succes.', { variant: 'success' });
            this.props.updatePlaceInfo(res.data);
          },
          error => {
            this.props.enqueueSnackbar('Eroare la actualizarea informatiilor. ' + error, { variant: 'error' });
            console.log('handleEditPlaceInfo request : ', error);
          }
        );
    })
  };

  render() {

    const { t } = this.props;

    return (
      <div>
        <Paper>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" component="h3">
                {t('place_settings.general_settings')}
              </Typography>
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.coach_can_add_classes')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.coach_can_add_classes_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.selfManage}
                onClick={this.handleChange('selfManage')}
                value="selfManage"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Divider />

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.see_the_program')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.see_the_program_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.overview}
                onClick={this.handleChange('overview')}
                value="overview"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Divider />

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.disable_past')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.disable_past_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.disablePast}
                onClick={this.handleChange('disablePast')}
                value="disablePast"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Divider />

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.pay_at_staff')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.pay_at_staff_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.payAtStaff}
                onClick={this.handleChange('payAtStaff')}
                value="payAtStaff"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Divider />

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.hide_phone')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.hide_phone_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.hidePhone}
                onClick={this.handleChange('hidePhone')}
                value="hidePhone"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Divider />

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.sms_notifs')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.sms_notifs_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.sendSms}
                onClick={this.handleChange('sendSms')}
                value="sendSms"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Divider />

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.sms_reminder_notifs')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.sms_reminder_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.sendReminderSms}
                onClick={this.handleChange('sendReminderSms')}
                value="sendReminderSms"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Divider />

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.public_profile')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.public_profile_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.publicProfile}
                onClick={this.handleChange('publicProfile')}
                value="publicProfile"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.online_reservation')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.online_reservation_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.publicRequest}
                onClick={this.handleChange('publicRequest')}
                disabled={!this.props.placeInfo.publicProfile}
                value="publicRequest"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Divider />

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.online_reservation_coach')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.online_reservation_coach_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.publicRequestMember}
                onClick={this.handleChange('publicRequestMember')}
                disabled={true}
                value="publicRequestMember"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

            <Grid item xs={9}>
              <Typography variant="h5">
                {t('place_settings.online_payments')}
              </Typography>
              <Typography variant="body2">
                {t('place_settings.online_payments_description')}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={3} className={"text-right"}>
              <Switch
                checked={this.props.placeInfo.onlinePayments}
                onClick={this.handleChange('onlinePayments')}
                disabled={true}
                value="onlinePayments"
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>

          </Grid>
        </Paper>

      </div>
    );
  }
}

export default withSnackbar(withTranslation(['settings', 'general'])(PlaceSettings))
