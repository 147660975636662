import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import axios from 'axios';

import PlaceTermsEdit from './PlaceTermsEdit';

import { useTranslation } from 'react-i18next'

export default function PlaceTerms(props){

  let { place, updatePlaceInfo } = props;

  const  { t } = useTranslation(['settings', 'general']);

  const [edit, setEdit] = useState(false);

  return (
    <div>
    <Paper>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="h5" component="h3">
            {t('place_terms.terms_cond')}
          </Typography>
        </Grid>
        <Grid item xs={4} className={'text-right'}>
          <Tooltip title={t('settings_page.edit_info')} enterDelay={700}>
            <Button variant="outlined" color="primary" onClick={() => setEdit(true)}>
              <EditOutlinedIcon />
            </Button>
          </Tooltip>
        </Grid>

      </Grid>
    </Paper>

    <PlaceTermsEdit
      open={edit}
      close={() => setEdit(false)}
      place={place}
      updatePlaceInfo={updatePlaceInfo} />

    </div>
  );
}
