import React from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

import { withSnackbar } from 'notistack';
import moment from 'moment'
import { DATETIME_FORMAT } from 'react-big-scheduler'

import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class PlaceInfoEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
      placeInfo : {
        address : '',
        website : '',
        phone : '',
        email: '',
        info : '',
        descr: '',
        termsLink : '',
        startTime : '',
        closeTime : '',
        locations: [],
        activities: [],
        currency: '',
        bookUrl: '',
        packageUrl: ''
      },
      placeInfoError : {
        address : false,
        website : false,
        phone : false,
        email : false,
        info : false,
        startTime : false,
        closeTime : false,
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      this.setState({placeInfo : this.props.placeInfo})
    }
  }

  handleClose() {
    this.setState({placeInfoError : {address : false, website : false, phone : false, email: false, info : false, startTime : false, closeTime : false}});
    this.props.handleCloseEditPlaceInfo();
  }

  handleEditPlaceInfo() {
    if(this.validateModel()){
      axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.placeLink + '/info', this.state.placeInfo)
        .then(
          res => {
            console.log('handleEditPlaceInfo request : ', res);
            this.props.enqueueSnackbar(`${this.props.t('general:notifs.info.success')}`, {variant: 'success'});
            this.props.updatePlaceInfo(res.data);
          },
          error => {
            this.props.enqueueSnackbar("{t('general:notifs.info.error')}" + error, {variant: 'error'});
            console.log('handleEditPlaceInfo request : ', error);
          }
        );
    }
  }

  render() {

    const { t } = this.props;

    const locations = [{enum: 'arenaPlatos', name: 'Arena Platos'}, {enum: 'arieseni', name: 'Arieseni'}, {enum: 'azuga', name: 'Azuga'}, {enum: 'baileHomorod', name: 'Baile Homorod'}, {enum: 'baileTusnad', name: 'Baile Tusnad'},
                      {enum: 'buscatBaisoara', name: 'Buscat Baisoara'}, {enum: 'borsa', name: 'Borsa'}, {enum: 'borsec', name: 'Borsec'}, {enum: 'busteni', name: 'Busteni'}, {enum: 'cavnic', name: 'Cavnic'},
                      {enum: 'feleacu', name: 'Feleacu'}, {enum: 'fagaras', name: 'Fagaras'}, {enum: 'guraHumorului', name: 'Gura Humorului'}, {enum: 'harghitaBai', name: 'Harghita Bai'}, {enum: 'harghitaMadaras', name: 'Harghita Madaras'},
                      {enum: 'izvoare', name: 'Izvoare'}, {enum: 'izvorulMuresului', name: 'Izvorul Muresului'}, {enum: 'marisel', name: 'Marisel'}, {enum: 'miercureaCiuc', name: 'Miercurea Ciuc'}, {enum: 'mogosa', name: 'Mogosa'},
                      {enum: 'munteleMic', name: 'Muntele Mic'}, {enum: 'paltinis', name: 'Paltinis'}, {enum: 'piatraNeamt', name: 'Piatra Neamt'}, {enum: 'parang', name: 'Parang'}, {enum: 'poianaBrasov', name: 'Poiana Brasov'},
                      {enum: 'praid', name: 'Praid'}, {enum: 'predeal', name: 'Predeal'}, {enum: 'ranca', name: 'Ranca'}, {enum: 'semenic', name: 'Semenic'}, {enum: 'sinaia', name: 'Sinaia'},
                      {enum: 'sovata', name: 'Sovata'}, {enum: 'stanaDeVale', name: 'Stana de Vale'}, {enum: 'suior', name: 'Suior'}, {enum: 'sureanu', name: 'Sureanu'}, {enum: 'straja', name: 'Straja'},
                      {enum: 'toplita', name: 'Toplita'}, {enum: 'transalpina', name: 'Transalpina'}, {enum: 'vatraDornei', name: 'Vatra Dornei'}]
    const activities = ['ski', 'snowboard', 'kids']

    return (
      <Dialog fullScreen open={this.props.open} onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <AppBar className={'position-relative'} color="secondary">
          <Grid container justify="center" alignItems="center">
            <Grid item xs={1}>
              <IconButton edge="start" color="inherit" onClick={() => this.handleClose()} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6">
                {t('place_info_edit.edit_info')}
              </Typography>
            </Grid>
            <Grid item xs={3} className={'text-right'}>
              <Button variant="outlined" className={'white-button'} onClick={() => this.handleEditPlaceInfo()}>
                {t('general:buttons.save')}
              </Button>
            </Grid>
          </Grid>
        </AppBar>

        <Container maxWidth="md" className={"mt-4"}>
          <form noValidate>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container>
                <Grid item sm={6} xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="start-date-picker-dialog"
                    fullWidth
                    label= {t('place_info_edit.forum.opening_date')}
                    format="dd-MM-yyyy"
                    error={this.state.placeInfoError.startTime}
                    value={this.state.placeInfo.startTime}
                    onChange = {(event,newValue) => {
                      var newVal = moment(event).format(DATETIME_FORMAT);
                      this.setState(prevState => ({placeInfo : {...prevState.placeInfo, startTime : newVal}}))
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="start-date-picker-dialog"
                    fullWidth
                    label={t('place_info_edit.forum.closing_date')}
                    format="dd-MM-yyyy"
                    error={this.state.placeInfoError.startTime}
                    value={this.state.placeInfo.closeTime}
                    onChange = {(event,newValue) => {
                      var newVal = moment(event).format(DATETIME_FORMAT);
                      this.setState(prevState => ({placeInfo : {...prevState.placeInfo, closeTime : newVal}}))
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="open-time-picker"
                    fullWidth
                    label={t('place_info_edit.forum.opening_time')}
                    error={this.state.placeInfoError.closeTime}
                    value={this.state.placeInfo.startTime}
                    onChange = {(event,newValue) => {
                      console.log(event)
                      var newVal = moment(event).format(DATETIME_FORMAT);
                      this.setState(prevState => ({placeInfo : {...prevState.placeInfo, startTime : newVal}}))
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <KeyboardTimePicker
                    margin="normal"
                    id="end-time-picker"
                    label={t('place_info_edit.forum.closing_time')}
                    fullWidth
                    error={this.state.placeInfoError.closeTime}
                    value={this.state.placeInfo.closeTime}
                    onChange = {(event,newValue) => {
                      var newVal = moment(event).format(DATETIME_FORMAT);
                      this.setState(prevState => ({placeInfo : {...prevState.placeInfo, closeTime : newVal}}))
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
            {this.state.placeInfoError.startTime ? <span className="text-error text-helper" id="pi-title-helper-text">Data de inceput trebuie sa fie inaintea datei de final</span> : '' }
            {this.state.placeInfoError.closeTime ? <span className="text-error text-helper" id="pi-title-helper-text">Ora inceperii programului trebuie sa fie inaintea orei de inchidere</span> : '' }
            <Grid container>
              <Grid item md={4} xs={12}>
                <InputLabel htmlFor="sett-locations" className="text-small select-margin">{t('place_info_edit.forum.locations')}</InputLabel>
                <Select
                  value={this.state.placeInfo.locations}
                  multiple
                  onChange = {(event, newValue) => {
                    var newVal = event.target.value;
                    console.log(newVal)
                    this.setState(prevState => ({placeInfo : {...prevState.placeInfo, locations: newVal}}))
                  }}
                  margin="none"
                  fullWidth
                  renderValue={(selected) => selected.join(', ')}
                  inputProps={{
                    name: 'sett-locations',
                    id: 'sett-locations',
                  }}
                  >
                  {locations.map((location) => (
                    <MenuItem key={location.enum} value={location.enum}>
                      <Checkbox checked={this.state.placeInfo.locations.indexOf(location.enum) > -1} color="primary"/>
                      <ListItemText primary={location.name} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={4} xs={12}>
                <InputLabel htmlFor="sett-activities" className="text-small select-margin">{t('place_info_edit.forum.activities')}</InputLabel>
                <Select
                  value={this.state.placeInfo.activities}
                  multiple
                  onChange = {(event, newValue) => {
                    var newVal = event.target.value;
                    this.setState(prevState => ({placeInfo : {...prevState.placeInfo, activities : newVal}}))
                  }}
                  margin="none"
                  fullWidth
                  renderValue={(selected) => selected.join(', ')}
                  inputProps={{
                    name: 'sett-activities',
                    id: 'sett-activities',
                  }}
                  >
                  {activities.map((a) => (
                    <MenuItem key={a} value={a}>
                      <Checkbox checked={this.state.placeInfo.activities.indexOf(a) > -1} color="primary"/>
                      <ListItemText primary={a} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item md={4} xs={12}>
                <InputLabel htmlFor="sett-currency" className="text-small select-margin">{t('place_info_edit.forum.currency')}</InputLabel>
                <Select
                  value={this.state.placeInfo.currency}
                  onChange = {(event, newValue) => {
                    var newVal = event.target.value;
                    this.setState(prevState => ({placeInfo : {...prevState.placeInfo, currency : newVal}}))
                  }}
                  margin="none"
                  fullWidth
                  inputProps={{
                    name: 'sett-currency',
                    id: 'sett-currency',
                  }}
                  >
                  {['ron', 'eur', 'chf'].map((a) => (
                    <MenuItem key={a} value={a}>{a}</MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <TextField
              autoFocus
              margin="normal"
              required
              fullWidth
              id="pi-title"
              label={t('place_info_edit.forum.address')}
              name="pi-title"
              defaultValue={this.state.placeInfo.address}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, address : newVal}}))
              }}
              error={this.state.placeInfoError.address}
              helperText={this.state.placeInfoError.address ? "{t('place_info_edit.forum.address')}" : ''}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="pi-web"
              label={t('place_info_edit.forum.website')}
              name="pi-web"
              defaultValue={this.state.placeInfo.website}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, website : newVal}}))
              }}
              error={this.state.placeInfoError.website}
              helperText={this.state.placeInfoError.website ? "{t('place_info_edit.forum.website_helper_text')}" : ''}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="pi-email"
              label={t('place_info_edit.forum.email')}
              name="pi-email"
              defaultValue={this.state.placeInfo.email}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, email : newVal}}))
              }}
              error={this.state.placeInfoError.email}
              helperText={this.state.placeInfoError.email ? "{t('place_info_edit.forum.email_helper_text')}" : ''}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="pi-terms"
              label={t('place_info_edit.forum.terms')}
              name="pi-terms"
              defaultValue={this.state.placeInfo.terms}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, terms : newVal}}))
              }}
              error={this.state.placeInfoError.terms}
              helperText={this.state.placeInfoError.terms ? "{t('place_info_edit.forum.email_helper_text')}" : ''}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="pi-tel"
              type="tel"
              label={t('place_info_edit.forum.school_phone_number')}
              name="pi-tel"
              defaultValue={this.state.placeInfo.phone}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, phone : newVal}}))
              }}
              error={this.state.placeInfoError.phone}
              helperText={this.state.placeInfoError.phone ? "{t('place_info_edit.forum.school_phone_number_helper_text')}" : ''}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="pi-bookUrl"
              type="text"
              label={t('place_info_edit.forum.book_url')}
              name="pi-tel"
              defaultValue={this.state.placeInfo.bookUrl}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, bookUrl : newVal}}))
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="pi-pkgUrl"
              type="text"
              label={t('place_info_edit.forum.pkg_url')}
              name="pi-tel"
              defaultValue={this.state.placeInfo.packageUrl}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, packageUrl : newVal}}))
              }}
            />
            <TextField
              margin="normal"
              fullWidth
              multiline
              rows={3}
              id="pi-descr"
              label={t('place_info_edit.forum.descr')}
              name="pi-descr"
              defaultValue={this.state.placeInfo.descr}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, descr: newVal}}))
              }}
              helperText={`${this.state.placeInfo.descr.length} caractere`}
            />
            <TextField
              margin="normal"
              fullWidth
              multiline
              rows={3}
              id="pi-info"
              label={t('place_info_edit.forum.info')}
              name="pi-info"
              defaultValue={this.state.placeInfo.info}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, info : newVal}}))
              }}
            />
          </form>
        </Container>

      </Dialog>
    );
  };

  validateModel(){
    let valid = true;
    this.setState({placeInfoError : {address : false, website : false, phone : false, info : false, startTime : false, closeTime : false}});

    if (moment(this.state.placeInfo.startTime).isSameOrAfter(this.state.placeInfo.closeTime)){
      this.setState(prevState => ({placeInfoError : {...prevState.placeInfoError, startTime : true}}));
      valid = false;
    }
    if (moment(this.state.placeInfo.startTime).hour() > moment(this.state.placeInfo.closeTime).hour()){
      this.setState(prevState => ({placeInfoError : {...prevState.placeInfoError, closeTime : true}}));
      valid = false;
    }
    if (this.state.placeInfo.address.length < 3){
      this.setState(prevState => ({placeInfoError : {...prevState.placeInfoError, address : true}}));
      valid = false;
    }
    if (this.state.placeInfo.phone.length < 3){
      this.setState(prevState => ({placeInfoError : {...prevState.placeInfoError, phone : true}}));
      valid = false;
    }
    if (this.state.placeInfo.website.length < 3){
      this.setState(prevState => ({placeInfoError : {...prevState.placeInfoError, website : true}}));
      valid = false;
    }
    // if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.placeInfo.email)) {
    //   this.setState(prevState => ({placeInfoError : {...prevState.placeInfoError, email : true}}));
    //   valid = false;
    // }

    return valid;
  }
}

export default withSnackbar( withTranslation(['settings', 'general'])(PlaceInfoEdit));
