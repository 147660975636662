import React, {useState} from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import EventItem from './EventItem';
import EventDetails from './EventDetails';

import './style.scss';

import {DATE_FORMAT} from '../utils/index.js'

export default function UserEvents(props) {

  let date = moment(props.date).startOf('day');
  let dateFormat = "DD.MM"

  const [eventDetails, setEventDetails] = useState(false);

  return (
    <div>
      <Paper>
        <Typography variant="h5" component="h3">
          {props.title}
        </Typography>
        {props.events.map((e, i) => {
          return(
            <div>
              <div>
              { (moment(e.start).isSameOrAfter(date, 'day') && !props.prev ) || (moment(e.start).isBefore(date, 'day') && props.prev) ?
                <div>
                  { i >= 1 ?
                      <div>
                        {moment(e.start).isAfter(props.events[i-1].start, 'day') ?
                        <div>
                          <Grid container>
                            <p className={'wrap-success'}>{moment(e.start).format(dateFormat)}</p>
                          </Grid>
                          <Divider  />
                        </div>: ''}
                      </div>
                    :
                    <div>
                      <Grid container>
                        <p className={'wrap-success'}>{moment(e.start).format(dateFormat)}</p>
                      </Grid>
                      <Divider  />
                    </div>
                  }
                  <div key={e.id}>
                    <EventItem
                      event={e}
                      key={e.id}
                      onShowDetails={() => setEventDetails(e)}/>
                  </div>
                </div> : ''
              }
              </div>
            </div>
          )}
        )}
      </Paper>

      <EventDetails
        closeEventDetails={() => setEventDetails(false)}
        eventDetails={eventDetails}
        hidePhone={props.hidePhone} />
    </div>
  );
}
