import React, { useState } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';

export default function UserItem(props) {

  const { t } = useTranslation('users');

  const [open, setOpen] = useState(false);

  return (
      <Grid container justify="center" alignItems="center" className={"p-3"}>
        <Grid item xs={12} md={5} >
          <Typography variant="h6">
            {props.user.name}
          </Typography>
        </Grid>
        <Grid item xs={6} md={1}>
          {t('user_item.' + props.user.level)}
        </Grid>
        <Grid item xs={3} md={2} >
          {t('user_item.' + (props.user.type && props.user.type.toLowerCase()))}
        </Grid>
        <Grid item xs={3} md={1} >
          <p style={{width: '30px', float: 'left'}}>{props.user.category}</p> {props.user.rank} {props.user.star > 0 ? ` (${props.user.star}*)` : ''}
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid container spacing={1} justify="flex-end">
            <Grid item>
              <Tooltip title={t('user_item.edit')}>
                <Button variant="outlined" color="primary" onClick={props.onEditUser}>
                  <EditOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Grid item>
              { !props.user.locked ? <Tooltip title={t('user_item.otp')}>
                <Button variant="outlined" color="primary" onClick={props.onOtpLogin}>
                  <LockOpenIcon />
                </Button>
              </Tooltip> : ''}
            </Grid>
            <Grid item>
              <Tooltip title={t('delete.title')}>
                <Button variant="outlined" color="secondary" onClick={() => setOpen(true)}>
                  <DeleteOutlineOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        {open && <DeleteModal
          open={open}
          user={props.user}
          handleClose={() => setOpen(false)}
          refreshUsers={props.refreshUsers} />}

      </Grid>
  );
}


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteModal = ({user, open, handleClose, refreshUsers}) => {

  const { t, i18n } = useTranslation('users');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [keepHistory, setKeepHistory] = useState(false);

  const deleteUser = () => {
    setLoading(true);
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + user.place.link + '/users/delete', {...user, keepHistory: keepHistory})
      .then(res => {
        setLoading(false);
        refreshUsers(res.data);
        handleClose()
        enqueueSnackbar('Instructor sters cu succes.', {variant: 'success'});
      }, err => {
        setLoading(false);
        enqueueSnackbar('Eroare la stergerea instructorului. ' + err.response.data.error, {variant: 'error'});
        console.log(123)
      });
   }

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <DialogTitle id="alert-dialog-slide-title">{t('delete.title')} {user.name}</DialogTitle>
      <LinearProgress value="100" variant={loading ? 'indeterminate' : 'determinate'} color="secondary"/>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {t('delete.info')}
          <br/>
          {t('delete.keep_history')}
        </DialogContentText>
        <FormControlLabel
          control={
            <Checkbox
              checked={keepHistory}
              onChange={() => setKeepHistory(!keepHistory)}
              name="keepHistory"
              color="primary"
            />
          }
          label={t('delete.keep_history_simple')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('delete.back')}
        </Button>
        <Button onClick={deleteUser} color="secondary" variant="contained">
          {t('delete.btn')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
