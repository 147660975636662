import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PanelFront from'./PanelFront';
import PanelMobile from './PanelMobile';
import PanelSms from './PanelSms';
import PaneliFrame from './PaneliFrame';
import PanelSuport from './PanelSuport';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <Typography
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
      >
        <Box p={3}>{children}</Box>
      </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function createCookie(name, value) {
    document.cookie = name + '=' + value + '; path=/';
}

export default function SupportPage(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (index) => {
      createCookie("selectedTab",index);
      setValue(index);
  };

  useEffect(() => {
      const selectedTabCookie = document.cookie
          .split('; ')
          .find(cookie => cookie.startsWith('selectedTab='));
      if (selectedTabCookie) {
          const selectedIndex = parseInt(selectedTabCookie.split('=')[1]);
          if (!isNaN(selectedIndex) && selectedIndex >= 0 && selectedIndex < tabData.length) {
              setValue(selectedIndex);
          }
      }}, []
  );

  const tabData = [
      { label: "Platforma online", component: <PanelFront /> },
      { label: "Aplicatie mobile", component: <PanelMobile /> },
      { label: "Aplicatie SMS", component: <PanelSms /> },
      { label: "iFrame", component: <PaneliFrame placeLink={props.match.params.placeLink} /> },
      { label: "Suport", component: <PanelSuport /> },
  ];

  return (
      <div className={classes.root}>
        <AppBar position="static">
            <Tabs value={value} centered>
                {tabData.map((tab, index) => (
                    <Tab key={index}
                         label={tab.label}
                         {...a11yProps(index)}
                         onClick={() => handleChange(index)}/>
                ))}
            </Tabs>
        </AppBar>
          {tabData.map((tab, index) => (
              <TabPanel key={index} value={value} index={index}>
                  {tab.component}
              </TabPanel>
          ))}
      </div>
  );
}