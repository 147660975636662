import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import moment from 'moment'
import axios from 'axios';

import PlaceInfoEdit from './PlaceInfoEdit';

import { DATE_FORMAT } from 'react-big-scheduler'

import { withTranslation } from 'react-i18next';

class PlaceInfo extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      openEditPlaceInfoModal : false,
      imgLink: props.placeLink,
    }
  }

  render(){

    const { t } = this.props;

    const onFileChangeHandler = (e) => {
      e.preventDefault();
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.placeLink + '/info/logo', formData)
      .then(
        res => {
          this.setState({imgLink: ''});
          this.setState({imgLink: this.props.placeLink});
          // this.props.enqueueSnackbar('Logo actualizat cu succes.', {variant: 'success'});
        },
        error => {
          // this.props.enqueueSnackbar('Eroare la actualizarea imaginii. ' + error, {variant: 'error'});
        }
      );
    };

    return (
      <div>
      <Paper>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography variant="h5" component="h3">
                {t('place_info.general_info')}
              </Typography>
            </Grid>
            <Grid item xs={4} className={'text-right'}>
              <Tooltip title={t('settings_page.edit_info')} enterDelay={700}>
                <Button variant="outlined" color="primary" onClick={() => this.setState({openEditPlaceInfoModal : true})}>
                  <EditOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item sm={3} xs={12}>
              <Grid container>
                <Grid item sm={12} xs={12}>
                  <img src={process.env.REACT_APP_MAPI_URL + '/public/images/' + this.props.placeLink + '-logo'} alt="logo" width={200} heigh={200}/>
                </Grid>
                <Grid item sm={12} xs={12}>
                  <div className="form-group files color">
                    <label className={'text-error cursor-pointer'} htmlFor="place-logo">{t('place_info.logo')}</label>
                    <input style={{ display: 'none' }} accept="image/*" type="file" className="form-control" name="file" id="place-logo"
                      onChange={onFileChangeHandler}/>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={9} xs={12}>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.locations')}</label> {this.props.placeInfo.locations && this.props.placeInfo.locations.map((l) => {return l + ', '}) }
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.activities')}</label> {this.props.placeInfo.activities && this.props.placeInfo.activities.map((a) => {return a + ', '}) }
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.address')}</label> {this.props.placeInfo.address}
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.website')}</label> {this.props.placeInfo.website}
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.email')}</label> {this.props.placeInfo.email}
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.phone_number')}</label> {this.props.placeInfo.phone}
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info_edit.forum.currency')}</label> {this.props.placeInfo.currency}
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.terms')}</label> {this.props.placeInfo.terms}
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.book_url')}</label> {this.props.placeInfo.bookUrl}
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.pkg_url')}</label> {this.props.placeInfo.packageUrl}
              </Grid>
              <Grid item xs={12}>
                <label className={'pi-width'}>{t('place_info.form')}</label> <a href={`https://snowbuddy.app/ro/iframe/event/book/${this.props.placeLink}`} target="_blank">https://snowbuddy.app/ro/iframe/event/book/{this.props.placeLink}</a>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <label className={'pi-width'}>{t('place_info.descr')}</label> {this.props.placeInfo.descr}
            </Grid>
            <Grid item xs={12}>
              <label className={'pi-width'}>{t('place_info.about_us')}</label> {this.props.placeInfo.info}
            </Grid>
            <Grid item sm={6} xs={12}>
              <label className={'pi-width'}>{t('place_info.opening_date')}</label> {moment(this.props.placeInfo.startTime).format('DD MMM')}
            </Grid>
            <Grid item sm={6} xs={12}>
              <label className={'pi-width'}>{t('place_info.closing_time')}</label> {moment(this.props.placeInfo.closeTime).format('DD MMM')}
            </Grid>
            <Grid item sm={6} xs={12}>
              <label className={'pi-width'}>{t('place_info.opening_time')}</label> {moment(this.props.placeInfo.startTime).format('HH:mm')}
            </Grid>
            <Grid item sm={6} xs={12}>
              <label className={'pi-width'}>{t('place_info.closing_time')}</label> {moment(this.props.placeInfo.closeTime).format('HH:mm')}
            </Grid>
          </Grid>
      </Paper>

      <PlaceInfoEdit
        open={this.state.openEditPlaceInfoModal}
        handleCloseEditPlaceInfo={() => this.setState({openEditPlaceInfoModal : false})}
        placeInfo={this.props.placeInfo}
        placeLink={this.props.placeLink}
        updatePlaceInfo={(newPlaceInfo) => {
          this.props.updatePlaceInfo(newPlaceInfo);
          this.setState({openEditPlaceInfoModal: false})
        }}
      />

      </div>
    );
  }
}

export default  withTranslation(['settings', 'general'])(PlaceInfo);
