import React from 'react';

import Grid from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import PkgItem from './PkgItem'

export default function PkgList(props){

  let { pkgs, setPackageToEdit } = props;

  return (
    <div>
    { pkgs ?
    <Grid xs={12}>
      {pkgs.map((p, i) => {
        return(
          <div key={p.id}>
            <PkgItem
              pkg={p}
              key={p.id}
              setPackageToEdit={setPackageToEdit}/>
            <Divider  />
          </div>
        )}
      )}
    </Grid> :
    <Grid item xs={12} className={"stats-flex text-center"}>
      <CircularProgress color="primary" size={40}/>
    </Grid>}
  </div>
  );
}
