import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import ReactToPrint from 'react-to-print';
import Content from './Content';
import Header from './Header';

const EventTerms = ({ place, staff, eventPackage, clientPackage }) => {

  const componentRef = useRef();
  const [print, setPrint] = React.useState(false);

  return (
    <div>
      <ReactToPrint
        trigger={() =>
          <Button variant="outlined" color="primary" onClick={() => setPrint(true)}>
            <DescriptionOutlinedIcon />
          </Button>
          }
        content={() => componentRef.current}
      />
        <div style={{ display: "none" }}>
          <div ref={componentRef} className={'pdf-page'}>
            <Header place={place} />
            <Content
              terms={place.placeInfo.localTerms}
              eventPackage={eventPackage}
              clientPackage={clientPackage}
              staff={staff}
              print={print} />
          </div>
        </div>
    </div>
  );
};

export default EventTerms
