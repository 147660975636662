import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Collapse from '@mui/material/Collapse';

import pineapple from '../../assets/img/brand/med-icon.png'

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/1600x900/?nature)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    //margin: theme.spacing(1),
    backgroundColor: '#fff',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide(props) {
  const classes = useStyles();

  const { t } = useTranslation(['account']);

  let { otPass, loading, logInSubmit, error, userDetails, otp, setOtp } = props;
  // const [userDetails, setUserDetails] = useState( { username:'', password:'' } );
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [errorOtp, setErrorOtp] = useState(false);
  const [timer, setTimer] = useState(0);

  const requestOtpSubmit = event => {
    event.preventDefault();

    setLoadingOtp(true);
    setErrorOtp('');
    setOtp(false);

    // request otp
    axios.post(process.env.REACT_APP_AUTH_URL + `/otp`, userDetails)
      .then(res => {
        setLoadingOtp(false);
        setOtp(true);
        setTimer(120);
      },
      (error) => {
        setLoadingOtp(false);
        setErrorOtp(error);});
  }

  useEffect(() => {
    const time = setTimeout(() => {
      if (timer > 0)
        setTimer(timer - 1);
      else
        setOtp(false);
    }, 1000);
    return () => clearTimeout(time);
  }, [timer]);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <img src={pineapple} alt="logo" width="100%" />
          </Avatar>
          <br/>
          <Typography component="h1" variant="h5">
            {t('sign_in_page.otp_sign_in')}
          </Typography>
          <form className={classes.form} onSubmit={otp ? logInSubmit : requestOtpSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label={t('sign_in_page.forum.username')}
              name="username"
              autoComplete="username"
              autoFocus
              onChange={(event, newValue) => { userDetails.username = event.target.value }}
            />
           <Collapse in={otp}>
             <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('sign_in_page.forum.otp')}
                type="text"
                onChange={(event, newValue) => { userDetails.password = event.target.value }}/>
            </Collapse>
            {otp ? <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loading ? <CircularProgress color="secondary" size={25} /> : 'Sign In'}
              </Button>
              :
              <Button
                onClick={requestOtpSubmit}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {loadingOtp ? <CircularProgress color="secondary" size={25} /> : 'Get access code'}
              </Button>
            }
            <Grid container>
              <Grid item>
                <Link href="mailto:hello@snowbuddy.app?subject=Hello, vreau un cont!&body=Salut, as dori un cont :) %0d%0a%0d%0aMa numesc ... si sunt de la scoala de schi ... %0d%0aAdresa mea de email este: ... %0d%0aNumarul de telefon: ... %0d%0a%0d%0aMultumesc,%0d%0aO zi buna!" variant="body2">
                  {t('sign_in_page.dont_have_an_account')}
                </Link>
              </Grid>
            </Grid>
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}
