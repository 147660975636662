import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import moment from 'moment'
import axios from 'axios';

import PlaceNotifsEdit from './PlaceNotifsEdit';

import { DATE_FORMAT } from 'react-big-scheduler'

import { withTranslation } from 'react-i18next';

class PlaceNotifs extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      openEditPlaceNotifsModal : false,
    }
  }

  render(){

    const { t } = this.props;

    return (
      <div>
      <Paper>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Typography variant="h5" component="h3">
                {t('place_notifs.msg_notifs')}
              </Typography>
            </Grid>
            <Grid item xs={4} className={'text-right'}>
              <Tooltip title={t('settings_page.edit_info')} enterDelay={700}>
                <Button variant="outlined" color="primary" onClick={() => this.setState({openEditPlaceNotifsModal : true})}>
                  <EditOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <label className={'pi-width'}>{t('place_notifs.add_class')}</label> {this.props.placeInfo.createSmsMsg}
            </Grid>
            <Grid item xs={12}>
              <label className={'pi-width'}>{t('place_notifs.edit_class')}</label> {this.props.placeInfo.updateSmsMsg}
            </Grid>
            <Grid item xs={12}>
              <label className={'pi-width'}>{t('place_notifs.cancel_class')}</label> {this.props.placeInfo.cancelSmsMsg}
            </Grid>
            <Grid item xs={12}>
              <label className={'pi-width'}>{t('place_notifs.request_class')}</label> {this.props.placeInfo.requestSmsMsg}
            </Grid>
            <Grid item xs={12}>
              <label className={'pi-width'}>{t('place_notifs.reminder_class')}</label> {this.props.placeInfo.reminderSmsMsg}
            </Grid>
          </Grid>
      </Paper>

      <PlaceNotifsEdit
        open={this.state.openEditPlaceNotifsModal}
        handleCloseEditPlaceInfo={() => this.setState({openEditPlaceNotifsModal : false})}
        placeInfo={this.props.placeInfo}
        placeLink={this.props.placeLink}
        updatePlaceInfo={(newPlaceInfo) => {
          this.props.updatePlaceInfo(newPlaceInfo);
          this.setState({openEditPlaceNotifsModal: false})
        }}
      />

      </div>
    );
  }
}

export default withTranslation(['settings','general'])(PlaceNotifs);
