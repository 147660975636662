import React from 'react';

import snowflake from '../../../assets/img/snowflake.png';
import { useTranslation} from 'react-i18next'

export default function Copyright() {
  const { t } = useTranslation('general');
  return (
    <p class="margin-0">© {new Date().getFullYear()} SnowBuddy app | <a href="/terms" class="privacy">{t('term_conditions')}</a> | Powered with <img src={snowflake} alt="snowflake" class="me-heart" width="17;"/> by
    <a href="https://iservit.ro" class="privacy"> iServit</a></p>
  );
}
