import React from 'react';
import axios from 'axios';
import moment from 'moment'

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import AreaStats from '../../stats/AreaStats';
import LineStats from '../../stats/LineStats';
import RadialStats from '../../stats/RadialStats';
import '../../stats/style.scss';

import Events from './Events';
// import PaidTill from './PaidTill';
import StatsSettings from './StatsSettings'

import {DATETIME_FORMAT} from '../utils/index.js'

import { withTranslation } from 'react-i18next'

class EventUserPage extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      findParams: {
        date: moment().format(DATETIME_FORMAT),
        userId: this.props.location.state.userId,
        statsDaysNo: 30
      },
      // date: "2020-12-05 23:44:35", userId: 7, statsDaysNo: 30},
      userEvents: [],
      memberInfo: {},
      attendeesStats: false,
      eventsStats: false,
      incomeStats: false,
      packagesStats: false
    }
  }

  componentDidMount(){
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/users/details', {id: this.state.findParams.userId})
    .then(res => {
      this.setState({memberInfo: res.data});
    });
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/event/member-info', this.state.findParams)
    .then(res => {
      this.setState({userEvents: res.data})
    });
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/stats/events', this.state.findParams)
    .then(res => {
      this.setState({eventsStats: res.data});
    });
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/stats/attendees', this.state.findParams)
    .then(res => {
      this.setState({attendeesStats: res.data});
    });
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/stats/income', this.state.findParams)
    .then(res => {
      this.setState({incomeStats: res.data});
    });
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/stats/packages', this.state.findParams)
    .then(res => {
      this.setState({packagesStats: res.data});
    });
  }


  render() {

    const { t } = this.props;

    const updateResource = data => {
      this.setState({memberInfo: data})
    }

    return (
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography variant="h2">
              {this.props.location.state.userName}
            </Typography>
          </Grid>

          {this.props.securityUser.privilege != 'INSTRUCTOR' ?
          <>
          { this.state.attendeesStats && this.state.eventsStats ? <AreaStats
            data={this.state.eventsStats}
            data2={this.state.attendeesStats}
            date={this.state.findParams.date}
            daysNo={this.state.findParams.statsDaysNo}
            title={t('user.event_user_page.classes_participants')}
            size={12}/> :
            <Grid item xs={12} className={"stats-flex text-center"}>
              <CircularProgress color="primary" size={40}/>
            </Grid>}

          { this.state.packagesStats ? <RadialStats
            data={this.state.packagesStats}
            date={this.state.findParams.date}
            daysNo={this.state.findParams.statsDaysNo}
            title={t('user.event_user_page.pakages')}
            size={6}/> :
            <Grid item xs={6} className={"stats-flex text-center"}>
              <CircularProgress color="primary" size={40}/>
            </Grid>}

          { this.state.incomeStats ? <LineStats
            data={this.state.incomeStats}
            date={this.state.findParams.date}
            daysNo={this.state.findParams.statsDaysNo}
            percent={this.state.memberInfo.percent}
            title={t('user.event_user_page.receipts')}
            size={6}/> :
            <Grid item xs={6} className={"stats-flex text-center"}>
              <CircularProgress color="primary" size={40}/>
            </Grid>}
          </> : '' }

          {this.props.securityUser && <Grid item md={6} xs={12}>
            <Events
              title={t('user.event_user_page.next_7_days')}
              events={this.state.userEvents}
              date={this.state.findParams.date}
              prev={false}
              hidePhone={this.props.securityUser.privilege == 'INSTRUCTOR' && this.props.securityUser.username.substring(0, 2) == 'ce'}
            />
          </Grid>}

          {this.props.securityUser && <Grid item md={6} xs={12}>
            <Events
              title={t('user.event_user_page.last_7_days')}
              events={this.state.userEvents}
              date={this.state.findParams.date}
              prev={true}
              hidePhone={this.props.securityUser.privilege == 'INSTRUCTOR' && this.props.securityUser.username.substring(0, 2) == 'ce'}
            />
          </Grid>}
          <Grid item xs={12}>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withTranslation(['schedule'])(EventUserPage);
