import { useEffect } from 'react'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import Cookies from 'js-cookie'

import PlacePageRO from './translation/ro/PlacePage.json'
import PlacePageEN from './translation/en/PlacePage.json'
import PlacePageES from './translation/es/PlacePage.json'

import SettingsRO from './translation/ro/Settings.json'
import SettingsEN from './translation/en/Settings.json'
import SettingsES from './translation/es/Settings.json'

import GeneralRO from './translation/ro/General.json'
import GeneralEN from './translation/en/General.json'
import GeneralES from './translation/es/General.json'

import AccountRO from './translation/ro/Account.json'
import AccountEN from './translation/en/Account.json'
import AccountES from './translation/es/Account.json'

import ClientsRO from './translation/ro/ClientsPage.json'
import ClientsEN from './translation/en/ClientsPage.json'
import ClientsES from './translation/es/ClientsPage.json'

import DashboardRO from './translation/ro/Dasboard.json'
import DashboardEN from './translation/en/Dasboard.json'
import DashboardES from './translation/es/Dasboard.json'

import PackagesRO from './translation/ro/Packages.json'
import PackagesEN from './translation/en/Packages.json'
import PackagesES from './translation/es/Packages.json'

import ReportsRO from './translation/ro/Reports.json'
import ReportsEN from './translation/en/Reports.json'
import ReportsES from './translation/es/Reports.json'

import RequestsRO from './translation/ro/Requests.json'
import RequestsEN from './translation/en/Requests.json'
import RequestsES from './translation/es/Requests.json'

import ScheduleRO from './translation/ro/Schedule.json'
import ScheduleEN from './translation/en/Schedule.json'
import ScheduleES from './translation/es/Schedule.json'

import UsersRO from './translation/ro/Users.json'
import UsersEN from './translation/en/Users.json'
import UsersES from './translation/es/Users.json'

import StatsRO from './translation/ro/Stats.json'
import StatsEN from './translation/en/Stats.json'
import StatsES from './translation/es/Stats.json'

import SupportRO from './translation/ro/Support.json'
import SupportEN from './translation/en/Support.json'
import SupportES from './translation/es/Support.json'

import PlaceComponentsRO from './translation/ro/place_components.json'
import PlaceComponentsEN from './translation/en/place_components.json'
import PlaceComponentsES from './translation/es/place_components.json'


import { Place } from '@material-ui/icons';

const resources = {
    ro: {
        placePage: PlacePageRO,
        settings: SettingsRO,
        general: GeneralRO,
        account: AccountRO,
        clients: ClientsRO,
        dashboard: DashboardRO,
        packages: PackagesRO,
        requests: RequestsRO,
        reports: ReportsRO,
        schedule: ScheduleRO,
        users: UsersRO,
        stats: StatsRO,
        support: SupportRO,
        place_components: PlaceComponentsRO
    },
    en: {
        placePage: PlacePageEN,
        settings: SettingsEN,
        general: GeneralEN,
        account: AccountEN,
        clients: ClientsEN,
        dashboard: DashboardEN,
        packages: PackagesEN,
        requests: RequestsEN,
        reports: ReportsEN,
        schedule: ScheduleEN,
        users: UsersEN,
        stats: StatsEN,
        support: SupportEN,
        place_components: PlaceComponentsEN
    },
    es: {
        placePage: PlacePageES,
        settings: SettingsES,
        general: GeneralES,
        account: AccountES,
        clients: ClientsES,
        dashboard: DashboardES,
        packages: PackagesES,
        requests: RequestsES,
        reports: ReportsES,
        schedule: ScheduleES,
        users: UsersES,
        stats: StatsES,
        support: SupportES,
        place_components: PlaceComponentsES
    }
}

var lang = Cookies.get('language');

const languages = ['ro', 'en', 'es']

const detectionOptions = {
  order: ['cookie'],
  lookupCookie: 'language'
};
const savedLang = Cookies.get('language') || 'ro';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: savedLang,
    // keySeparator: false, // we do not use keys in form messages.welcome
    debug: false,
    whitelist: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: detectionOptions
  });

export default i18n;
