import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';

import { useTranslation, withTranslation } from 'react-i18next'
import { withSnackbar, useSnackbar } from 'notistack';
import moment from 'moment'
import { DATETIME_FORMAT } from 'react-big-scheduler'

import ResourceSchedule from './ResourceSchedule';

export default function Package(props){

  const { t } = useTranslation(['requests', 'general']);
  let { place, eventPackage, resources, handleAddEvent, handleChange, error } = props

  useEffect(() => {
  }, [])

  const prevDay = () => {handleChange('start', moment(eventPackage.start).subtract(1, 'd').format(DATETIME_FORMAT))}

  const nextDay = () => {handleChange('end', moment(eventPackage.end).add(1, 'd').format(DATETIME_FORMAT))}

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {t('assign.planning')}
          </Typography>
          <Divider />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputLabel htmlFor="req-staff" className="text-small select-margin">{t('assign.label.resource')}</InputLabel>
          <Select
            value={eventPackage.resourceId ? eventPackage.resourceId : 0}
            onChange = {(event) => {
              var newVal = event.target.value;
              handleChange('resourceId', newVal);
            }}
            margin="none"
            fullWidth
            inputProps={{
              name: 'req-staff',
              id: 'req-staff',
            }}>
            {eventPackage.pakage.type && resources.map((val, key) => {
              if (eventPackage.type == val.type || val.type === 'all' || eventPackage.pakage.type === 'all')
                return <MenuItem key={key} value={val.id}>{val.name}</MenuItem>
            })}
          </Select>
        </Grid>
        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container alignItems="flex-end">
              <Grid item md={1} xs={2}>
                <i className={'material-icons'} style={{margin: "15px 0", cursor: "pointer"}} onClick={prevDay}>keyboard_arrow_left</i>
              </Grid>
              <Grid item md={5} xs={10}>
                <KeyboardDatePicker
                  margin="normal"
                  id="start-date-picker-dialog"
                  fullWidth
                  label={t('assign.label.date_start')}
                  autoOk
                  disableToolbar
                  format="MM/dd/yyyy"
                  error={error.date}
                  value={eventPackage.start}
                  onChange = {(event,newValue) => {
                    var newVal = moment(event).hour(0).format(DATETIME_FORMAT);
                    handleChange('start', newVal);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item md={5} xs={10}>
                <KeyboardDatePicker
                  margin="normal"
                  id="end-date-picker-dialog"
                  fullWidth
                  label={t('assign.label.date_end')}
                  autoOk
                  disableToolbar
                  format="MM/dd/yyyy"
                  error={error.date}
                  value={eventPackage.end}
                  onChange = {(event,newValue) => {
                    var newVal = moment(event).hour(23).format(DATETIME_FORMAT);
                    handleChange('end', newVal);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
              <Grid item md={1} xs={2}>
                <i className={'material-icons'} style={{margin: "15px 0", cursor: "pointer"}} onClick={nextDay}>keyboard_arrow_right</i>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
          {error.date ? <span className="text-error text-helper" id="evt-title-helper-text">{t('assign.error.date')}</span> : '' }
        </Grid>
        <Grid item xs={12}>
          <ResourceSchedule
            eventPackage={eventPackage}
            place={place}
            resources={resources}
            addEvent={(date) => handleAddEvent(date)}
            handleChange={handleChange}/>
        </Grid>
      </Grid>
    </Grid>
  );
}
