import React from 'react';
import axios from 'axios';
import { withSnackbar } from 'notistack';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';


import PlaceInfo from './PlaceInfo'
import PlaceNotifs from './PlaceNotifs'
import PlaceSettings from './PlaceSettings'
import PlaceTerms from './PlaceTerms'

import { withTranslation } from 'react-i18next';

class SettingsPage extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      place: {
        placeInfo: {
          ski: false,
          snowboard: false,
          selfManage: false,
          sendSms: false,
          sendReminderSms: false,
          publicProfile: false,
          publicRequest: false,
          publicRequestMember: false,
          overview: false
        }
      },
    }
  }

  componentDidMount() {
    //this.setState({place: {"id":4,"placeInfo":{"selfManage":true,"publicProfile":false,"publicRequest":true,"publicRequestMember":false,"id":5,"info":null,"address":"str. Brancusi, nr.4, la baza partiei, Brasov, Romania","website":"https://snowbuddy.app","phone":"0741123456","placeId":"sb","startTime":"2019-12-01 09:00:00","closeTime":"2020-04-30 21:00:00"},"name":"Snow Buddy","link":"snow-buddy","active":false}})
    axios.get(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink)
      .then(res => {
        this.setState({place : res.data});
      });
  }

  render() {

    const { t } = this.props;

    return (
      <div>
        <CssBaseline />
        <Container maxWidth="lg">
          { this.state.place && <Grid container className={"p-3"} spacing={3}>
            <Grid item xs={12} md={10}>
              <Typography variant="h4">
                {t('settings_page.settings')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <PlaceInfo
                placeInfo={this.state.place.placeInfo}
                updatePlaceInfo={(newPlaceInfo) => this.setState(prevState => ({place: {...prevState.place, placeInfo: newPlaceInfo}}))}
                placeLink={this.props.match.params.placeLink} />
            </Grid>
            <Grid item xs={12}>
              <PlaceNotifs
                placeInfo={this.state.place.placeInfo}
                updatePlaceInfo={(newPlaceInfo) => this.setState(prevState => ({place: {...prevState.place, placeInfo: newPlaceInfo}}))}
                placeLink={this.props.match.params.placeLink} />
            </Grid>
            <Grid item xs={12}>
              <PlaceTerms
                place={this.state.place}
                updatePlaceInfo={(newPlaceInfo) => this.setState(prevState => ({place: {...prevState.place, placeInfo: newPlaceInfo}}))} />
            </Grid>
            <Grid item xs={12}>
              <PlaceSettings
                placeInfo={this.state.place.placeInfo}
                updatePlaceInfo={(newPlaceInfo) => this.setState(prevState => ({place: {...prevState.place, placeInfo: newPlaceInfo}}))}
                placeLink={this.props.match.params.placeLink}/>
            </Grid>
          </Grid>}
        </Container>
      </div>
    );
  }
}

export default withTranslation(['settings'])(SettingsPage);
