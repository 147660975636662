import React from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

import { withSnackbar } from 'notistack';
import moment from 'moment'
import { DATETIME_FORMAT } from 'react-big-scheduler'

import { withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class PlaceNotifsEdit extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
      placeInfo : {
        createSmsMsg : '',
        updateSmsMsg : '',
        cancelSmsMsg : '',
        requestSmsMsg: '',
        reminderSmsMsg: '',
      },
      placeNotifsError : {
        createSmsMsg: false,
        updateSmsMsg: false,
        cancelSmsMsg: false,
        requestSmsMsg: false,
        reminderSmsMsg: false
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      this.setState({placeInfo : this.props.placeInfo})
    }
  }

  handleClose() {
    this.setState({placeNotifsError : {createSmsMsg: false, updateSmsMsg: false, cancelSmsMsg: false, requestSmsMsg: false, reminderSmsMsg: false}});
    this.props.handleCloseEditPlaceInfo();
  }

  handleEditPlaceInfo() {
    if(this.validateModel()){
      axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.placeLink + '/info', this.state.placeInfo)
        .then(
          res => {
            console.log('handleEditPlaceInfo request : ', res);
            this.props.enqueueSnackbar("{this.props.t('general:info.success')}", {variant: 'success'});
            this.props.updatePlaceInfo(res.data);
          },
          error => {
            this.props.enqueueSnackbar("{t('general:info.error')}" + error, {variant: 'error'});
            console.log('handleEditPlaceInfo request : ', error);
          }
        );
    }
  }

  render() {

    const { t } = this.props;

    return (
      <Dialog fullScreen open={this.props.open} onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <AppBar className={'position-relative'} color="secondary">
          <Grid container justify="center" alignItems="center">
            <Grid item xs={1}>
              <IconButton edge="start" color="inherit" onClick={() => this.handleClose()} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6">
                {t('place_notifs_edit.edit_msg_notifs')}
              </Typography>
            </Grid>
            <Grid item xs={3} className={'text-right'}>
              <Button variant="outlined" className={'white-button'} onClick={() => this.handleEditPlaceInfo()}>
                t{('general:buttons.edit')}
              </Button>
            </Grid>
          </Grid>
        </AppBar>

        <Container maxWidth="md" className={"mt-4"}>
          <form noValidate>
            <TextField
              margin="normal"
              fullWidth
              multiline="true"
              rows={3}
              id="pi-createSmsMsg"
              label={t('place_notifs_edit.forum.sms_add')}
              name="pi-createSmsMsg"
              value={this.state.placeInfo.createSmsMsg}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, createSmsMsg : newVal}}))
              }}
              error={this.state.placeNotifsError.createSmsMsg}
              helperText={this.state.placeNotifsError.createSmsMsg ? `Lungimea trebuie sa fie mai mare decat 0. Ai completat ${this.state.placeInfo.createSmsMsg.length} caractere` : `${this.state.placeInfo.createSmsMsg.length} caractere`}
            />

            <TextField
              margin="normal"
              fullWidth
              multiline="true"
              rows={3}
              id="pi-updateSmsMsg"
              label={t('place_notifs_edit.forum.sms_edit')}
              name="pi-updateSmsMsg"
              value={this.state.placeInfo.updateSmsMsg}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, updateSmsMsg : newVal}}))
              }}
              error={this.state.placeNotifsError.updateSmsMsg}
              helperText={this.state.placeNotifsError.updateSmsMsg ? `Lungimea trebuie sa fie mai mare decat 0. Ai completat ${this.state.placeInfo.updateSmsMsg.length} caractere` : `${this.state.placeInfo.updateSmsMsg.length} caractere`}
            />

            <TextField
              margin="normal"
              fullWidth
              multiline="true"
              rows={3}
              id="pi-cancelSmsMsg"
              label={t('place_notifs_edit.forum.sms_cancel')}
              name="pi-cancelSmsMsg"
              value={this.state.placeInfo.cancelSmsMsg}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, cancelSmsMsg : newVal}}))
              }}
              error={this.state.placeNotifsError.cancelSmsMsg}
              helperText={this.state.placeNotifsError.cancelSmsMsg ? `Lungimea trebuie sa fie mai mare decat 0. Ai completat ${this.state.placeInfo.cancelSmsMsg.length} caractere` : `${this.state.placeInfo.cancelSmsMsg.length} caractere`}
            />

            <TextField
              margin="normal"
              fullWidth
              multiline="true"
              rows={3}
              id="pi-requestSmsMsg"
              label={t('place_notifs_edit.forum.sms_request')}
              name="pi-cancelSmsMsg"
              value={this.state.placeInfo.requestSmsMsg}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, requestSmsMsg : newVal}}))
              }}
              error={this.state.placeNotifsError.requestSmsMsg}
              helperText={this.state.placeNotifsError.requestSmsMsg ? `Lungimea trebuie sa fie mai mare decat 0. Ai completat ${this.state.placeInfo.requestSmsMsg.length} caractere` : `${this.state.placeInfo.requestSmsMsg.length} caractere`}
            />

            <TextField
              margin="normal"
              fullWidth
              multiline="true"
              rows={3}
              id="pi-reminderSmsMsg"
              label={t('place_notifs_edit.forum.sms_reminder')}
              name="pi-reminderSmsMsg"
              value={this.state.placeInfo.reminderSmsMsg}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({placeInfo : {...prevState.placeInfo, reminderSmsMsg : newVal}}))
              }}
              error={this.state.placeNotifsError.reminderSmsMsg}
              helperText={this.state.placeNotifsError.reminderSmsMsg ? `Lungimea trebuie sa fie mai mare decat 0. Ai completat ${this.state.placeInfo.reminderSmsMsg.length} caractere` : `${this.state.placeInfo.reminderSmsMsg.length} caractere`}
            />
          </form>
          <Grid item xs={12}>
            <Typography>
             {t('place_notifs_edit.forum.autocomplete.variables')}
              <ul>
                <li>{t('place_notifs_edit.forum.autocomplete.staff_name')}</li>
                <li>{t('place_notifs_edit.forum.autocomplete.start_date')}</li>
                <li>{t('place_notifs_edit.forum.autocomplete.end_date')}</li>
                <li>{t('place_notifs_edit.forum.autocomplete.team_name')}</li>
              </ul>
              <p>{t('place_notifs_edit.forum.autocomplete.info')})</p>
            </Typography>
          </Grid>
        </Container>

      </Dialog>
    );
  };

  validateModel(){
    let valid = true;
    this.setState({placeNotifsError : {createSmsMsg: false, updateSmsMsg: false, cancelSmsMsg: false}});

    if (!this.state.placeInfo.createSmsMsg.length > 0){
      this.setState(prevState => ({placeNotifsError : {...prevState.placeNotifsError, createSmsMsg : true}}));
      valid = false;
    }
    if (!this.state.placeInfo.updateSmsMsg.length > 0){
      this.setState(prevState => ({placeNotifsError : {...prevState.placeNotifsError, updateSmsMsg : true}}));
      valid = false;
    }
    if (!this.state.placeInfo.cancelSmsMsg.length > 0){
      this.setState(prevState => ({placeNotifsError : {...prevState.placeNotifsError, cancelSmsMsg : true}}));
      valid = false;
    }
    if (!this.state.placeInfo.requestSmsMsg.length > 0){
      this.setState(prevState => ({placeNotifsError : {...prevState.placeNotifsError, requestSmsMsg : true}}));
      valid = false;
    }
    if (!this.state.placeInfo.reminderSmsMsg.length > 0){
      this.setState(prevState => ({placeNotifsError : {...prevState.placeNotifsError, reminderSmsMsg : true}}));
      valid = false;
    }

    return valid;
  }

}

export default withSnackbar(withTranslation(['settings', 'general'])(PlaceNotifsEdit));
