import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';

import { useTranslation, withTranslation } from 'react-i18next'
import { withSnackbar, useSnackbar } from 'notistack';
import moment from 'moment'
import { DATETIME_FORMAT } from 'react-big-scheduler'

import Header from './Header';
import Details from './Details';
import Package from './Package';
import Resource from './Resource';
import Client from './Client';

const HUMAN_FORMAT = 'DD.MM HH:mm';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EventPackage(props){

  const { t } = useTranslation(['requests', 'general']);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { open, handleClose, event, place, packages, resources, refreshData } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const [eventPackage, setEventPackage] = useState(props.eventPackage)
  const [editInfo, setEditInfo] = useState(false);
  const [fetchedClient, setFetchedClient] = useState(undefined);                // keep in memory the client fetched in title if exist

  const getPackage = id => {for (var key in packages) {if (packages[key].id === id) return packages[key];}}
  const getPackageType = id => {for (var key in packages) {if (packages[key].id === id) return packages[key].type;}}

  useEffect(() => {
    handleFetch()}, [])

  const handleFetch = () => {
    if (eventPackage.id != undefined){
      setLoading(true);
      axios.post(process.env.REACT_APP_API_URL + `/staff/place/${place.link}/event/request/info`, {eventPackageId: eventPackage.id})
        .then(res => {
          setEventPackage(res.data)
          if (event && event.status == 'FREE')
            handleAddEvent(event);
          setLoading(false)
        },
        error => {
          setLoading(false)
          enqueueSnackbar(t('assign.error.find_package') + error.response.data.error, {variant: 'error'});
          handleClose();});}}

  const handleSubmit = () => {
    if(!loading){
      setLoading(true);
      axios.post(process.env.REACT_APP_API_URL + `/staff/place/${place.link}/event/request/assign`, {...eventPackage})
        .then(res => {
          refreshData();
          handleClose();
          setLoading(false)
          enqueueSnackbar(t('assign.status.saved'), {variant: 'success'});
        },
        error => {
          refreshData();
          setLoading(false)
          enqueueSnackbar(t('assign.error.saved_package') + error.response.data.error, {variant: 'error'});});}}

  const handleFree = () => {
    setLoading(true);
    event.location = eventPackage.location;
    axios.post(process.env.REACT_APP_RAPI_URL + `/staff/place/${place.link}/event/free`, event)
      .then(res => {
        refreshData();
        handleClose();
        setLoading(false)
        enqueueSnackbar(t('assign.free'), {variant: 'success'});
      },
        error => {
          setLoading(false)
          enqueueSnackbar(t('assign.error.open_hour') + error.response.data.error, {variant: 'error'});});}

  const handleCancel = () => {
    setLoading(true);
    axios.post(process.env.REACT_APP_RAPI_URL + `/staff/place/${place.link}/event/request/cancel`, {...eventPackage})
      .then(res => {
        refreshData();
        handleClose();
        setLoading(false)
        enqueueSnackbar(t('assign.status.canceled'), {variant: 'success'});
      },
        error => {
          setLoading(false)
          enqueueSnackbar(t('assign.error.cancel_package') + error.response.data.error, {variant: 'error'});});}

  const handleAddEvent = evt => {
    const events = eventPackage.events;
    let i = contains(evt);
    if (i >= 0){
      events.splice(i, 1);
      handleChange('events', events);
    }else if(events.length < eventPackage.pakage.availability || eventPackage.moreHours){
      events.push(evt)
      handleChange('events', events);}}

  const contains = event => {
    for (var key in eventPackage.events) {
      if (eventPackage.events[key].start === event.start)
        return key;
      }
      return -1;}

  const handleChange = (field, value) => {
    setEventPackage({ ...eventPackage, [field]: value })}

  // de verificat -----
  const selectPackage = (pakage) => {
    // am pus price ca sa fie si in Client :) sa nu trebuiasca sa l transmit altfel da cumva formula asta ar trebui sa fie valabila si acolo | pentru cand se schimba pachetul si ar trebui useEffect si acolo pt price
    // let price = eventPackage.pakage.pkgPrice ? eventPackage.pakage.price : eventPackage.pakage.price * (eventPackage.spots;
    let type = eventPackage.type != undefined ? eventPackage.type : pakage.type[0];
    setEventPackage({...eventPackage, type: type, pakage: pakage})
  }

  // de verificat -----
  useEffect(() => {
    if (eventPackage.pakage.id > 0){
      setEventPackage({ ...eventPackage, mmm: 'value' })
      // am pus price ca sa fie si in Client :) sa nu trebuiasca sa l transmit altfel da cumva formula asta ar trebui sa fie valabila si acolo | pentru cand se schimba pachetul si ar trebui useEffect si acolo pt price
      // let price = eventPackage.pakage.pkgPrice ? eventPackage.pakage.price : eventPackage.pakage.price * (eventPackage.spots;
      let type = eventPackage.type != undefined ? eventPackage.type : eventPackage.pakage.type[0];
      setEventPackage({...eventPackage, type: type})
    }
  }, [eventPackage.pakage, eventPackage.spots])

  return (
    <Dialog fullScreen open={open} onClose={() => handleClose()} TransitionComponent={Transition}>

      <Header
        place={place}
        resources={resources}
        eventPackage={eventPackage}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        handleFree={handleFree}
        handleCancel={handleCancel}
        loading={loading}
        event={event} />

      <LinearProgress value={100} style={{padding: 3}} variant={loading ? 'indeterminate' : 'determinate'} color="primary"/>

      <Container maxWidth="md" className={"mt-4"}>
        <form noValidate>
          <Grid container spacing={3}>

            <Details
              eventPackage={eventPackage}
              handleChange={handleChange}
              setClient={setFetchedClient}
              place={place} />

            {place && <Package
              eventPackage={eventPackage}
              packages={packages}
              handleChange={handleChange}
              selectPackage={selectPackage}
              place={place} />}

            {eventPackage.pakage.id && <Resource
              place={place}
              eventPackage={eventPackage}
              resources={resources}
              handleChange={handleChange}
              handleAddEvent={handleAddEvent}
              error={error} />}

            {eventPackage.pakage.id && <Client
              place={place}
              eventPackage={eventPackage}
              resources={resources}
              fetchedClient={fetchedClient}
              handleChange={handleChange}
              handleAddEvent={handleAddEvent}
              error={error} />}

          </Grid>
        </form>
      </Container>
    </Dialog>
  );
};

  // validateModel(){
  //   let valid = true;
  //   // this.setState({eventError : {title : false, pakage : false, info : false, phone : false, spots : false, price : false} })
  //   // let workingHoursStart = moment(this.state.eventToEdit.start).hour(8).minute(-1).format(DATETIME_FORMAT);
  //   // let workingHoursEnd = moment(this.state.eventToEdit.start).hour(21).minute(1).format(DATETIME_FORMAT);
  //   // console.log(workingHoursStart, workingHoursEnd);
  //   // if (moment(this.state.eventToEdit.start).isSameOrAfter(this.state.eventToEdit.end)){
  //   //   this.setState(prevState => ({eventError : {...prevState.eventError, date : true}}));
  //   //   valid = false;
  //   // }
  //   // if (!moment(this.state.eventToEdit.start).isBetween(workingHoursStart, workingHoursEnd) || !moment(this.state.eventToEdit.end).isBetween(workingHoursStart, workingHoursEnd)){
  //   //   this.setState(prevState => ({eventError : {...prevState.eventError, time : true}}));
  //   //   valid = false;
  //   // }
  //   // if (this.state.eventToEdit.title.length < 3 || this.state.eventToEdit.title.length > 254){
  //   //   this.setState(prevState => ({eventError : {...prevState.eventError, title : true}}));
  //   //   valid = false;
  //   // }
  //   // if (this.state.eventToEdit.pakage.id === 0 ){
  //   //   this.setState(prevState => ({eventError : {...prevState.eventError, pakage : true}}));
  //   //   valid = false;
  //   // }
  //   // if (this.state.eventToEdit.phone < 1 ){
  //   //   this.setState(prevState => ({eventError : {...prevState.eventError, phone : true}}));
  //   //   valid = false;
  //   // }
  //   // if (this.state.eventToEdit.spots < 1 ){
  //   //   this.setState(prevState => ({eventError : {...prevState.eventError, spots : true}}));
  //   //   valid = false;
  //   // }
  //   // if (this.state.eventToEdit.price < 0 ){
  //   //   this.setState(prevState => ({eventError : {...prevState.eventError, price : true}}));
  //   //   valid = false;
  //   // }
  //   return valid;
  // }
