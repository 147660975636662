import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Container from '@material-ui/core/Container'
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import PrintIcon from '@material-ui/icons/Print';
import Tooltip from '@material-ui/core/Tooltip';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';

import { useTranslation, withTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';
import moment from 'moment'
import { DATETIME_FORMAT } from 'react-big-scheduler'

import ResourceSchedule from './ResourceSchedule';
import ThermoPrint from '../thermoPrint/ThermoPrint';
import PrintTerms from '../terms/PrintTerms'

export default function Client(props){

  const { t } = useTranslation(['requests', 'general']);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let { place, eventPackage, resources, fetchedClient, handleChange, error } = props

  const [selected, setSelected] = useState({});
  const [askDelete, setAskDelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lid, setLid] = useState(1);

  // good one but it doesn't update in ui
  // useEffect(() => {
  //   console.log(eventPackage.clientPackages && eventPackage.clientPackages.length == 1)
  //   if(eventPackage.clientPackages && eventPackage.clientPackages.length == 1){
  //     let client = {...eventPackage.clientPackages[0],
  //       attendees: eventPackage.pakage.spots,
  //       payAmount: eventPackage.pakage.pkgPrice ? eventPackage.pakage.price : eventPackage.pakage.price * eventPackage.pakage.spots}
  //     handleChange('clientPackages', [client])
  //     setSelected(client);
  //   }
  //   console.log(eventPackage.pakage)
  // }, [eventPackage.pakage])

  useEffect(() => {
    if(fetchedClient != undefined && eventPackage.clientPackages.length == 0)
      addClient();
  }, [fetchedClient])

  const updateClient = (field, value, clientPkg) => {
    const newState = eventPackage.clientPackages.map(c => {
      if ((c.id === clientPkg.id && c.id) || (c.lid === clientPkg.lid && c.lid)){
        if (field.includes('phone') || field.includes('email') || field.includes('address') || field.includes('city'))
          return {...c, client: {...c.client, [field]: value}};
        if (field == 'attendees')
          return {...c, [field]: value, payAmount: eventPackage.pakage.pkgPrice ? eventPackage.pakage.price : (value * eventPackage.pakage.price)}
        return {...c, [field]: value};}
      return c;
    });

    handleChange('clientPackages', newState);
  };

  const addClient = () => {
    let client = {lid: lid, attendees: eventPackage.pakage.spots,
      payAmount: eventPackage.pakage.pkgPrice ? eventPackage.pakage.price : eventPackage.pakage.price * eventPackage.pakage.spots,
      payMethod: 'card', level: 'BEGINNER'}
    setLid(lid + 1);
    if(eventPackage.clientPackages.length == 0){
      client = fetchedClient && fetchedClient.id ? {...client, client: fetchedClient, title: fetchedClient.name} : {...client, title: eventPackage.title};
    }
    setSelected(client);
    handleChange('clientPackages', [...eventPackage.clientPackages, client])
  }

  const deleteClient = (clientPkg) => {
    if (clientPkg.id > 0){
      setLoading(true);
      axios.post(process.env.REACT_APP_RAPI_URL + `/staff/place/${place.link}/clients/client-package/delete`, clientPkg)
      .then(res => {
        setLoading(false)
        enqueueSnackbar(t('notifs.user.delete_success'), {variant: 'success'});
        let clients = eventPackage.clientPackages.filter(item => (item.lid && item.lid != clientPkg.lid) || (item.id && item.id != clientPkg.id))
        handleChange('clientPackages', clients);
      },
      error => {
        setLoading(false)
        enqueueSnackbar(t('notifs.user.delete_error') + error.response.data.error, {variant: 'error'});})
    }else{
      let clients = eventPackage.clientPackages.filter(item => (item.lid && item.lid != clientPkg.lid) || (item.id && item.id != clientPkg.id))
      handleChange('clientPackages', clients);
      enqueueSnackbar(t('notifs.user.delete_success'), {variant: 'success'});}
    }

  const getStaff = id => {for (var key in resources) {if (resources[key].id === id) return resources[key];}}

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={9} md={10}>
          <Typography variant="h6">
            {t('assign.client')}
          </Typography>
          <Divider />
        </Grid>
        <Grid item xs={3} md={2} className={"text-right"}>
          <Tooltip title={t('assign.btn.client_add')} enterDelay={700}>
            <Button variant="outlined" color="primary" onClick={() => addClient()}>
              <PersonAddOutlinedIcon />
            </Button>
          </Tooltip>
        </Grid>
        {eventPackage.clientPackages.map(clientPkg => { return(
          <Grid item xs={12}>
            <Accordion expanded={(clientPkg.id != undefined && selected.id === clientPkg.id) || (clientPkg.lid != undefined && selected.lid === clientPkg.lid)}>
              <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {selected === clientPkg ? setSelected({}) : setSelected(clientPkg)}} />
                }
                sx={{ cursor: 'unset !important' }}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                  <Grid item md={5} xs={12}>
                    <Typography>{clientPkg.title}</Typography>
                  </Grid>
                  <Grid item md={1} xs={12}>
                    <Typography>{clientPkg.attendees} pers.</Typography>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <Typography color={clientPkg.paid ? 'primary' : 'secondary'}>{clientPkg.payAmount} | {clientPkg.payMethod}</Typography>
                  </Grid>
                  {!(askDelete && askDelete === clientPkg) &&
                    <Grid item className={'text-right'}>
                      <ThermoPrint
                        eventPackage={eventPackage}
                        clientPackage={clientPkg}
                        staff={getStaff(eventPackage.resourceId) ? getStaff(eventPackage.resourceId) : {}}
                        place={place}
                        tooltip={t('assign.btn.client_flyer')} />
                    </Grid>}
                  {!(askDelete && askDelete === clientPkg) &&
                    <Grid item className={'text-right'}>
                      <PrintTerms
                        clientPackage={clientPkg}
                        eventPackage={eventPackage}
                        staff={getStaff(eventPackage.resourceId) ? getStaff(eventPackage.resourceId) : {}}
                        place={place}
                        tooltip={t('assign.btn.client_terms')} />
                    </Grid>}
                  <Grid item className={'text-right'}>
                    {askDelete && askDelete === clientPkg ? <Button variant="contained" color="secondary" onClick={() => deleteClient(clientPkg)} disabled={loading}>
                          {t('notifs.user.delete_client')}
                    </Button> :
                    <Tooltip title={t('assign.btn.client_delete')} enterDelay={700}>
                      <Button variant="outlined" color="secondary" onClick={() => {setAskDelete(clientPkg); setTimeout(() => {setAskDelete(false)}, 2000);}}>
                        <DeleteOutlineOutlinedIcon />
                      </Button>
                    </Tooltip>}
                  </Grid>
                </Grid>
              </AccordionSummary>

              <AccordionDetails>

                <Grid container spacing={1}>
                  <Grid item md={4} xs={12}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="cp-title"
                      type="text"
                      label="Nume"
                      name="cp-title"
                      value={clientPkg.title}
                      onChange = {(event, newValue) => {
                        var newVal = event.target.value;
                        updateClient('title', newVal, clientPkg);
                      }}
                      error={error.title}
                      helperText={error.title ? `${t('assign.number_of_persons_helper_text')}` : ''}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="c-phone"
                      type="text"
                      label="Telefon"
                      name="c-phone"
                      value={clientPkg.client ? clientPkg.client.phone : ''}
                      onChange = {(event, newValue) => {
                        var newVal = event.target.value;
                        updateClient('phone', newVal, clientPkg);
                      }}
                      error={error.phone}
                      helperText={error.phone ? `${t('assign.number_of_persons_helper_text')}` : ''}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <TextField
                      margin="normal"
                      fullWidth
                      id="c-email"
                      type="text"
                      label={t('general:form.email')}
                      name="c-email"
                      value={clientPkg.client ? clientPkg.client.email : ''}
                      onChange = {(event, newValue) => {
                        var newVal = event.target.value;
                        updateClient('email', newVal, clientPkg);
                      }}
                      error={error.email}
                      helperText={error.email ? `${t('assign.number_of_persons_helper_text')}` : ''}
                    />
                  </Grid>
                  <Grid item md={1} xs={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="req-att"
                        type="number"
                        label={t('assign.number_of_persons')}
                        name="req-att"
                        value={clientPkg.attendees}
                        disabled={clientPkg.payDate}
                        onChange = {(event, newValue) => {
                          var newVal = event.target.value;
                          updateClient('attendees', newVal, clientPkg);
                        }}
                        error={error.attendees}
                        helperText={error.attendees ? `${t('assign.number_of_persons_helper_text')}` : ''}
                      />
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <InputLabel htmlFor="req-level" className="text-small select-margin">{t('assign.level.info')}</InputLabel>
                      <Select
                        value={clientPkg.level ? clientPkg.level : ''}
                        onChange = {(event, newValue) => {
                          var newVal = event.target.value;
                          updateClient('level', newVal, clientPkg);
                        }}
                        margin="none"
                        fullWidth
                        inputProps={{
                          name: 'req-pay',
                          id: 'req-pay',
                        }}
                        >
                        <MenuItem key={''} value={''}></MenuItem>
                        <MenuItem key={'BEGINNER'} value={'BEGINNER'}>{t('assign.level.beginner')}</MenuItem>
                        <MenuItem key={'INTERMEDIATE'} value={'INTERMEDIATE'}>{t('assign.level.intermediate')}</MenuItem>
                        <MenuItem key={'ADVANCED'} value={'ADVANCED'}>{t('assign.level.advanced')}</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="c-info"
                        type="text"
                        label="Info's"
                        name="c-info"
                        value={clientPkg ? clientPkg.info : ''}
                        onChange = {(event, newValue) => {
                          var newVal = event.target.value;
                          updateClient('info', newVal, clientPkg);
                        }}
                        error={error.info}
                        helperText={error.info ? `${t('assign.number_of_persons_helper_text')}` : ''}
                      />
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="req-price"
                        type="number"
                        label={t('assign.bill')}
                        name="req-price"
                        value={clientPkg.payAmount}
                        disabled={clientPkg.payDate}
                        onChange = {(event, newValue) => {
                          var newVal = event.target.value;
                          updateClient('payAmount', newVal, clientPkg);
                        }}
                        error={error.price}
                        helperText={error.price ? `${t('assign.bill_helper_text')}` : ''}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <InputLabel htmlFor="req-pay" className="text-small select-margin">{t('assign.pay_method.pay')}</InputLabel>
                      <Select
                        value={clientPkg.payMethod ? clientPkg.payMethod : ''}
                        disabled={clientPkg.payDate}
                        onChange = {(event, newValue) => {
                          var newVal = event.target.value;
                          updateClient('payMethod', newVal, clientPkg);
                        }}
                        margin="none"
                        fullWidth
                        inputProps={{
                          name: 'req-pay',
                          id: 'req-pay',
                        }}
                        >
                        <MenuItem key={''} value={''}></MenuItem>
                        <MenuItem key={'cash'} value={'cash'}>{t('assign.pay_method.cash')}</MenuItem>
                        <MenuItem key={'card'} value={'card'}>{t('assign.pay_method.card')}</MenuItem>
                        <MenuItem key={'online'} value={'online'}>{t('assign.pay_method.online')}</MenuItem>
                        <MenuItem key={'staffCash'} value={'staffCash'}>{t('assign.pay_method.staff_cash')}</MenuItem>
                        <MenuItem key={'staffCard'} value={'staffCard'}>{t('assign.pay_method.staff_card')}</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item md={3} xs={12} style={{margin: "auto", padding: "0 0 0 40px", height: "100%", width: "100%" }}>
                      {!clientPkg.payDate && <FormControlLabel
                        control={
                          <Checkbox
                            disabled={clientPkg.payDate}
                            checked={clientPkg.paid}
                            onChange = {(event, newValue) => {
                              var newVal = event.target.checked;
                              updateClient('paid', newVal, clientPkg);
                            }}
                            color="primary"
                          />
                        }
                        label={t('assign.payment_checker')}
                      />}
                      {clientPkg.payDate && <p>{t('assign.payment_checker')}: {moment(clientPkg.payDate).format("hh:mm / DD.MM")}</p>}
                    </Grid>
                    <Grid item md={3} xs={12} style={{margin: "auto", padding: "0 0 0 40px", height: "100%", width: "100%" }}>
                      {(!clientPkg.invoiceNumber || clientPkg.invoiceNumber == '0') && <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!clientPkg.paid || !place.placeInfo.invoiceSeries || clientPkg.invoiceNumber > '0' || !clientPkg.client || !clientPkg.client.email || !clientPkg.client.phone || !clientPkg.client.address || !clientPkg.client.city }
                            checked={clientPkg.invoiceNumber == '0' ? true : false}
                            onChange = {(event, newValue) => {
                              var newVal = event.target.checked;
                              updateClient('invoiceNumber', newVal ? '0' : undefined, clientPkg);
                            }}
                            color="primary"
                          />
                        }
                        label={t('assign.invoice_checker')}
                      />}
                      {clientPkg.invoiceNumber > '0' && <p>{t('assign.invoice_checker')}: {place.placeInfo.invoiceSeries}{clientPkg.invoiceNumber}</p>}
                    </Grid>
                    <Grid item md={8} xs={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="c-address"
                        type="text"
                        label={t('assign.address')}
                        name="c-info"
                        value={clientPkg.client ? clientPkg.client.address : ''}
                        onChange = {(event, newValue) => {
                          var newVal = event.target.value;
                          updateClient('address', newVal, clientPkg);
                        }}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        margin="normal"
                        fullWidth
                        id="c-city"
                        type="text"
                        label={t('assign.city')}
                        name="c-city"
                        value={clientPkg.client ? clientPkg.client.city : ''}
                        onChange = {(event, newValue) => {
                          var newVal = event.target.value;
                          updateClient('city', newVal, clientPkg);
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

          </Grid>)})}
      </Grid>
    </Grid>
  );
}
