import React, {useEffect, useState} from 'react';
import axios from 'axios';
import { withSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';

import ResourceSchDay from './ResourceSchDay';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { DATETIME_FORMAT } from 'react-big-scheduler'

export default function ResourceSchedule(props){

  const { t } = useTranslation('schedule');
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { resources, eventPackage, place, addEvent, setAssigned, handleChange } = props;
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resource, setResource] = useState({});

  const getStaff = id => {for (var key in resources) {if (resources[key].id === id) { setResource(resources[key]); return resources[key];}}}

  useEffect(() => {
    if(getStaff(eventPackage.resourceId)){
      setLoading(true);
      axios.post(process.env.REACT_APP_API_URL + `/staff/place/${place.link}/event`, eventPackage)
        .then(res => {
          // group response by day
          var occurrenceDay = function(occurrence){
              return moment(occurrence.start).startOf('day').format();};

          var groupToDay = function(group, day){
              return {
                  day: day,
                  events: group}};

          var result = _.chain(res.data.events)
              .groupBy(occurrenceDay)
              .map(groupToDay)
              .sortBy('day')
              .value();

          // add all selected days
          let diff = moment(eventPackage.end).diff(moment(eventPackage.start), 'day') + 1;
          for (let i=0; i < diff; i++){
            if (!result.some(r => {
                return (moment(r.day).diff(moment(eventPackage.start).add(i,'day'), 'day') == 0)
                })
              )
              result.push({day: moment(eventPackage.start).add(i,'day').format(DATETIME_FORMAT), events:[]})
          }

          setDays(_.orderBy(result, ['day'],['asc']))
          setLoading(false);
        },
        error => {
          enqueueSnackbar(t('error_check') + error.response.errorMsg, {variant: 'error'});
          setLoading(false);
        });}

  }, [eventPackage.resourceId, eventPackage.start, eventPackage.end])

  return (
    <Grid container spacing={1}>

      <Grid item xs={12}>
          {loading && <LinearProgress value={loading ? 100 : 0} variant={loading ? 'indeterminate' : 'determinate'} color="primary"/>}
      </Grid>

      {resource.instructor && days.map((day, i) => {
          return(
            <ResourceSchDay
              day={day}
              key={i}
              place={place}
              eventPackage={eventPackage}
              handleClick={(day, hour) => {console.log(day,hour)}}
              events={eventPackage.events}
              addEvent={addEvent}
            />
          )
        })}

    </Grid>
  );
}

// alternative grouping function
// const groups = res.data.reduce((groups, item) => {
//   const group = (groups[item.group] || []);
//   group.push(item);
//   groups[item.group] = group;
//   return groups;
// }, {});
