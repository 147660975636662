import React from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';

import UserSchedule from './edit/UserSchedule';

import { withSnackbar } from 'notistack';

import { withTranslation } from 'react-i18next'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UserAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      userToEdit: {
        level: 'instructor',
        type: 'ski',
        name: '',
        username: '',
        phone: '',
        category: '',
        rank: '',
        selfSchedule: true,
        instructor: false,
        locked: false
      },
      userToEditError: {},
      loadingRequest: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      this.setState({ userToEdit: this.props.user }, () => {
        this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, password: '' } }));
      })
    }
  }

  handleClose() {
    this.props.handleCloseEditUser();
  }

  handleEditUser() {
    if (this.validateModel()) {
      this.setState({ loadingRequest: true });
      axios.put(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.placeLink + '/users', this.state.userToEdit)
        .then(
          res => {
            this.setState({ loadingRequest: false });
            this.props.enqueueSnackbar(`${this.props.t('general:notifs.user.edit_success')}`, { variant: 'success' });
            this.props.refreshUsers(res.data);
          },
          error => {
            this.props.enqueueSnackbar(`${this.props.t('general:notifs.user.edit_error')}` + error, { variant: 'error' });
            this.setState({ loadingRequest: false });
          }
        );
    }
  }

  render() {

    const { t } = this.props;

    return (
      <Dialog fullScreen open={this.props.open} onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <AppBar className={'position-relative'} color="primary">
          <Grid container justify="center" alignItems="center">
            <Grid item xs={1}>
              <IconButton edge="start" color="inherit" onClick={() => this.handleClose()} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6">
                {t('user_edit_and_add.edit_user')}
              </Typography>
            </Grid>
            <Grid item xs={3} className={'text-right'}>
              <Button variant="outlined" className={'white-button'} disabled={this.state.loadingRequest} onClick={() => this.handleEditUser()}>
                {t('general:buttons.save')}
              </Button>
            </Grid>
          </Grid>
        </AppBar>
        <LinearProgress value="100" variant={this.state.loadingRequest ? 'indeterminate' : 'determinate'} color="primary" />
        <Container maxWidth="md" className={"mt-4"}>
          <form noValidate>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  autoFocus
                  margin="normal"
                  required
                  fullWidth
                  id="usr-name"
                  label={t('user_edit_and_add.name')}
                  name="usr-name"
                  value={this.state.userToEdit.name}
                  onChange={(event, newValue) => {
                    var newVal = event.target.value;
                    this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, name: newVal } }))
                  }}
                  error={this.state.userToEditError.name}
                  helperText={this.state.userToEditError.name ? `${t('user_edit_and_add.name_helper_text')}` : ''}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="usr-phone"
                  label={t('user_edit_and_add.phone_number')}
                  name="usr-phone"
                  type="tel"
                  value={this.state.userToEdit.phone}
                  onChange={(event, newValue) => {
                    var newVal = event.target.value;
                    this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, phone: newVal } }))
                  }}
                  error={this.state.userToEditError.phone}
                  helperText={this.state.userToEditError.phone ? `${t('user_edit_and_add.phone_number_helper_text')}` : ''}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <label>{t('user_edit_and_add.teaching.teaching_what')}</label>
                <Select
                  value={this.state.userToEdit.type}
                  onChange={(event, newValue) => {
                    var newVal = event.target.value;
                    this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, type: newVal } }))
                  }}
                  margin="none"
                  fullWidth
                  inputProps={{
                    name: 'usr-type',
                    id: 'usr-type',
                  }}>
                  <MenuItem value={'ski'}>{t('user_edit_and_add.teaching.ski')}</MenuItem>
                  <MenuItem value={'snowboard'}>{t('user_edit_and_add.teaching.snowboarding')}</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={9}>
                <Typography variant="h5">
                  {t('user_edit_and_add.locked.title')}
                </Typography>
                <Typography variant="body2">
                  {t('user_edit_and_add.locked.description')}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={3} className={"text-right"}>
                <Switch
                  checked={this.state.userToEdit.locked}
                  onClick={() => this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, locked: !this.state.userToEdit.locked } }))}
                  value="locked"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>

              <Grid item xs={9}>
                <Typography variant="h5">
                  {t('user_edit_and_add.status.activ')}
                </Typography>
                <Typography variant="body2">
                  {t('user_edit_and_add.status.description')}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={3} className={"text-right"}>
                <Switch
                  checked={this.state.userToEdit.instructor}
                  onClick={() => this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, instructor: !this.state.userToEdit.instructor } }))}
                  value="instructor"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>

              <Grid item xs={12}>
                {this.state.userToEdit.instructor ? <div>
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    id="usr-category"
                    label={t('user_edit_and_add.category')}
                    name="usr-category"
                    value={this.state.userToEdit.category}
                    onChange={(event, newValue) => {
                      var newVal = event.target.value;
                      this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, category: newVal } }))
                    }}
                    error={this.state.userToEditError.category}
                    helperText={this.state.userToEditError.category ? `${t('user_edit_and_add.category_helper_text')}` : ''}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    required
                    id="usr-rank"
                    label={t('user_edit_and_add.rank')}
                    name="usr-rank"
                    type="number"
                    value={this.state.userToEdit.rank}
                    onChange={(event, newValue) => {
                      var newVal = event.target.value;
                      this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, rank: newVal } }))
                    }}
                    error={this.state.userToEditError.rank}
                    helperText={this.state.userToEditError.rank ? `${t('user_edit_and_add.rank_helper_text')}` : ''}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="usr-percent"
                    label={t('user_edit_and_add.percentage')}
                    name="usr-percent"
                    value={this.state.userToEdit.percent}
                    onChange={(event, newValue) => {
                      var newVal = event.target.value;
                      this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, percent: newVal } }))
                    }}
                  />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="usr-star"
                    label={t('user_edit_and_add.star')}
                    name="usr-star"
                    value={this.state.userToEdit.star}
                    onChange={(event, newValue) => {
                      var newVal = event.target.value;
                      this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, star: newVal } }))
                    }}
                  /> </div> : ''}
                </Grid>
              </Grid>
            </form>

          {this.state.userToEdit.level === 'instructor' ? <UserSchedule
            schedule={this.state.userToEdit.schedule}
            handleSchClick={(day, hour) => this.handleSchClick(day, hour)}
            selfSchedule={this.state.userToEdit.selfSchedule}
            handleSelfSchClick={(newVal) => { console.log(newVal); this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, selfSchedule: newVal } })) }}
          /> : ''}
        </Container>
      </Dialog>
    );
  };


  validateModel() {
    let valid = true;
    this.setState({ userToEditError: { name: false } });

    if (this.state.userToEdit.name.length < 4 || this.state.userToEdit.name.length > 254) {
      this.setState(prevState => ({ userToEditError: { ...prevState.userToEditError, name: true } }));
      valid = false;
    }
    if (this.state.userToEdit.password !== '' && (this.state.userToEdit.password.length < 9 || this.state.userToEdit.password.length > 12)) {
      this.setState(prevState => ({ userToEditError: { ...prevState.userToEditError, password: true } }));
      valid = false;
    }
    if (this.state.userToEdit.phone.length < 10 || this.state.userToEdit.phone.length > 13) {
      this.setState(prevState => ({ userToEditError: { ...prevState.userToEditError, phone: true } }));
      valid = false;
    }
    if (this.state.userToEdit.level === 'instructor' && (this.state.userToEdit.category.length < 1 || this.state.userToEdit.category.length > 10)) {
      this.setState(prevState => ({ userToEditError: { ...prevState.userToEditError, category: true } }));
      valid = false;
    }
    if (this.state.userToEdit.level === 'instructor' && (this.state.userToEdit.rank.length < 1 || this.state.userToEdit.rank.length > 5)) {
      this.setState(prevState => ({ userToEditError: { ...prevState.userToEditError, rank: true } }));
      valid = false;
    }
    return valid;
  }

  // schedule functions --------------------------------------------------------

  handleSchClick(day, hour) {
    this.changes = true;
    const localSch = this.state.userToEdit.schedule;
    const index = localSch[day].indexOf(hour, 0);
    if (index > -1) {
      localSch[day].splice(index, 1);
    } else {
      localSch[day] = this.insert(localSch[day], this.sortedIndex(localSch, day, hour), hour);
    }
    console.log(localSch);
    this.setState(prevState => ({ userToEdit: { ...prevState.userToEdit, schedule: localSch } }));
  }

  insert = (arr, index, newItem) => {
    return [
      ...arr.slice(0, index),
      newItem,
      ...arr.slice(index)
    ]
  }

  sortedIndex(schedule, day, hour) {
    var low = 0,
      high = schedule[day].length;
    if (high == 0)
      return 0;
    while (low < high) {
      var mid = low + high >>> 1;
      if (schedule[day][mid] < hour) low = mid + 1;
      else high = mid;
    }
    return low;
  }

}

export default withSnackbar(withTranslation(['users', 'general'])(UserAdd));
