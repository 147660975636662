import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import Autocomplete from '@mui/material/Autocomplete';

import { useTranslation, withTranslation } from 'react-i18next'
import { withSnackbar, useSnackbar } from 'notistack';
import moment from 'moment'
import { DATETIME_FORMAT } from 'react-big-scheduler'
const HUMAN_FORMAT = 'DD.MM HH:mm';

const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 }]

export default function Details(props){

  const { t } = useTranslation(['requests', 'general']);
  let { eventPackage, handleChange, setClient, place } = props
  const [options, setOptions] = useState([]);
  const [fetchedClient, setFetchedClient] = useState(undefined)                 // keep fetched client if selected

  const fetch = (title) => {
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + place.link + '/clients', {name: title})
      .then(res => {
        if (res.data.length > 0)
          setOptions(res.data)})
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h6">
              {t('components.details')}
          </Typography>
          <Divider />
        </Grid>
        <Grid item md={12} xs={12}>

          <Autocomplete
            freeSolo
            disableClearable
            fullWidth
            required
            id="evt-title"
            margin="normal"
            options={options ? options : [{name: '', phone: ''}]}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
                    <Grid container {...props}>
                        <Grid item xs={6}>{option.name}</Grid>
                        <Grid item xs={6}>{option.phone}</Grid>
                    </Grid>
                )}

            value={fetchedClient ? fetchedClient.name : null}
            inputValue={eventPackage.title}
            onChange={(event: any, newValue: string | null) => {
              setClient(newValue)
              console.log(newValue, 222) // select from dropdown
            }}
            onInputChange={(event, newInputValue) => {
              handleChange('title', newInputValue)
              fetch(newInputValue)
              console.log(newInputValue, 333) // value from input
            }}
            onBlur={() => setClient({name: eventPackage.title})}

            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Titlu" />}
          />

        </Grid>
        {eventPackage.requestDates &&
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item>{t('assign.requestDates')}:</Grid>
            {eventPackage.requestDates.replace('[', '').replace(']', '').split(',').map((date, key) => {
              return (
                <Grid item>{moment(date).format(HUMAN_FORMAT)}</Grid>
              )
            })}
          </Grid>
        </Grid>}
      </Grid>
    </Grid>
  );
}

// <TextField
//   margin="normal"
//   required
//   fullWidth
//   id="evt-title"
//   type="text"
//   label="Titlu"
//   name="evt-title"
//   value={eventPackage.title}
//   onChange={(event, newValue) => {
//     var newVal = event.target.value;
//     handleChange('title', newVal)
//   }}
// />
