import React from 'react';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container'

import { useTranslation } from 'react-i18next'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EventDetails(props) {

  const { t } = useTranslation('schedule');

  let dateFormat = "DD MMM";
  let timeFormat = "HH:mm";
  let statusClass = props.eventDetails.status === 'FREE' ? 'text-success-light' :
                    props.eventDetails.status === 'CANCELED' ? 'text-error' :
                    props.eventDetails.status === 'BOOKED' ? 'text-blue' : 'text-success'

  return (
    <Dialog fullScreen open={props.eventDetails ? true : false} TransitionComponent={Transition}>
      <AppBar className={'position-relative'} color="secondary">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={1}>
            <IconButton edge="start" color="inherit" onClick={() => props.closeEventDetails()} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6">
              {t('user.event_details.event_details')}
            </Typography>
          </Grid>
          <Grid item xs={3} className={'text-right'}>
            <Button variant="outlined" className={'white-button'} onClick={() => props.closeEventDetails(false)}>
            {t('user.event_details.close_btn')}
            </Button>
          </Grid>
        </Grid>
      </AppBar>
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12}>  </Grid>
          <Grid item md={2} xs={4}>
          {t('user.event_details.coach')}
          </Grid>
          <Grid item md={10} xs={8}>
            {props.eventDetails.title}
          </Grid>
          <Grid item md={2} xs={4}>
          {t('user.event_details.date')}
          </Grid>
          <Grid item md={10} xs={8}>
            {moment(props.eventDetails.start).format(dateFormat)} de la {moment(props.eventDetails.start).format(timeFormat)} la {moment(props.eventDetails.end).format(timeFormat)}
          </Grid>
          <Grid item md={2} xs={4}>
          {t('user.event_details.pakage')}
          </Grid>
          <Grid item md={10} xs={8}>
            {props.eventDetails.pakage ? props.eventDetails.pakage.name : ''}
          </Grid>
          <Grid item md={2} xs={4}>
          {t('user.event_details.status')}
          </Grid>
          <Grid item md={10} xs={8}>
            <span className={statusClass}>{props.eventDetails.status}</span>
          </Grid>
          {!props.hidePhone && <Grid item md={2} xs={4}>
          {t('user.event_details.phone_number')}
          </Grid>}
          {!props.hidePhone && <Grid item md={10} xs={8}>
            <a href={`tel:${props.eventDetails.phone}`}>{props.eventDetails.phone} {props.hidePhone}</a>
          </Grid>}
          <Grid item md={2} xs={4}>
          {t('user.event_details.number_of_persons')}
          </Grid>
          <Grid item md={10} xs={8}>
            {props.eventDetails.attendees}
          </Grid>
          <Grid item md={2} xs={4}>
          {t('user.event_details.price')}
          </Grid>
          <Grid item md={10} xs={8}>
            {props.eventDetails.price}
          </Grid>
          <Grid item md={2} xs={4}>
          {t('user.event_details.details')}
          </Grid>
          <Grid item md={10} xs={8}>
            {props.eventDetails.info}
          </Grid>
          <Grid item md={2} xs={4}>
          {t('user.event_details.historic')}
          </Grid>
          <Grid item md={10} xs={8}>
            {props.eventDetails ?
              props.eventDetails.editHistory.split('|').map((h, i) => {
              return(
                <p>{h}</p>
              )
            }) : ''}
          </Grid>

        </Grid>
      </Container>
    </Dialog>
  );
};
