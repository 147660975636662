import React from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

export default function EventItem(props) {
  let statusClass = props.event.status === 'BOOKED' ? 'text-blue' : (props.event.status === 'CANCELED' ? 'text-error' : 'text-success')

  return (
    <div>
      <Grid container className={"p-1 event-item-pointer"} onClick={props.onShowDetails}>
        <Grid item  xs={2}>
          {moment(props.event.start).format('HH:mm')}
        </Grid>
        <Grid item  xs={2}>
          {moment(props.event.end).format('HH:mm')}
        </Grid>
        <Grid item xs={5}>
          {props.event.title}
        </Grid>
        <Grid item xs={3}>
          <span className={statusClass}>{props.event.status}</span>
        </Grid>
      </Grid>
    </div>
  );
}
