import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import ReactToPrint from 'react-to-print';
import Content from './Content';
import Header from './Header';

const pageStyle = `
  @page {
    size: 80mm 50mm;
  }
`;

const Print = (props) => {

  let { eventPackage, clientPackage, place, staff } = props;

  const componentRef = useRef();
  const [print, setPrint] = React.useState(false);

  return (
    <div>
      <ReactToPrint
        trigger={() =>
          <Button variant="outlined" color="primary" onClick={() => setPrint(true)}>
            <PrintOutlinedIcon />
          </Button>}
        content={() => componentRef.current}
      />
        <div style={{ display: "none" }}>
          <div ref={componentRef} className={'pdf-page'}>
            <Header place={place} />
            <Content
              eventPackage={eventPackage}
              clientPackage={clientPackage}
              staff={staff}
              print={print} />
          </div>
        </div>
    </div>
  );
};

export default Print
//<Header place={props.place} />
//pageStyle={pageStyle}
