import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LinearProgress from '@material-ui/core/LinearProgress';
import MUIRichTextEditor from 'mui-rte'
import { convertToRaw, convertFromRaw } from 'draft-js';

import { withSnackbar, useSnackbar } from 'notistack';
import { useTranslation, withTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PkgEdit(props){

  const { t } = useTranslation(['packages','general']);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let { open, place, setPackages, handleClose } = props;

  const [pkg, setPkg] = useState(props.pakage);
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState('');

  const savePackage = () => {
    setPkg({...pkg, info: info});
    if(validateModel()){
      setLoading(true);
      axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + place.link + '/packages', {...pkg, info: info})
        .then(
          res => {
            setLoading(false);
            enqueueSnackbar(`${t('general:notifs.pkg.success')}`, {variant: 'success'});
            setPackages(res.data);
            handleClose();
          },
          error => {
            enqueueSnackbar(`${t('general:notifs.pkg.error')}` + error, {variant: 'error'});
            setLoading(false);
          }
        );
    }
  }

  const validateModel = () => {
    let valid = true;
    setError({})

    if (pkg.name && pkg.name.length < 5 || pkg.name.length > 254){
      setError({...error, name: true})
      valid = false;
    }
    if (pkg.abs && pkg.abs.length > 254){
      setError({...error, abs: true})
      valid = false;
    }
    if (pkg.price < 0 || pkg.price == null){
      setError({...error, price: true})
      valid = false;
    }
    if (pkg.availability < 0 || pkg.availability == null){
      setError({...error, availability: true})
      valid = false;
    }
    if (pkg.spots.length === 0){
      setError({...error, spots: true})
      valid = false;
    }
    if (pkg.type.length === 0){
      setError({...error, type: true})
      valid = false;
    }
    return valid;
  }

  return (
    <Dialog fullScreen open={open} onClose={() => handleClose()} TransitionComponent={Transition}>
      <AppBar className={'position-relative'} color="primary">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={1}>
            <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6">
              {t('pkg_edit.appbar.edit')}
            </Typography>
          </Grid>
          <Grid item xs={3} className={'text-right'}>
            <Button variant="outlined" className={'white-button'} disabled={loading} onClick={savePackage}>
              {t('general:buttons.save')}
            </Button>
          </Grid>
        </Grid>
      </AppBar>
      <LinearProgress value="100" variant={loading ? 'indeterminate' : 'determinate'} color="primary"/>
      <Container maxWidth="md">
        <form noValidate>
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
            <Grid item xs={12} md={9}>
              <TextField
                autoFocus
                margin="normal"
                required
                fullWidth
                id="pkg-title"
                label={t('pkg_edit.form.pkg_name')}
                name="pkg-title"
                autoComplete="pkg-title"
                value={pkg.title}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, title : newVal})
                }}
                error={error.title}
                helperText={error.title ? `${t('pkg_edit.form.pkg_name_helper_text')}` : ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="pkg-name"
                label="Denumire scurta"
                name="pkg-name"
                autoComplete="pkg-name"
                value={pkg.name}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, name : newVal})
                }}
                error={error.name}
                helperText={error.name ? `${t('pkg_edit.form.pkg_name_helper_text')}` : ''}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                multiline={true}
                rows={2}
                id="pkg-abs"
                label="Descriere scurta"
                name="pkg-abs"
                autoComplete="pkg-abs"
                value={pkg.abs}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, abs : newVal})
                }}
                error={error.abs}
                helperText={error.name ? `${t('pkg_edit.form.content_helper_text')}` : ''}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <InputLabel htmlFor="req-evt-type" className="text-small select-margin">{t('pkg_edit.form.activity.activity')}</InputLabel>
              <Select
                value={pkg.type ? pkg.type : ''}
                multiple
                onChange = {(event, newValue) => {
                  var newVal = event.target.value;
                  console.log(newVal)
                  setPkg({...pkg, type : newVal})
                }}
                margin="none"
                fullWidth
                renderValue={(selected) => selected.join(', ')}
                inputProps={{
                  name: 'req-evt-type',
                  id: 'req-evt-type',
                }}
                >
                {place.placeInfo.activities.map((a) => (
                  <MenuItem key={a} value={a}>
                    <Checkbox checked={pkg.type.indexOf(a) > -1} color="primary"/>
                    <ListItemText primary={a} />
                  </MenuItem>
                ))}
              </Select>
              {error.type ? <p className="text-error text-helper" id="evt-title-helper-text">{t('pkg_edit.form.activity.helper_text')}</p> : '' }
            </Grid>
            <Grid item md={3} xs={12}>
              <InputLabel htmlFor="req-evt-locations" className="text-small select-margin">{t('pkg_edit.form.locations')}</InputLabel>
              <Select
                value={pkg.locations}
                multiple
                onChange = {(event, newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, locations : newVal})
                }}
                margin="none"
                fullWidth
                multiple
                renderValue={(selected) => selected.join(', ')}
                inputProps={{
                  name: 'req-evt-locations',
                  id: 'req-evt-locations',
                }}
                >
                {place.placeInfo.locations.map((l) => (
                  <MenuItem key={l} value={l}>
                    <Checkbox checked={pkg.locations.indexOf(l) > -1} color="primary"/>
                    <ListItemText primary={l} />
                  </MenuItem>
                ))}
              </Select>
              {error.type ? <p className="text-error text-helper" id="evt-title-helper-text">{t('pkg_edit.form.activity.helper_text')}</p> : '' }
            </Grid>

            <Grid item xs={12} md={2}>
              <InputLabel htmlFor="req-evt-status" className="text-small select-margin">Tip</InputLabel>
              <Select
                value={pkg.pkgType}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, pkgType : newVal})
                }}
                margin="none"
                fullWidth
                error={error.type}
                inputProps={{
                  name: 'pkg-ptype',
                  id: 'pkg-ptype',
                }}>
                <MenuItem value={'SIMPLE'}>Pachet</MenuItem>
                <MenuItem value={'COURSE'}>Curs</MenuItem>
                <MenuItem value={'CAMP'}>Tabara</MenuItem>
                <MenuItem value={'EXPERIENCE'}>Experienta</MenuItem>
              </Select>
              {error.pkgType ? <p className="text-error text-helper" id="evt-title-helper-text">{t('pkg_edit.form.activity.helper_text')}</p> : '' }
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                margin="normal"
                style={{marginBottom: 0}}
                required
                fullWidth
                id="pkg-price"
                label={t('pkg_edit.form.price')}
                name="pkg-price"
                type="number"
                autoComplete="pkg-price"
                value={pkg.price}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, price: newVal})
                }}
                error={error.price}
                helperText={error.price ?  `${t('pkg_edit.form.price_helper_text')}` : ''}
              />
            </Grid>
            <Grid item xs={12} md={2} className={'pow-checkbox'}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pkg.pkgPrice}
                    onChange = {(event, newValue) => {
                      var newVal = event.target.checked;
                      setPkg({...pkg, pkgPrice: newVal})
                    }}
                    color="primary"
                  />
                }
                label={t('pkg_edit.form.pkg_price')}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="pkg-availability"
                label={t('pkg_edit.form.number_of_persons')}
                name="pkg-spots"
                value={pkg.spots}
                type="number"
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, spots: newVal})
                }}
                error={error.spots}
                helperText={error.spots ?  `${t('pkg_edit.form.number_of_persons_helper_text')}` : ''}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="pkg-availability"
                label={t('pkg_edit.form.number_of_classes')}
                name="pkg-availability"
                type="number"
                value={pkg.availability}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, availability: newVal})
                }}
                error={error.availability}
                helperText={error.availability ?  `${t('pkg_edit.form.number_of_classes_helper_text')}` : ''}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="pkg-timeUnit"
                label={t('pkg_edit.form.time_unit')}
                name="pkg-timeUnit"
                type="number"
                value={pkg.timeUnit}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, timeUnit: newVal})
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="pkg-order"
                label={t('pkg_edit.form.order')}
                name="pkg-order"
                type="number"
                value={pkg.lordre}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, lordre: newVal})
                }}
              />
            </Grid>
            <Grid item xs={12} md={2} className={'pow-checkbox'}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={pkg.active}
                    onChange = {(event, newValue) => {
                      var newVal = event.target.checked;
                      setPkg({...pkg, active: newVal})
                    }}
                    color="primary"
                  />
                }
                label={t('pkg_edit.form.pkg_active')}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                multiline={true}
                rows={2}
                id="pkg-textArea"
                label="Informatii aditionale. Vor aparea in formularul de rezervare la final. ( ex. meniu, varsta, oras, etc. )"
                name="pkg-textArea"
                value={pkg.textArea}
                onChange = {(event,newValue) => {
                  var newVal = event.target.value;
                  setPkg({...pkg, textArea : newVal})
                }}
                error={error.textArea}
                helperText={error.name ? `${t('pkg_edit.form.content_helper_text')}` : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <MUIRichTextEditor
                label={t('pkg_edit.form.info')}
                name="pkg-info"
                value={pkg.info}
                onChange = {(event) => {
                  console.log(event.getCurrentContent())
                  setInfo(JSON.stringify(convertToRaw(event.getCurrentContent())));
                }}
                error={error.info}
                helperText={error.info ?  `${t('pkg_edit.content_helper_text')} ${pkg.info && pkg.info.length}/254*` : `${pkg.info && pkg.info.length}/254`}/>
            </Grid>
          </Grid>
        </form>
        <br/><br/><br/>
        <Grid container spacing={1}>
          <Grid item xs={12} md={12}>
            <Divider/><br/>
          </Grid>
          <Grid item xs={12} md={12}>
            <p>Link:       https://snowbuddy.app/ro/{place.link}/{pkg.link}</p>
            <p>Formular:   https://snowbuddy.app/ro/iframe/{place.link}/camp/book/{pkg.link}</p>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
}
