import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';

import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import '../../../../node_modules/react-vis/dist/style.css';

import FindDtoSearch from '../../../components/place/packages/FindDtoSearch';
import PrintUtil from '../../../components/place/packages/PrintUtil';
import StaffList from './StaffList';
import EventPackage from '../../../components/place/assign/EventPackage'

import {DATE_FORMAT, DATETIME_FORMAT} from 'react-big-scheduler'

import { useTranslation } from 'react-i18next'

export default function ReportsPage(props) {

  const { placeLink } = useParams();
  const { t } = useTranslation('reports');
  const { securityUser, packages } = props;
  const place = securityUser.place;

  const [loading, setLoading] = useState(false);
  const [findDto, setFindDto] = useState({date: moment().format(DATETIME_FORMAT), endDate: moment().format(DATETIME_FORMAT), statuss:['BOOKED', 'PAID'], byPayDate: false, resourceId: null, fullPackage: true});
  const [data, setData] = useState({resources: [], events: []});
  const [resources, setResources] = useState([]);                               // first time will came all resources, stored here
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);
  const [online, setOnline] = useState(0);
  const [openAll, setOpenAll] = useState(false);
  const [wannaEdit, setWannaEdit] = React.useState(false);

  useEffect(() => {
    refreshData()
  }, [findDto])

  const refreshData = () => {
    setLoading(true);
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + placeLink + '/event/request/reports', findDto)
      .then(res => {
        convertData(res.data)
        setLoading(false);
      },
      error => {setLoading(false);});
  }

  // convert events list to map < resourceId, events >
  const convertData = (data) => {
    let map = new Map();
    let ch = 0;
    let cd = 0;
    let on = 0;
    data.resources.forEach(resource => {
      if (!resource.groupOnly)
        map.set(resource.id, []);
    });
    data.eventPackages.forEach(ePkg => {
      let list = map.get(ePkg.resourceId);
      list.push(ePkg)
      map.set(ePkg.resourceId, list);
      ePkg.clientPackages.forEach(clientPkg => {
        if (clientPkg.paid && (clientPkg.payMethod == 'card' || clientPkg.payMethod == 'staffCard'))
          cd += clientPkg.payAmount;
        else if (clientPkg.paid && clientPkg.payMethod == 'online')
          on += clientPkg.payAmount;
        else if (clientPkg.paid && (clientPkg.payMethod == 'cash' || clientPkg.payMethod == 'staffCash'))
          ch += clientPkg.payAmount;
        })
    });
    if (resources.length == 0)
      setResources(data.resources);
    setCash(ch);
    setCard(cd);
    setOnline(on);
    setData({resources: data.resources, events: map});
  }

  return (
    <>
      <LinearProgress value="100" variant={loading ? 'indeterminate' : 'determinate'}/>
      <Container maxWidth="xl">
        <Grid container spacing={3}>

          <Grid item xs={9}>
            <FindDtoSearch
              findDto = { findDto }
              updateFindDto = { setFindDto }
              resources = { resources } />
          </Grid>

          <Grid item xs={3} className={'text-right margin-auto'}>
            <PrintUtil>
              <StaffList
                data = { data }
                place = { place }
                findDto = { findDto }
                refreshData = { refreshData }
                cash = { cash }
                card = { card }
                online = { online }
                print = { true }
                openAll = { openAll }
                setOpenAll = { setOpenAll }/>
            </PrintUtil>
          </Grid>

          <Grid item xs={12}>
            <StaffList
              data = { data }
              place = { place }
              findDto = { findDto }
              refreshData = { refreshData }
              cash = { cash }
              card = { card }
              online = { online }
              openAll = { openAll }
              setOpenAll = { setOpenAll }
              setWannaEdit = {setWannaEdit}/>
          </Grid>

          {wannaEdit &&
          <EventPackage
            open={wannaEdit ? true : false}
            eventPackage={wannaEdit}
            handleClose={() => setWannaEdit(false)}
            place={securityUser.place}
            packages={packages}
            resources={resources}
            refreshData={refreshData}/>}

        </Grid>
      </Container>
    </>
  )

}

//
