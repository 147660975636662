import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  padding: {
    padding: 0
  }
}));

export default function PanelMobile() {
  const classes = useStyles();

  const { t } = useTranslation('support');

  return (
    <div className={classes.root}>
      <Typography>
        {t('panel_suport.help')}
        <a href="mailto:hello@snowbuddy.app?subject=Problema tehnica platforma online?&body=Salut, intampinam dificultati cu platforma online  %0d%0a%0d%0aUsername: %0d%0aBrowser: %0d%0aDescrierea problemei: %0d%0a%0d%0aMultumesc,%0d%0aO zi buna!">hello@snowbuddy.app</a>
        <br/>{t('panel_suport.call')}<a href="tel:0753026141">+40 753 026 141</a>
        <br/><br/>
        {t('panel_suport.check')}
        <ul>
          <li>{t('panel_suport.check_1')}</li>
          <li>{t('panel_suport.check_2')}</li>
          <li>{t('panel_suport.check_3')}</li>
          <li>{t('panel_suport.check_4')}</li>
          <li>{t('panel_suport.check_5')}</li>
        </ul>
      </Typography>
    </div>
  );
}
