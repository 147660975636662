import React, { useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';

import { DATETIME_FORMAT } from 'react-big-scheduler'
import Grid from '@material-ui/core/Grid';

import Header from './Header';
import './style.scss';

import { useTranslation } from 'react-i18next'

export default function Content(props) {

  let { eventPackage, clientPackage, staff } = props;

  // var content = stateToHTML(convertFromRaw(JSON.parse()))
  // const replaceAll = (str, find, replace) => {
  //   return str.replace(new RegExp(find, 'g'), replace);
  // }

  const { t } = useTranslation('schedule');

  // if (props.eventPackage && props.staff && props.pakage){
  //   content = props.eventPackage ? replaceAll(content, '\\[clientName\\]', props.eventPackage.title) : content;
  //   content = props.eventPackage ? replaceAll(content, '\\[clientPhone\\]', props.eventPackage.phone) : content;
  //   content = props.eventPackage ? replaceAll(content, '\\[clientDetails\\]', props.eventPackage.info) : content;
  //   content = props.eventPackage ? replaceAll(content, '\\[clientAttendees\\]', props.eventPackage.attendees) : content;
  //   content = props.staff ? replaceAll(content, '\\[staffName\\]', props.staff.name) : content;
  //   content = props.pakage ? replaceAll(content, '\\[packageName\\]', props.pakage.name) : content;
  //   content = replaceAll(content, '\\[currentDate\\]', moment().format("DD.MM.YYYY"));
  // }

  // <div className={'pdf-content'} dangerouslySetInnerHTML={{__html: content }}>
  // </div>

  return(
    <Grid container spacing={1}>
      <Grid item xs={12}></Grid>
      <Grid item xs={1}>
          {t('content.name')}
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h5">
          { clientPackage.title }
        </Typography>
      </Grid>
      <Grid item xs={1}>
          {t('content.instructor')}
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h5">
          { staff.name } <br/>{ staff.phone }
        </Typography>
      </Grid>
      <Grid item xs={1}>
          {t('content.lesson')}
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h5">
          { eventPackage.pakage.name }
        </Typography>
      </Grid>
      <Grid item xs={1}>
          {t('content.lesson')}
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h5">
          { clientPackage.payAmount }
        </Typography>
      </Grid>
      <Grid item xs={1}>
          {t('content.payment')}
      </Grid>
      <Grid item xs={11}>
        <Typography variant="h5">
          { clientPackage.paid ? t('content.paid') : t('content.outstanding')}
        </Typography>
      </Grid>
      <Grid item xs={1}>
          {t('content.lessons')}
      </Grid>
      <Grid item xs={11}>
        {eventPackage.events.sort((a, b) => a.start > b.start ? 1 : -1).map((e) => {return <Typography variant="h5">
          { moment(e.start).format("DD.MM.YY")} / { moment(e.start).format("HH.mm")  } - { moment(e.end).format("HH.mm ") }
        </Typography>})}
      </Grid>
      <Grid item xs={12}>
        { moment().format(DATETIME_FORMAT) }
      </Grid>
    </Grid>
  )
}
