import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import { useTranslation } from 'react-i18next'
import moment from 'moment'

const TIME_FORMAT = 'HH:mm';
const DATE_FORMAT = 'DD.MM';
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export default function ResourceSchDay(props){

  const { t } = useTranslation('users');
  const SHORT_DATE = "DD MMM"
  let { place, day, events, eventPackage, addEvent } = props

  const hours = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22];
  const [allEvents, setAllEvents] = useState([]);

  useEffect(() => {
    let i = moment(place.placeInfo.startTime).hour();
    let e = 0;
    let hours = [];
    let thirty = false;
    const evnts = [...day.events];
    while (i <= moment(place.placeInfo.closeTime).hour()){
      if (e < evnts.length && moment(evnts[e].start).hour() == i){
          hours.push(evnts[e])
          i = moment(evnts[e].end).hour() //
          moment(evnts[e].end).minute() == 30 ? thirty = true : thirty = false ; // need review ----- check for 30' start/end event
          e += 1;
        }else{
          hours.push({start: moment(day.day).hour(i).minute(thirty ? 30 : 0).second(0).format(DATETIME_FORMAT), end: moment(day.day).hour(i + 1).minute(thirty ? 30 : 0).second(0).format(DATETIME_FORMAT)})
          i += 1;
          thirty = false
        }
    }
    setAllEvents(hours);
  }, [day, events])

  const contains = event => {
    for (var key in events) {
      if (events[key].start === event.start)
        return true;
      }
      return false;
  }


  return (
    <Grid item xs={12}>
      <Typography component="h5">
        {moment(day.day).format(SHORT_DATE)}
      </Typography>
      <Grid container justify="flex-start" direction="row" spacing={1}>
        {allEvents.map((event, i) => {
          let contain = contains(event);
          return(
            <Grid item key={i} className={'light'}>
              <Tooltip title={event.status === undefined ? 'UNKNOWN' : (event.eventPackage != undefined ? event.eventPackage.status : (event.status == 'FREE' && event.location ? event.location : 'FREE'))}>
                <Button
                    variant={contain ? 'contained' : (event.status == undefined) ? '' : 'outlined'}
                    color={(event.eventPackage == null || (event.status === undefined && contain)) ? 'primary' : (event.eventPackage.status == undefined) ? 'inherit' : 'secondary'}
                    key={i}
                    onClick={() => {if (event.status === 'FREE' || event.status === undefined || (event.eventPackage && event.eventPackage.id == eventPackage.id)) addEvent(event)}}>
                  {moment(event.start).format(TIME_FORMAT)}
                </Button>
              </Tooltip>
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  );
}
