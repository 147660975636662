import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';

// assets
import sbmp4 from '../../assets/img/sb.mp4';
import screenadmin from '../../assets/img/home/screen-admin.png'
import screenapp from '../../assets/img/home/screen-app.png'
import './HomePage.scss'
import PresentPage from './PresentPage'

import Copyright from '../../components/general/footer/Copyright.js'

import { withSnackbar } from 'notistack';

class HomePage extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      open: false
    }

    const action = (key) => (
    <Button className={'white-button'} onClick={() => { this.props.closeSnackbar(key) }}>
        {'Got it!'}
    </Button>)

    // props.enqueueSnackbar('Folosim cookies pentru a reusi sa functionam! Daca nu esti de acord te rugam sa inchizi conexiunea!' , {anchorOrigin: {
    //     vertical: 'bottom',
    //     horizontal: 'left',
    // }, action, variant: 'info'});
  }

  componentDidMount() {

  }

  render() {
    return (
      <div class="bg">
        <div class="bg-logo">
          <div class="container">
            <div class="welcome">
              <h2 class="title">
                Bun venit!
              </h2>
              <h5><Link to='/account/login'>Catre aplicatie ></Link></h5>
            </div>
            <div class="main">
              <section>
                {false ? '' : <><h3 class="subtitle">
                  De ce sunt aici?
                </h3>
                <h5>Sunt doua raspunsiri posibile:</h5>
                <p>Sunteti in cautarea unei platforme de gestionare a scolii de schi sau/si snowboard sau pur si simplu intamplator.</p>
                <p>In ambele cazuri ai ajuns intr-un loc bun :)</p>
                <p>Daca aveti nevoie de o platforma de gestionare a scolii de schi sectiunile urmatoare va vor da mai multe detalii</p>
                <p>Daca ai ajuns aici intamplator tot intamplator ne poti <a href="mailto:hello@snowbuddy.app?subject=Hello, dam o tura?&body=Salut, am ajuns intamplator pe la voi si am zis sa va scriem :) %0d%0a%0d%0aNo, dam o tura? %0d%0a%0d%0aMultumesc,%0d%0aOzzi buna!">scrie</a> si poate
                <br/>intamplator iti vom raspunde si poate tot intamplator dam o tura impreuna :) Ride On!</p>
                </>}
                <h3 class="subtitle">
                  Ce este SnowBuddy?
                </h3>
                <h5>Este o solutie software pentru managementul scolilor de schi si snowboard.</h5>
                <p>Are 3 componente principale. Platforma, care este folosita de catre staff-ul scolilor de schi,
                <br/>aplicatia pentru telefon care este folosita de catre monitori si formularul de rezervare care poate
                <br/>fi integrat in pagina web a scolii.</p>
                <p>Filozofia din spatele acestor componente este super simpla: "Folosesti atat cat ai nevoie!"</p>
                <h3 class="subtitle">
                  Cu ce m-ar ajuta?
                </h3>
                <h5>Pai ... O echipa eficienta este bine organizata!</h5>
                <p>Centrele de schi pot avea o viziune foarte buna asupra intregului program si a statisticilor
                <br/>din ultimele 30 de zile, monitorii sunt up-to-date cu programul in fiecare secunda iar
                <br/>clientii pot trimite usor cereri de rezervari online sau pot fi notificati prin SMS/email</p>
                <h3 class="subtitle">
                  Bine, dar cum functioneaza?
                </h3>
                <h5>We keep it simple!</h5>
                <p>Am creeat o mica prezentare a intregului proces</p>
                <Button variant="outlined" color="secondary" onClick={() => this.setState({open: true})}>
                  Vezi prezentarea
                </Button>
                <p></p>
                <PresentPage
                  open={this.state.open}
                  handleClose={() => this.setState({open:false})} />
                <h3 class="subtitle">
                  Cine a facut asta?
                </h3>
                <h5>We're pow lovers :)</h5>
                <p>Toata iarna stam la munte, mai exact la Scoala de schi si snowboard Platoul Soarelui - Straja,
                <br/>unde studiem impreuna complexul proces din spatele scolii de schi
                <br/>iar vara transpunem tot in super aplicatia Snow Buddy</p>
                <p>Ne puteti urmari -> insta-winter-life <a href="https://www.instagram.com/snowbuddy.app/" target="_blank">@snowbuddy.app</a></p>
                <h3 class="subtitle">
                  Vreti sa o testati?
                </h3>
                <h5>Cere un cont demo! Sau scrie-ne pur si simplu :)</h5>
                <p>Scrie-ne la <a href="mailto:hello@snowbuddy.app?subject=Hello, vreau un cont demo!&body=Salut, as dori un cont demo :) %0d%0a%0d%0aMa numesc ... si sunt de la scoala de schi ... %0d%0aAdresa mea de email este: %0d%0aNumarul de telefon: %0d%0a%0d%0aMultumesc,%0d%0aO zi buna!">Hello SnowBuddy App</a></p>
                <br/><br/>
              </section>
            </div>
            <Copyright/>
          </div>
        </div>
      </div>
      );
    }
  }

  export default withSnackbar(HomePage);
