import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import  { Redirect } from 'react-router-dom'

import SignInOtp from './SignInOtp'

export default function LoginComponent(props){

  const [userDetails, setUserDetails] = useState( { username:'', password:'' } );
  const [redirect, setRedirect] = useState('');
  const [loading, setLoading] = useState('');
  const [error, setError] = useState('');
  const [otp, setOtp] = useState(false); // true if otp was generated

  useEffect(() => {
    if (Cookies.get('refreshing'))
      axios.get(process.env.REACT_APP_API_URL + `/account/me`)
      .then(res => {
        setRedirect('/place');
      },
      (error) => {
        console.log(error.response);//.data.error_description)
        // setError(error.response.message);
      });
  }, [])

  const logInSubmit = event => {
    event.preventDefault();
    setLoading(true);
    setError('');
    const body = 'client_id=snowpow&grant_type=password&password=' + userDetails.password + '&username=' + userDetails.username;

    // post login details
    axios.post(process.env.REACT_APP_AUTH_URL + `/oauth/token`, body, {headers: {'Content-Type':'application/x-www-form-urlencoded; charset=utf-8'}})
      .then(res => {
        console.log(res)
        // get me as user
        axios.get(process.env.REACT_APP_API_URL + `/account/me`)
        .then(res => {
          setLoading(false);
          setRedirect('/place');
        },
        (error) => {
          setLoading(false);
          setError(error);
          setOtp(false);
        });
      },
      (error) => {
        setLoading(false);
        setError(error);
        setOtp(false);
    });
  }

  // manage redirect to /dashboard is user is logged in
  const renderRedirect = (target) => {
    if (redirect !== '') {
      return <Redirect to={redirect} />
    }
  }

  return (
    <div>
      <SignInOtp
        logInSubmit = {(event) => logInSubmit(event)}
        userDetails = {userDetails}
        loading = {loading}
        error = {error ? 'Bad credentials' : ''}
        otp={otp}
        setOtp = {setOtp}/>
      {renderRedirect()}
    </div>
  );

}
