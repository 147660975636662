import React, {useState} from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import '../../../../node_modules/react-vis/dist/style.css';
import './style.scss';
import {XYPlot, FlexibleXYPlot, LineSeries, AreaSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, Hint} from 'react-vis';

import { useTranslation } from 'react-i18next'

export default function AreaSeriesWrap(props) {

  const dateFormat = "DD MMM";
  const [viewDetails, setViewDetails] = useState({x: 0, y: 0});
  const [viewDetails2, setViewDetails2] = useState({x: 0, y: 0});

  const { t } = useTranslation('stats');

  return (
    <Grid container>
      <Grid item xs={12} className={"stats-flex"}>
        <FlexibleXYPlot>
          <XAxis tickLabelAngle={-0} tickFormat={v => `${props.dataLabelMap[v]}`}/>
          <YAxis hideLine/>
          <Hint value={viewDetails} >
            {viewDetails.info ? <div className={'wrap-hint'}>
            {t('area_series_wrap.in')} {moment(viewDetails.info).format(dateFormat)} {t('area_series_wrap.was')} {viewDetails.y} {t('area_series_wrap.classes_and')} {viewDetails2.y} {t('area_series_wrap.participants')}
            </div> : <div></div>}
          </Hint>
          <AreaSeries
            curve="curveBasis"
            data={props.data}
            opacity={0.5}
            style={{}}
            onNearestX={(value, {index}) => {setViewDetails(value)}}
            onSeriesMouseOut={(event)=>{setViewDetails({x:0, y:0, info: false})}}
          />
          {props.data2 ? <LineSeries
            curve="curveBasis"
            color="#EE6E72"
            data={props.data2}
            opacity={0.5}
            style={{}}
            onNearestX={(value, {index}) => {setViewDetails2(value)}}
            onSeriesMouseOut={(event)=>{setViewDetails2({x:0, y:0, info: false})}}
          /> : ''}
        </FlexibleXYPlot>
      </Grid>
    </Grid>
  )

}

//
