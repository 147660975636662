import React from 'react';
import { Redirect } from 'react-router-dom'
import moment from 'moment'

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import EventPackage from '../../../components/place/assign/EventPackage'

import { useTranslation } from 'react-i18next'

export default function ClientPackages(props){

  let { client, getClient, clientPackages, securityUser, packages, resources } = props;
  const { t } = useTranslation('clients', 'general');

  const [redirect, setRedirect] = React.useState(false);
  const [wannaEdit, setWannaEdit] = React.useState(false);

  return (
    <Grid xs={12}>
      {clientPackages.map((p, i) => {
        return(
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1} key={p.id}>
            <Grid item sm={12} md={2}>
              {moment(p.eventPackage.start).format('DD.MM.YY | HH:mm')} - {moment(p.eventPackage.end).format('HH:mm')}
            </Grid>
            <Grid item sm={6} md={3}>
              {p.eventPackage.title}
            </Grid>
            <Grid item sm={6} md={3}>
              {p.eventPackage.pakage.name}
            </Grid>
            <Grid item sm={4} md={1}>
              {p.eventPackage.price}
            </Grid>
            <Grid item sm={4} md={1}>
              <span className={p.eventPackage.status === 'BOOKED' ? 'text-blue' : (p.eventPackage.status === 'CANCELED' ? 'text-error' : 'text-success')}>{p.eventPackage.status}</span>
            </Grid>
            <Grid item sm={4} md={2} className={'text-right'}>
              <Tooltip title={t('event_item.edit')} enterDelay={700}>
                <Button variant="outlined" color="primary" onClick={() => setWannaEdit(p.eventPackage)}>
                  <EditOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>
            <Divider />
          </Grid>
        )}
      )}

      {wannaEdit &&
      <EventPackage
        open={wannaEdit ? true : false}
        eventPackage={wannaEdit}
        handleClose={() => setWannaEdit(false)}
        place={securityUser.place}
        packages={packages}
        resources={resources}
        refreshData={() => getClient(client)}/>}
    </Grid>
  );
}
