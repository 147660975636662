import React from 'react';

import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import RequestItem from './RequestItem'
import EventPackage from '../../../components/place/assign/EventPackage';

export default function RequestList(props) {

  let { requests, refreshData, place, packages, resources, wannaAssign, setWannaAssign } = props;

  return (
    <Grid container>
      { requests ?
      <Grid item xs={12}>
        {requests.map((r, i) => {
          return(
            <div key={r.id}>
              <RequestItem
                req={r}
                key={r.id}
                place={place}
                refreshData={refreshData}
                handleAssignRequest={(req) => setWannaAssign(req)}
                />
              <Divider />
            </div>
          )}
        )}
      </Grid> :
      <Grid item xs={12} className={"stats-flex text-center"}>
        <CircularProgress color="primary" size={40}/>
      </Grid>}

      {wannaAssign &&
      <EventPackage
        open={wannaAssign ? true : false}
        eventPackage={wannaAssign}
        handleClose={() => setWannaAssign(false)}
        place={place}
        packages={packages}
        resources={resources}
        refreshData={refreshData}/>}

    </Grid>
  );
}
