import React, { useState, useEffect } from 'react';
import axios from 'axios';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress';

import ClientsList from './ClientsList';
import ClientPackages from './ClientPackages';

import { useTranslation } from 'react-i18next'

export default function ClientsPage(props){

  let { securityUser, packages, resources } = props;
  const { t } = useTranslation(['clients', 'general']);

  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});
  const [clientPackages, setClientPackages] = useState(false);
  const [search, setSearch] = useState();

  useEffect(() => {
    if (search)
      axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + securityUser.place.link + '/clients', {name: search})
        .then(res => {
          setClients(res.data);
          setClientPackages(false)});}, [search])

  useEffect(() => {
    console.log(packages, resources)
  }, [packages, resources])

  const getClient = (client) => {
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + securityUser.place.link + '/clients/details', client)
      .then(res => {
        setClientPackages(res.data);
        setClient(client);
      });
  }

  return (
    <div>
      <CssBaseline />
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <TextField
              autoFocus
              margin="normal"
              required
              fullWidth
              id="evt-name"
              label={t('requested_textfield')}
              name="evt-name"
              autoComplete="evt-name"
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                setSearch(newVal)
              }}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            {clientPackages ?
              <ClientPackages
                securityUser={securityUser}
                packages={packages}
                resources={resources}
                client={client}
                clientPackages={clientPackages}
                getClient={getClient} /> :
              <ClientsList
                clients={clients}
                placeLink={securityUser.place.link}
                getClient={getClient} />}
          </Grid>
        </Grid>
      </Container>

    </div>
  );
}


// <EventEdit
//   open={this.state.onEditEventModal}
//   event={this.state.eventToEdit}
//   pkgs={this.state.pkgs}
//   handleCloseEditEvent={() => this.setState({onEditEventModal : false})}
//   getClient={(client) => {this.getClient(client); this.setState({onEditEventModal : false})}}
//   placeLink={this.props.match.params.placeLink}/>

// events && ? <ClientEvents
//   events={this.state.events}
//   editEvent={(event) => this.setState({eventToEdit: event, onEditEventModal: true})}
//   placeLink={securityUser.place.link} /> : ''}
