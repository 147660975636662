import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  padding: {
    padding: 0
  },
  details: {
    flexDirection: "column"
  },
  htmlcode: {
    background: '#efefef',
    padding: '20px'
  }
}));

export default function PanelFrame(props) {
    const { t } = useTranslation('support');
    const classes = useStyles();

  const prettyPrintJson = ({data}) => {
    return (<div><pre>{ JSON.stringify(data, null, 2) }</pre></div>);
  }

  const [height, setHeight] = React.useState(600)

  const getJson = {active: false,
    link: "snow-buddy",
    name: "Snow Buddy",
    placeInfo: {info: "Snow Buddy App", address: "Snow Buddy", website: "https://snowbuddy.app", phone: "0753026141"},
    address: "Snow Buddy",
    closeTime: "2020-03-05 17:00:00",
    email: "hello@snowbuddy.app",
    info: "Snow Buddy App",
    phone: "0753026141",
    publicProfile: true,
    publicRequest: true,
    publicRequestMember: false,
    ski: false,
    snowboard: true,
    startTime: "2019-11-28 09:00:00",
    website: "https://snowbuddy.app",
    placePakage: [
      {
        id: 170,
        name: "Ora ski",
        attendeesInfo: "1",
        availability: 1,
        id: 170,
        info: "info",
        name: "Ora ski",
        price: 100,
        type: "SKI"
      },
      {
        id: 180,
        name: "Ora snowboard",
        attendeesInfo: "1",
        availability: 1,
        id: 180,
        info: "info",
        name: "Ora snowboard",
        price: 100,
        type: "SNOWBOARD"
      }
    ]
  }

  const postJson = {
    attendees: "2", //123
    checkDate: false,
    email: "adrian@iservit.ro",
    start: "2019-12-04 09:00:00",
    end: "2019-12-04 10:00:00",
    info: "",
    level: "BEGINNER",
    phone: "0753026141",
    title: "Adrian Pop",
    type: "SNOWBOARD",
    pakage: {
      attendeesInfo: "1",
      availability: 1,
      id: 180,
      info: "info",
      name: "Ora snowboard",
      price: 100,
      type: "SNOWBOARD",
    }
  }

  return (
    <div className={classes.root}>
      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_iframe.presentation.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
              {t('panel_iframe.presentation.text1')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_iframe.installation.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>
            <Typography>
                {t('panel_iframe.installation.text1')}
              <br/>
                {t('panel_iframe.installation.text2')}
              <br/>
                {t('panel_iframe.installation.text3')}
            </Typography>
            <br/>
            <Typography className={classes.htmlcode}>
              {`<style>.frame-container{top: 100px; left: 0; right: 0; bottom: 0;}.frame-container iframe{display: block; width: 100%; min-height: 600px; overflow:hidden;}@media screen and (max-width: 1024px){.frame-container iframe{min-height: 650px;}}@media screen and (max-width: 768px){.frame-container iframe{min-height: 700px;}}@media screen and (max-width: 425px){.frame-container iframe{min-height: 750px;}}</style><div class="frame-container"><iframe frameborder="0" scrolling="no" src="https://snowbuddy.app/ro/iframe/event/book/${props.placeLink}" ></iframe></div>`}
            </Typography>

        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_iframe.integrate_api.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.details}>

            <Typography>
                {t('panel_iframe.integrate_api.text1')}
            </Typography>
            <br/>
            DEPRECATED | APIv1.0 --------------------------------
            <Typography>
                {t('panel_iframe.integrate_api.text2')}
            </Typography>
            <br/>
            <Typography className={classes.htmlcode}>
              GET: https://snowbuddy.app/resources/iframe/place/{props.placeLink}
            </Typography>
            <br/>
            <Typography className={classes.htmlcode}>
              <pre>{JSON.stringify(getJson, null, 2)}</pre>
            </Typography>
            <br/>
            <Typography>
                {t('panel_iframe.integrate_api.text3')}
            </Typography>
            <br/>
            <Typography className={classes.htmlcode}>
              POST: https://snowbuddy.app/resources/iframe/place/{props.placeLink}
            </Typography>
            <br/>
            <Typography className={classes.htmlcode}>
              <pre>{JSON.stringify(postJson, null, 2)}</pre>
            </Typography>
            <br/>
            <Typography>
                {t('panel_iframe.integrate_api.text4')}
              <br/>
                {t('panel_iframe.integrate_api.text5')}
              <br/>
                {t('panel_iframe.integrate_api.text6')}
            </Typography>

        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_iframe.requests.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
              {t('panel_iframe.requests.text1')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_iframe.process.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
              {t('panel_iframe.process.text1')}
            Dupa completarea formularului interactiv, clientul va trebui sa accepte termenii si conditiile specificate in sectiunea
            "Setari" -> "Setari generale" -> "Termeni si conditii" si va primi un email cu detaliile rezervarii. In momentul asignarii
            cererii de rezervare unui instructor, clientul va primi un email de confirmare a rezervarii cu numele monitorului, ora lectiei
            si detaliile generale completate de el in primul pas.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_iframe.requirements.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
              {t('panel_iframe.requirements.text1')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_iframe.information.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
              {t('panel_iframe.information.text1')}
            <ul>
              <li>{t('panel_iframe.information.text2')}</li>
              <li>{t('panel_iframe.information.text3')}</li>
              <li>{t('panel_iframe.information.text4')}</li>
            </ul>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );


}
