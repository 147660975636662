import React, { useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';

const EventTerms = (props) => {

  const componentRef = useRef();
  const [print, setPrint] = React.useState(false);

  return (
    <div>
      <ReactToPrint
        trigger={() =>
          <Button variant="outlined" color="primary" onClick={() => setPrint(true)}>
            <PrintIcon />
          </Button>}
        content={() => componentRef.current}
      />
        <div style={{ display: "none" }}>
          <div ref={componentRef} className={'pdf-page'}>
            { props.children }
          </div>
        </div>
    </div>
  );
};

export default EventTerms
