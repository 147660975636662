import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './style.scss';

import RadialChartWrap from './RadialChartWrap';

export default function RadialStats(props) {

  return (
    <Grid item lg={props.size}>
      <Grid item lg={12}>
        <Typography variant="h5" component="h3">
          {props.title}
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <RadialChartWrap
          data={props.data}/>
      </Grid>
    </Grid>
  )
}
