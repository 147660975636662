import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { convertFromRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html';
import moment from 'moment';

import Header from './Header';
import './style.scss';

import { useTranslation } from 'react-i18next'

const Content = ({ terms, staff, eventPackage, clientPackage }) => {

  const [content, setContent] = useState(stateToHTML(convertFromRaw(JSON.parse(terms))));
  const replaceAll = (str, find, replace) => {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  const { t } = useTranslation('schedule');

  useEffect(() => {
      replace();
  }, [clientPackage, staff, eventPackage])

  const replace = () => {
    let c = content
    c = clientPackage ? replaceAll(c, '\\[clientName\\]', clientPackage.title) : c;
    c = clientPackage && clientPackage.client ? replaceAll(content, '\\[clientPhone\\]', clientPackage.client.phone) : c;
    c = clientPackage ? replaceAll(c, '\\[clientDetails\\]', clientPackage.info) : c;
    c = clientPackage ? replaceAll(c, '\\[clientAttendees\\]', clientPackage.attendees) : c;
    c = staff ? replaceAll(c, '\\[staffName\\]', staff.name) : c;
    c = eventPackage.pakage ? replaceAll(c, '\\[packageName\\]', eventPackage.pakage.name) : c;
    c = replaceAll(c, '\\[date\\]', moment().format("DD.MM.YYYY HH:mm"));
    setContent(c);
  }

  return(
    <div className={'pdf-content'} dangerouslySetInnerHTML={{__html: content }}>
    </div>
  )
};

export default Content;
