import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  padding: {
    padding: 0
  }
}));

export default function PanelMobile() {
  const { t } = useTranslation('support');

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_mobile.presentation.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_mobile.presentation.text1')}
            {/*Aplicatia mobile este disponibila pentru Android sau IOS si se  gaseste sub numele de SnowBuddy. Este destinata*/}
            {/*instructorilor:*/}
            <ul>
              <li>{t('panel_mobile.presentation.text2')}</li>
              {/*<li>pot verifica programul pentru ziua curenta sau zilele urmatoare</li>*/}
              <li>{t('panel_mobile.presentation.text3')}</li>
              {/*<li>primesc notificari cu privire la modificarile din sectiunea "Program"</li>*/}
              <li>{t('panel_mobile.presentation.text4')}</li>
              {/*<li>pot selecta datele disponibile</li>*/}
              <li>{t('panel_mobile.presentation.text5')}</li>
              {/*<li>pot creea un program predefinit pentru disponibilitatea zilnica</li>*/}
            </ul>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_mobile.installation.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_mobile.installation.text1')}
            {/*Aplicatia este gratuita si se poate descarca din App Store sau Google Play.*/}
            {/*Datele de login sunt cele definite in sectiunea "Utilizatori", respectin username si parola*/}
            {/*Dupa logare vor avea 3 optiuni de navigare:*/}
            <ul>
              <li>{t('panel_mobile.installation.text2')}</li>
              {/*<li>programul din data selectata</li>*/}
              <li>{t('panel_mobile.installation.text3')}</li>
              {/*<li>setarea programului predefinit pe zile</li>*/}
              <li>{t('panel_mobile.installation.text4')}</li>
              {/*<li>iesire din cont</li>*/}
            </ul>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_mobile.edit_hours.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_mobile.edit_hours.text1')}
            {/*Daca optiunea "Instructorul poate adauga ore" din sectiunea "Setari" este activa instructorul poate:*/}
            <ul>
              <li>{t('panel_mobile.edit_hours.text2')}</li>
              {/*<li>sa adauge ore noi</li>*/}
              <li>{t('panel_mobile.edit_hours.text3')}</li>
              {/*<li>sa modifice sau sa anuleze orele adaugate de el</li>*/}
            </ul>
            {t('panel_mobile.edit_hours.text4')}
            {/*Instructorul nu va putea sa modifice sau sa anuleze orele venite din sediul central*/}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_mobile.install_app.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_mobile.install_app.text1')}
            {/*Daca aplicatia nu este compatibila cu telefonul instructorului acesta poate verifica programul din aplicatia web, la adresa*/}
            &nbsp;
            <a href="mailto:hello@snowbuddy.app/login ">hello@snowbuddy.app/login</a>
            &nbsp;
            {t('panel_mobile.install_app.text2')}
            {/*unde va fi redirectionat catre contul sau. Daca apar probleme tehnice ne puteti contacta la adresa de email*/}
            &nbsp;
            <a href="mailto:hello@snowbuddy.app?subject=Problema tehnica aplicatie?&body=Salut, Intampinam dificultati in instalarea aplicatiei  %0d%0a%0d%0aUsername: %0d%0aVersiune soft: %0d%0aDescrierea problemei: %0d%0a%0d%0aMultumesc,%0d%0aO zi buna!">hello@snowbuddy.app</a>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
