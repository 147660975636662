import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';

import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Popover from '@mui/material/Popover';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';

import '../../../../node_modules/react-vis/dist/style.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';

import EventPopover from './EventPopover';
import PaidTill from './PaidTill';

import {DATE_FORMAT, DATETIME_FORMAT} from 'react-big-scheduler'

import { useTranslation } from 'react-i18next'

export default function StaffItem(props) {

  const { placeLink } = useParams();
  const { t } = useTranslation('reports');
  const { staff, place, eventPkgs, refreshData, findDto, print, openAll, setWannaEdit } = props;

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openEvents, setOpenEvents] = useState(openAll);
  const [cash, setCash] = useState(0);
  const [card, setCard] = useState(0);
  const [online, setOnline] = useState(0);

  useEffect(() => {
    setOpenEvents(openAll);
  }, [openAll])

  useEffect(() => {
    let ch = 0;
    let cd = 0;
    let on = 0;
    eventPkgs.forEach(eventPkg => {
      eventPkg.clientPackages.forEach(clientPkg => {
        if (clientPkg.paid && (clientPkg.payMethod == 'card' || clientPkg.payMethod == 'staffCard'))
          cd += clientPkg.payAmount;
        else if (clientPkg.paid && clientPkg.payMethod == 'online')
          on += clientPkg.payAmount;
        else if (clientPkg.paid && (clientPkg.payMethod == 'cash' || clientPkg.payMethod ==  'staffCash'))
          ch += clientPkg.payAmount;
        })})
    setCash(ch);
    setCard(cd);
    setOnline(on);
  }, [eventPkgs]);

  // popover
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (e, event) => {
    setSelectedEvent(event);
    setAnchorEl(e.currentTarget);
  };

  const handlePopoverClose = () => {
    setSelectedEvent(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <TableRow
        key={staff.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >

        {!print &&
          <TableCell>
          {eventPkgs.length > 0 && <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpenEvents(!openEvents)}
          >
            {openEvents ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>}
        </TableCell>}

        <TableCell>{staff.name}</TableCell>

        <TableCell>
          {eventPkgs.map((eventPkg, i) => {
            return(
              <div item key={i}
                aria-owns={open ? `mouse-over-popover-${i}` : undefined}
                aria-haspopup="true"

                onMouseLeave={handlePopoverClose}
                style={{float: 'left', margin: '5px'}}>
                <Chip
                  label={`${eventPkg.title}`}
                  color={eventPkg.status == 'PAID' ? 'success' : (eventPkg.status == 'BOOKED' || eventPkg.status == 'CANCELED') ? 'error' : 'info'}
                  variant="outlined">
                </Chip>
              </div>
            )}
          )}
        </TableCell>

        <TableCell align="right">{ cash } / { card } / { online }</TableCell>

        <TableCell align="right">{staff.percent > 0 ? `${staff.percent}% = ${Math.round((cash + card + online) * staff.percent)/100}` :  ``}</TableCell>

        <TableCell align="right">{cash + card + online}</TableCell>

        <TableCell align="right" className={moment(findDto.date).subtract(2, 'days').isBefore(moment(staff.paidTill), 'days') ? '' : 'text-danger text-bold'}>{moment(staff.paidTill).format(DATE_FORMAT)}</TableCell>

        {!print && <TableCell align="right">
          <PaidTill
            staff={staff}
            refreshData={refreshData}
          />
        </TableCell>}

        {selectedEvent && <Popover
          id={`mouse-over-popover`}
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <EventPopover
            event={selectedEvent}/>
        </Popover>}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={openEvents && eventPkgs.length > 0} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <p>
                Pachete
              </p>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">
                      <Grid container>
                        <Grid item xs={3} align="left">{t('item.title')}</Grid>
                        <Grid item xs={3} align="left">{t('package')}</Grid>
                        <Grid item xs={2}>{t('item.status')}</Grid>
                        <Grid item xs={2}>{t('item.from')}</Grid>
                        <Grid item xs={2}>{t('item.till')}</Grid>
                      </Grid>
                    </TableCell>
                    <TableCell align="right">
                      <Grid container>
                        <Grid item xs={3} align="left">{t('item.client')}</Grid>
                        <Grid item xs={2}>{t('item.price')}</Grid>
                        <Grid item xs={2}>{t('item.status')}</Grid>
                        <Grid item xs={2}>{t('item.pay_method')}</Grid>
                        <Grid item xs={3}>{t('item.pay_date')}</Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventPkgs.map((eventPkg) => (
                    <>
                      <TableRow key={eventPkg.id} onClick={() => setWannaEdit(eventPkg)} style={{cursor: 'pointer'}}>
                        <TableCell align="right">
                          <Grid container>
                          <Grid item xs={3} align="left">{eventPkg.title}</Grid>
                          <Grid item xs={3} align="left">{eventPkg.pakage && `${eventPkg.pakage.name}`}{eventPkg.location && ` / ${eventPkg.location}`}</Grid>
                          <Grid item xs={2} className={eventPkg.status == 'PAID' ? 'text-success' : (eventPkg.status == 'BOOKED' || eventPkg.status == 'CANCELED') ? 'text-danger' : 'text-info'} >{eventPkg.status}</Grid>
                          <Grid item xs={2}>{moment(eventPkg.start).format("DD.MM.yyyy")}</Grid>
                          <Grid item xs={2}>{moment(eventPkg.end).format("DD.MM.yyyy")}</Grid>
                          </Grid>
                        </TableCell>
                        <TableCell align="right">
                          {eventPkg.clientPackages.map((clientPkg) => (<Grid container>
                            <Grid item xs={3} align="left">{clientPkg.title}</Grid>
                            <Grid item xs={2}>{clientPkg.payAmount}</Grid>
                            <Grid item xs={2} className={clientPkg.paid ? 'text-success' : 'text-danger text-bold'} >{clientPkg.paid ? 'Achitat' : 'Neachitat'}</Grid>
                            <Grid item xs={2}>{clientPkg.payMethod}</Grid>
                            <Grid item xs={3}>{clientPkg.paid ?
                              `${moment(clientPkg.payDate).format("hh:mm / DD.MM")} ${place && place.placeInfo.invoiceSeries && clientPkg.invoiceNumber ? ' / ' + place.placeInfo.invoiceSeries + '' + clientPkg.invoiceNumber : ''}` : '-'}</Grid>
                            <Grid item xs={12}><Divider /></Grid>
                          </Grid>))}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}
