import React from 'react'
import { Link } from 'react-router-dom'

// assets
import sbmp4 from '../../assets/img/pow-sms.mp4';
import screenadmin from '../../assets/img/home/screen-admin.png'
import screenapp from '../../assets/img/home/screen-app.png'
import './HomePage.scss'

import Copyright from '../../components/general/footer/Copyright.js'

class OldHomePage extends React.Component {

  render() {
    return (
      <div>
        <div class="home-image"></div>
        <div>
          <div class="container">
            <div class="main">
              <section>
                <h3 class="subtitle">
                  SnowBuddy SMS Gateway
                </h3>
                <p>A short video showing pow sms functionality</p>
                <video width="320" height="240" controls>
                  <source src={sbmp4}/>
                </video>
                <p></p>
              </section>
            </div>
            <Copyright/>
          </div>
        </div>
      </div>
      );
    }
  }

  export default OldHomePage;
