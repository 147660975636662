import React, { useState, useEffect } from 'react';
import { MuiPickersUtilsProvider,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import {DATETIME_FORMAT} from './utils/index.js'

import moment from 'moment'

import './scheduler.scss'

import { useTranslation } from 'react-i18next'

export default function EventPopover(props) {

  const { t } = useTranslation('schedule');

  return (
    <div className='header2-text date-nav'>
      <Grid container direction="row" justifyContent="flex-start" alignItems="center">
        <Grid item>
          <i className={'material-icons'} onClick={props.prevClick}>keyboard_arrow_left</i>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              title={t('utils.custom_left_header.calendar')}
              margin="normal"
              id="current-date-picker-dialog"
              format="EEE, dd MMM"
              value={props.findParams.date}
              onChange = {(event,newValue) => {
                var newVal = moment(event).format(DATETIME_FORMAT);
                props.onSelectDate(newVal);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className={'date-nav-picker'}
            />
          </MuiPickersUtilsProvider>
          <i className={'material-icons'} onClick={props.nextClick}>keyboard_arrow_right</i>
        </Grid>
        <Grid item>
          <span className={props.socketConnected ? '' : 'text-error'}>|<CircularProgress style={{float: "left", margin: "0 5px"}} color={props.socketConnected ? 'primary' : 'inherit'} size={20} variant={true ? 'static' : ''} value={100}/> {props.socketConnected ? props.socketLastMessage : `${t('utils.custom_left_header.not_synchronized')}`}</span>
        </Grid>
      </Grid>
    </div>
  );
}
