import React, { useState, useEffect } from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container'
import MenuItem from '@material-ui/core/MenuItem';

import { useTranslation } from 'react-i18next'

var QRCode = require('qrcode.react');

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserOtp(props) {

  let otp = `{"username": "${props.user.phone}", "password": "${props.otpDetails}"}`;

  const { t } = useTranslation('users');

  return (
    <Dialog fullScreen open={props.open} onClose={() => props.handleClose()} TransitionComponent={Transition}>
      <AppBar className={'position-relative'} color="secondary">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={1}>
            <IconButton edge="start" color="inherit" onClick={() => props.handleClose()} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6">
              {t('user_otp.login')} {props.user.name}
            </Typography>
          </Grid>
        </Grid>
      </AppBar>
      <Container maxWidth="md" className={"mt-4"}>
        <Grid container direction="column" justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">
            {t('user_otp.scan1')} "{props.user.name}" {t('user_otp.scan2')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <QRCode value={otp}/>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
            {t('user_otp.timer1')} {props.seconds} {t('user_otp.timer2')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};
