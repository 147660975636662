import { createTheme } from '@material-ui/core/styles'

const defaultTheme = createTheme();

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      light: '#69FFED',
      main: '#42D4C3',
      dark: '#1C877B',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#FF9869',
      main: '#EE6E72',
      dark: '#873E1C',
      contrastText: '#FFFFFF',
    },
    // info: {
    //   light: '#ADDDFF',
    //   main: '#90CDF9',
    //   dark: '#5388AD',
    //   contrastText: '#FFFFFF',
    // },
    error: {
      main: '#EE6E72',
    },
    background: {
      default: '#fff',
    },
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h4',
        subtitle2: 'h5',
        body1: 'p',
        body2: 'span',
      },
    }
  },
  typography: {
    button: {
      fontStyle: 'italic',
      color: '#fff',
    },
  },
  overrides: {
    MuiPaper: {
      root: {
        color: '#333',
        padding: defaultTheme.spacing(3, 2),
      }
    }
  },
});

export default theme;
