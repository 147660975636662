import React, {useState} from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import './style.scss';

import LineSeriesWrap from './LineSeriesWrap';

export default function AreaStats(props) {

  const dateFormat = "DD MMM"
  const [viewDetails, setViewDetails] = useState({x: 0, y: 0});
  const [dataLabelMap, setDataLabelMap] = useState([]);

  //iterate and check if in daysNo interval are empty dates
  const d = (dta) => {
    let data = [];
    let dataLabel = []
    let i = 0;
    let j = 0;

    for (var m = moment(props.date).add(-props.daysNo, 'days'); m.isBefore(moment(props.date).add(1, 'days'), 'day'); m.add(1, 'days')) {
      if(dta.length > 0){
        if (moment(dta[j].x).isSame(m, 'day')){
          data[i] = {x: i, y: dta[j].y, info: dta[j].x};
          j = j < dta.length - 1 ? j += 1 : j;
        }else
          data[i] = {x: i, y: 0};
      }else {
        data[i] = {x: i, y: 0};
      }
      dataLabel[i] = m.format(dateFormat);
      i += 1;
    }

    // set only once data label map otherwise it will iterate 'infinite'
    if(dataLabelMap.length === 0)
      setDataLabelMap(dataLabel);

    return data;
  }

  const [data, setData] = useState(d(props.data));

  return (
    <Grid item lg={props.size}>
      <Grid item lg={12}>
        <Typography variant="h5" component="h3">
          {props.title}
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <LineSeriesWrap
          data={data}
          date={props.date}
          percent={props.percent}
          dataLabelMap={dataLabelMap} />
      </Grid>
    </Grid>
  )
}
