import React, {useState} from 'react';
import moment from 'moment';

import Grid from '@material-ui/core/Grid';
import '../../../../node_modules/react-vis/dist/style.css';
import './style.scss';
import {XYPlot, FlexibleXYPlot, RadialChart, makeVisFlexible, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, Hint} from 'react-vis';

import { useTranslation } from 'react-i18next'

export default function RadialChartWrap(props) {

  const FlexRadialChart=makeVisFlexible(RadialChart)

  const dateFormat = "DD MMM";
  const [viewDetails, setViewDetails] = useState({x: 0, y: 0, label: false});

  const getLabelStats = (l) => {
    for(var i = 0; i < props.data.length; i++)
      if (props.data[i].label == l)
        return props.data[i].angle;
  }

  const nop = () => {};

  const { t } = useTranslation('stats');
  
  return (
    <Grid container>
      <Grid item xs={8} className={"stats-flex radial"}>
      <FlexRadialChart
        data={props.data}
        labelsRadiusMultiplier={1.2}
        labelsAboveChildren={true}
        innerRadius={85}
        radius={120}
        padAngle={0.05}
        onValueMouseOver={(d, info) => {d.label != viewDetails.label ? setViewDetails(d) : nop()}}
        onSeriesMouseOut={() => {console.log('out'); setViewDetails({x: 0, y: 0, label: false})}}
        labelsStyle={{
          fontSize: 12,
          color: 'white'
        }} >
        <Hint value={viewDetails} >
          {viewDetails.label ? <div className={'wrap-hint'}>
            {getLabelStats(viewDetails.label)} {t('radial_chart_wrap.pakage')}{getLabelStats(viewDetails.label) > 1 ? `${t('radial_chart_wrap.e')}` : ''} "{viewDetails.label}"
          </div> : <div></div>}
        </Hint>
      </FlexRadialChart>
      </Grid>
      <Grid item xs={4} className={"stats-flex-p"}>
        {props.data.map((p, i) => {
          return(<p key={i}>
            {p.angle} {t('radial_chart_wrap.pakage')}{p.angle > 1 ? `${t('radial_chart_wrap.e')}` : ' '} "{p.label}"
            </p>)})}
      </Grid>
    </Grid>
  )

}

//colorType="literal" && color "#81654" pt fiecare data item
//data={[ {angle: 1, radius: 10, color: '#615a5f'}, {angle: 2, label: 'Super Custom label', subLabel: 'With annotation', radius: 20}, {angle: 5, radius: 5, label: 'Alt Label'}, {angle: 3, radius: 14}, {angle: 5, radius: 12, subLabel: 'Sub Label only', className: 'custom-class'} ]}
