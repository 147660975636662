import React, {useEffect} from 'react';
import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import axios from 'axios';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(theme => ({
  unconfirmed: {
    background: theme.palette.secondary.light
  }
}));

export default function RequestItem(props) {

  const [redirect, setRedirect] = React.useState(false);
  const [wannaDelete, setWannaDelete] = React.useState(false);

  const { place, req, refreshData, handleAssignRequest } = props;
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { t } = useTranslation('requests');

  useEffect(() => {
    if(req.id === 41551)
        handleAssignRequest(req)
  },[redirect])

  const validate = () => {
    axios.get(process.env.REACT_APP_BUDDY_URL + `/place/${place.link}/event/validate/${req.id}`)
      .then(res => {
        refreshData();
        enqueueSnackbar('Rezervare validata.', {variant: 'success'});
      },
      error => {
        enqueueSnackbar('Eroare la validarea rezervarii. Eroare: ' + error.response.error, {variant: 'error'});
      });
  }

  const deleteRequest = () => {
    axios.post(process.env.REACT_APP_API_URL + `/staff/place/${place.link}/event/request/cancel`, req)
      .then(res => {
        refreshData();
        enqueueSnackbar('Rezervare stearsa.', {variant: 'success'});
      },
      error => {
        enqueueSnackbar('Eroare la stergerea rezervarii. Eroare: ' + error.response.error, {variant: 'error'});
      });
  }

  return (
      <Grid container alignItems="center" className={`p-3`}>
      <Grid item  xs={4} md={2}>
        {moment(req.createdDate).format("DD.MM.YYYY HH:mm")}
      </Grid>
        <Grid item xs={4} md={3}>
          {req.title}
        </Grid>
        <Grid item xs={4} md={3}>
          {req.pakage.name}
        </Grid>
        <Grid item xs={4} md={2}>
          {moment(req.start).format("DD.MM.YYYY")} {moment(req.start).diff(moment(req.end), 'days') != 0 && `-> ${moment(req.end).format("DD.MM.YYYY")}`}
        </Grid>
        {/*req.status == 'PING' ?
          <Tooltip title={t('request_item.unconfirmed_tooltip')}>
            <Grid item xs={3} md={2} className={'text-danger text-right text-bold'}>
               {t('request_item.unconfirmed')}
            </Grid>
          </Tooltip> :
          <Grid item xs={3} md={2}>
             {req.info}
          </Grid>
        */}
        <Grid item xs={4} md={2} className={'text-right'}>
          <Grid container spacing={1} justify="flex-end">
            {!wannaDelete ?
              <>
                <Grid item>
                  {req.status == 'PENDING' ?
                  <Tooltip title={t('request_item.validate')}>
                    <Button variant="outlined" color="primary" onClick={() => validate()}>
                      <CheckIcon  />
                    </Button>
                  </Tooltip> :
                  <Tooltip title={t('request_item.assign')}>
                    <Button variant="outlined" color="primary" onClick={() => handleAssignRequest(req)}>
                      <AssignmentIndOutlinedIcon />
                    </Button>
                  </Tooltip>}
                </Grid>
                <Grid item>
                  <Tooltip title={t('request_item.delete')}>
                    <Button variant="outlined" color="secondary" onClick={() => {
                        setWannaDelete(true);
                        setTimeout(() => {
                          setWannaDelete(false);
                        }, 2000);
                      }}>
                      <DeleteOutlineOutlinedIcon />
                    </Button>
                  </Tooltip>
                </Grid>
              </> :
              <Grid item>
                <Tooltip title={t('request_item.delete')}>
                  <Button variant="outlined" color="secondary" onClick={() => deleteRequest()}>
                    <DeleteOutlineOutlinedIcon /> Sterge
                  </Button>
                </Tooltip>
              </Grid>
            }
          </Grid>
        </Grid>
        {redirect ?
          <Redirect to={
              {pathname: '/place/' + place.link + '/schedule',
              state: {date: req.start, type: req.pakage.type}
            }} /> : ''
        }
      </Grid>
  );
}
