import React, {Component, useState, useEffect} from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom'

import Scheduler, {SchedulerData, ViewTypes, CellUnits, DnDSource, DATE_FORMAT, DATETIME_FORMAT} from 'react-big-scheduler' //'./utils/index.js'
import 'react-big-scheduler/lib/css/style.css'
import './scheduler.scss'
import 'react-big-scheduler/lib/css/antd-globals-hiding-hack.css'
import moment from 'moment'
import SockJsClient from 'react-stomp';
import LinearProgress from '@material-ui/core/LinearProgress';

import {DragDropContextProvider} from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import EventPopover from './EventPopover'
import EventItem from './EventItem'
import CustomLeftHeader from './CustomLeftHeader'
import EventPackage from '../../../components/place/assign/EventPackage';

import { withTranslation } from 'react-i18next'
import { withSnackbar, useSnackbar } from 'notistack';
import withDragDropContext from './utils/withDnDContext.js'

class SchedulePage extends Component{

  constructor(props){
    super(props);

    let schedulerData = new SchedulerData(moment(), ViewTypes.Custom, false, false, {
      customCellWidth: 90,
      cellUnit: CellUnits.Hour,
      eventItemLineHeight: 31,
      schedulerMaxHeight: (window.innerHeight - 160),
      selectedAreaColor: '#42D4C3',
      nonWorkingTimeHeadColor: '#333',
      nonWorkingTimeHeadBgColor: '#f8f9fa',
      nonWorkingTimeBodyBgColor: '#f8f9fa',
      views: [{viewName: 'SKI', viewType: ViewTypes.Custom}, {viewName: 'SNOWBOARD', viewType: ViewTypes.Custom1}],//, {viewName: 'WEEK', viewType: ViewTypes.Custom2}],
      checkConflict: true,
      resourceName : `Instructori`
    }, {
      getCustomDateFunc: this.getCustomDate,
      isNonWorkingTimeFunc: this.getCurrentTime
    });

    schedulerData.localeMoment.locale('en');
    this.state = {
        sData: schedulerData,

        pkgs: [],
        wannaAssign: {eventPackage: {events: [], pakage: {}, clientPackages: []}},

        findParams: {date : moment().format(DATETIME_FORMAT), type: props.securityUser.place.placeInfo.activities[0]},
        leViews: [{viewName: 'SKI', viewType: ViewTypes.Custom}, {viewName: 'SNOWBOARD', viewType: ViewTypes.Custom1}],//, {viewName: 'WEEK', viewType: ViewTypes.Custom2}],
        leViewType: props.securityUser.place.placeInfo.activities[0] == 'ski' ? ViewTypes.Custom : ViewTypes.Custom1,

        isLoading: false,
        socketConnected: false,
        socketLastMessage: `${this.props.t('schedule_page.synchronized_events')}`,
        redirect: false,
    }

    this.getData(schedulerData);

  }

  // View functions | set view window + current hour ------------------------------------------------------------------------------------
  // set view from 9am to 9pm | there may be a bug because the end date is before 1 day
  getCustomDate = (schedulerData, num, date = undefined) => {
      const { viewType } = schedulerData;
      let selectDate = schedulerData.startDate;
      let startHour = this.props ? moment(this.props.securityUser.place.placeInfo.startTime).hour() : 9;
      let endHour = this.props ? moment(this.props.securityUser.place.placeInfo.closeTime).hour() : 22;

      if(date != undefined)
          selectDate = date;

      selectDate = schedulerData.localeMoment(selectDate).startOf('day');

      let startDate = schedulerData.localeMoment(selectDate).hour(startHour).format(DATETIME_FORMAT)
      let endDate = schedulerData.localeMoment(selectDate).subtract(1, 'day').hour(endHour).format(DATETIME_FORMAT)
      let cellUnit = CellUnits.Hour;
      if (viewType == ViewTypes.Custom2){
        cellUnit = CellUnits.Day;
        endDate = schedulerData.localeMoment(selectDate).add(7, 'day').hour(endHour).format(DATETIME_FORMAT)
      }
      return {
          startDate,
          endDate,
          cellUnit};}

  onViewChange = (schedulerData, view) => {
    let type = this.state.findParams.type;
    let end = undefined;
    if (view.viewType != ViewTypes.Custom2)
      type = view.viewType === ViewTypes.Custom1 ? "snowboard" : "ski";
    else
      end = moment(this.state.findParams.date).add(7, 'day').format(DATETIME_FORMAT);
    this.setState(prevState => ({findParams : {...prevState.findParams, viewType: view.viewType, type : type, resourceId: undefined, end: end}}),() => {
      this.getData();
    });}

  getData = (schedulerData) => {
    if (schedulerData == undefined)
      schedulerData = this.state.sData;
    // this will broke schedule view
    // if(this.state.findParams.viewType)
    //   schedulerData.setViewType(this.state.findParams.viewType)
    if(!moment(schedulerData.startDate).isSame(moment(this.state.findParams.date), 'day'))
      schedulerData.setDate(moment(this.state.findParams.date));
    schedulerData.config.customCellWidth = schedulerData.viewType === ViewTypes.Custom2 ? 190 : 90;
    schedulerData.config.startResizable = schedulerData.viewType === ViewTypes.Custom2 ? false : true;
    schedulerData.config.endResizable = schedulerData.viewType === ViewTypes.Custom2 ? false : true;
    schedulerData.localeMoment.locale('en');

    this.setState({isLoading: true})
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.securityUser.place.link + '/event', this.state.findParams)
      .then(res => {
        console.log(this.state.findParams.viewType)

        schedulerData.setResources(res.data.resources);
        schedulerData.setEvents(res.data.events);

        this.setState({sData: schedulerData, loadingRequest : false})},
      error => {this.setState({isLoading: false});});
    axios.get(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.securityUser.place.link + '/packages')
      .then(res => {
        this.setState({pkgs: res.data})
        this.setState({isLoading: false});},
      error => {this.setState({isLoading: false});})
    }

  // highlights the current hour
  getCurrentTime(schedulerData, time){
      const { localeMoment } = schedulerData;
      if(schedulerData.cellUnit === CellUnits.Hour){
          let hour = localeMoment(time).hour();
          if (hour === localeMoment().hour())
              return true;}
      return false;}

    // View events functions | by date | yesterday | tmrw | type | refresh ------------------------------------------------------------------------------------
   prevClick = ()=> {
     let date = this.state.findParams.date;
     this.setState(prevState => ({findParams : {...prevState.findParams, date: moment(date).add(-1, 'days').format(DATETIME_FORMAT)}}),() => {
       this.getData();});}

   nextClick = ()=> {
     let date = this.state.findParams.date;
     this.setState(prevState => ({findParams : {...prevState.findParams, date: moment(date).add(1, 'days').format(DATETIME_FORMAT)}}),() => {
       this.getData();});}

   onSelectDate = (date) => {
     this.setState(prevState => ({findParams : {...prevState.findParams, date: moment(date).format(DATETIME_FORMAT)}}),() => {
       this.getData();});}

   refreshSchedulerData = (res, schedulerData) => {
      this.getData(this.state.sData);
      this.setState(prevState => ({wannaAssign : {eventPackage: {events: [], pakage: {}, clientPackages: []}}}))}

    // Edit events functions | new | click | ops1 | ops2 ------------------------------------------------------------------------------------
   eventClicked = (schedulerData, event) => {
    if (event.eventPackage == null)
      this.setState({wannaAssign: {...event, eventPackage: {resourceId: event.resourceId, start: event.start, end: event.end, status: 'BOOKED', events: [event], pakage: {}, clientPackages: []}}})
    else
      this.setState({wannaAssign: event})};

   newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    if (moment(end).diff(moment(start), 'minutes', true) == 30)
      end = moment(end).add(30, 'minute').format(DATETIME_FORMAT)
    this.setState({wannaAssign: {resourceId : slotId, start : start, end : end, eventPackage: {resourceId: slotId, start: start, end: end, status: 'BOOKED', events: [{start: start, end: end, resourceId: slotId}], pakage: {}, clientPackages: []}}})}

   updateEventStart = (schedulerData, event, newStart) => {
    event.start = newStart;
    this.setState({isLoading: true})
    axios.post(process.env.REACT_APP_RAPI_URL + '/staff/place/' + this.props.securityUser.place.link + '/event/edit', event)
    .then(res => {
      this.setState({isLoading: false})
      this.props.enqueueSnackbar(`${this.props.t('general:notifs.event.success')}`, {variant: 'success'});
    },
    error => {
      this.props.enqueueSnackbar(`${this.props.t('general:notifs.event.error')}` + error.response.data.error, {variant: 'error'});
      this.setState({isLoading: false})});}

  updateEventEnd = (schedulerData, event, newEnd) => {
    event.end = newEnd;
    this.setState({isLoading: true})
    axios.post(process.env.REACT_APP_RAPI_URL + '/staff/place/' + this.props.securityUser.place.link + '/event/edit', event)
    .then(res => {
     this.setState({isLoading: false})
     this.props.enqueueSnackbar(`${this.props.t('general:notifs.event.success')}`, {variant: 'success'});
    },
    error => {
     this.props.enqueueSnackbar(`${this.props.t('general:notifs.event.error')}` + error.response.data.error, {variant: 'error'});
     this.setState({isLoading: false})});}

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    let diff = moment(event.end).diff(moment(event.start), 'hours', true);
    event.start = start;
    event.end = end;
    event.resourceId = slotId;
    this.setState({isLoading: true})
    axios.post(process.env.REACT_APP_RAPI_URL + '/staff/place/' + this.props.securityUser.place.link + '/event/edit', event)
    .then(res => {
      this.setState({isLoading: false})
      this.props.enqueueSnackbar(`${this.props.t('general:notifs.event.success')}`, {variant: 'success'});
    },
    error => {
      this.props.enqueueSnackbar(`${this.props.t('general:notifs.event.error')}` + error.response.data.error, {variant: 'error'});
      this.setState({isLoading: false})});}

    copyEvent = (sData, evnt) => {
      // setWannaAssign({...evnt, id: null, eventPackage: {...evnt.eventPackage, id: null}})
    }

  cancelEvent = (schedulerData, event) => {
    this.setState({isLoading: true})
    axios.post(process.env.REACT_APP_RAPI_URL + '/staff/place/' + this.props.securityUser.place.link + '/event/cancel', event)
    .then(res => {
     this.setState({isLoading: false})
     this.props.enqueueSnackbar(`${this.props.t('general:notifs.event.success')}`, {variant: 'success'});
    },
    error => {
     this.props.enqueueSnackbar(`${this.props.t('general:notifs.event.error')}` + error.response.data.error, {variant: 'error'});
     this.setState({isLoading: false})});}

    // conflicts are handled in backend. call move if the eventy exist or new if event is from dnd list
     conflictOccurred = (schedulerData, action, event, type, slotId, slotName, start, end) => {
      if(event.id > 0)
        this.moveEvent(schedulerData, event, slotId, slotName, start, end);}

    // check is is a real resource ( not a parent )
     slotClickedFunc = (schedulerData, slot) => {
      // if (slot.slotId != this.state.findParams.resourceId)
      //   setFindParams({...findParams, resourceId: slot.slotId});
      // if (slot.parentId == undefined)
      //   setFindParams({...findParams, resourceId: undefined});
    }

     renderRedirect = () => {
      // if (redirect) {
      //   return <Redirect to={redirect} />}
    }

     toggleExpandFunc = (schedulerData, slotId) => {
      console.log('toggle expand')
      schedulerData.toggleExpandStatus(slotId);}

     onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
      schedulerContent.scrollLeft = maxScrollLeft - 10;}

     onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
      schedulerContent.scrollLeft = 10;}

    // Event item && Popover template functions --------------------------------------------------------------------------------------------------------
    render(){

    let { sData, pkgs, findParams, wannaAssign, leViews, leViewType, isLoading, socketConnected, socketLastMessage, redirect  } = this.state;
    let { t, securityUser } = this.props;

    let leftCustomHeader = (
      <CustomLeftHeader
        prevClick={this.prevClick}
        nextClick={this.nextClick}
        findParams={this.state.findParams}
        onSelectDate={this.onSelectDate}
        socketConnected={this.state.socketConnected}
        socketLastMessage={this.state.socketLastMessage} />
    );

    let eventItemPopoverTemplateResolver = (schedulerData, eventItem, title, start, end, statusColor) => {
      return (
        <EventPopover
          event={eventItem}
          cancelEvent={(event) => this.cancelEvent(schedulerData, event)}
          editEvent={(event) => this.eventClicked(schedulerData, event)}
          copyEvent={(event) => this.copyEvent(schedulerData, event)}
          viewDetails={false}/>
      );
    }

    let eventItemTemplateResolver = (schedulerData, event, bgColor, isStart, isEnd, mustAddCssClass, mustBeHeight, agendaMaxEventWidth) => {
      return (
        <EventItem
          event={event}
        />
      )
    }

    return (
        <div>

          <LinearProgress value="100" variant={isLoading ? 'indeterminate' : 'determinate'}/>

          <Scheduler schedulerData={sData}
            prevClick={this.prevClick}
            nextClick={this.nextClick}
            onSelectDate={this.onSelectDate}
            onViewChange={this.onViewChange}
            eventItemClick={this.eventClicked}
            eventItemPopoverTemplateResolver={eventItemPopoverTemplateResolver}
            eventItemTemplateResolver={eventItemTemplateResolver}
            updateEventStart={this.updateEventStart}
            updateEventEnd={this.updateEventEnd}
            moveEvent={this.moveEvent}
            newEvent={this.newEvent}
            onScrollLeft={this.onScrollLeft}
            onScrollRight={this.onScrollRight}
            toggleExpandFunc={this.toggleExpandFunc}
            slotClickedFunc={this.slotClickedFunc}
            conflictOccurred={this.conflictOccurred}
            leftCustomHeader={leftCustomHeader}/>

          {wannaAssign.resourceId && <EventPackage
            open={wannaAssign.resourceId ? true : false}
            event={wannaAssign}
            eventPackage={wannaAssign.eventPackage}
            handleClose={() => this.setState({wannaAssign: {eventPackage: {events: [], pakage: {}, clientPackages: []}}})}
            place={securityUser.place}
            packages={pkgs}
            resources={sData.resources}
            refreshData={() => this.onSelectDate(findParams.date)}/>}

          {process.env.REACT_APP_SOCKET != 'false' ? <SockJsClient url={process.env.REACT_APP_WS_API_URL} topics={[`${process.env.REACT_APP_WS_TOPIC_SCH}/${securityUser.place.link}`]}
            onMessage={(msg) => {
              this.getData();
              this.setState({socketLastMessage: msg})
              }}

            onConnect={ () => { this.setState({socketConnected: true}) } }
            onDisconnect={ () => { this.setState({socketConnected: false}) } } /> : '' }

          {this.renderRedirect}

        </div>
    );
  }
}

export default withSnackbar(withDragDropContext(withTranslation(['schedule', 'general'])(SchedulePage)));
