import React from 'react';
import { useHistory } from 'react-router-dom';

// return to login on 401 or 403
import axios from 'axios';

export default function Header(props){

  let history = useHistory();

  let isRefreshing = false;
  let failedQueue = [];

  const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve();
      }
    });

    failedQueue = [];
  };

  axios.interceptors.response.use(
    response => {
      return response;
    },
    err => {
      const originalRequest = err.config;

      if (err.response.status === 401 && !originalRequest._retry && !originalRequest.url.includes(`/oauth/token`)) {
        if (isRefreshing) {
          return new Promise(function(resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
          .then(token => {
            return axios(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function(resolve, reject) {
          axios
          .post(process.env.REACT_APP_AUTH_URL + `/oauth/token`, {}, {headers: {'Content-Type':'application/x-www-form-urlencoded; charset=utf-8'}})
            .then(({ data }) => {
              processQueue(null);
              resolve(axios(originalRequest));
            })
            .catch(err => {
              processQueue(err);
              reject(err);
              console.log('push: ', process.env.PUBLIC_URL + '/')
              history.push(process.env.PUBLIC_URL + '/')
            })
            .then(() => {
              isRefreshing = false;
            });
          });
        }
        return Promise.reject(err);
      }
    );

  return (
    <div></div>
  );
}
