import React, {useState, useEffect} from 'react';
import moment from 'moment';
import axios from 'axios';
import { useTranslation } from 'react-i18next'

import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Select from '@material-ui/core/Select';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";

import { useSnackbar } from 'notistack';

import {DATE_FORMAT, DATETIME_FORMAT} from 'react-big-scheduler'

export default function FindDtoSearch(props) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let { findDto, updateFindDto, resources } = props;
  const { t } = useTranslation(['place_components', 'requests', 'stats_settings']);
  const statuss = ['BOOKED', 'PAID', 'REQUEST', 'OPEN', 'CANCELED'];

  return (
    <Grid container spacing={2}>
      <Grid item style={{ margin: "auto 0", cursor: "pointer" }}>
        <i className={'material-icons'} onClick={() => updateFindDto({...findDto, date: moment(findDto.date).subtract(1, 'day').format(DATETIME_FORMAT)})}>keyboard_arrow_left</i>
      </Grid>
      <Grid item md={2} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="start-date-picker-dialog"
            label={t('stats_settings.from')}
            autoOk
            disableToolbar
            format="dd/MM/yyyy"
            value={findDto.date}
            onChange = {(event,newValue) => {
              updateFindDto({...findDto, date: moment(event).format(DATETIME_FORMAT)});
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={2} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            margin="normal"
            id="start-date-picker-dialog"
            fullWidth
            label={t('stats_settings.to')}
            autoOk
            disableToolbar
            format="dd/MM/yyyy"
            value={findDto.endDate}
            onChange = {(event,newValue) => {
              updateFindDto({...findDto, endDate: moment(event).format(DATETIME_FORMAT)});
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item style={{ margin: "auto 0", cursor: "pointer" }}>
        <i className={'material-icons'} onClick={() => updateFindDto({...findDto, endDate: moment(findDto.endDate).add(1, 'day').format(DATETIME_FORMAT)})}>keyboard_arrow_right</i>
      </Grid>
      <Grid item md={2} xs={12}>
        <InputLabel htmlFor="evt-staff" className="text-small select-margin">{t('stats_settings.group_by')}</InputLabel>
        <Select
          value={findDto.byPayDate}
          onChange = {(event,newValue) => {
            var newVal = event.target.value;
            updateFindDto({...findDto, byPayDate: newVal})
          }}
          margin="none"
          fullWidth
          inputProps={{
            name: 'pkg-type',
            id: 'pkg-type',
          }}>
          <MenuItem value={false}>{t('stats_settings.real_date')}</MenuItem>
          <MenuItem value={true}>{t('stats_settings.pay_date')}</MenuItem>
        </Select>
      </Grid>
      <Grid item md={2} xs={12}>
        <InputLabel htmlFor="req-evt-status" className="text-small select-margin">{t('stats_settings.status')}</InputLabel>
        <Select
            value={findDto.statuss}
            // value={findDto.statuss ? findDto.statuss : statusLabels}
            // value={findDto.statuss ? findDto.statuss.map((status) => t('stats_settings.status_elements.' + status)) : []}
            onChange = {(event, newValue) => {
            var newVal = event.target.value;
            updateFindDto({...findDto, statuss: newVal});
          }}
          margin="none"
          fullWidth
          multiple
          renderValue={(selected) => selected.join(', ')}
          inputProps={{
            name: 'req-evt-status',
            id: 'req-evt-status',
          }}
          >
          {statuss.map((status) => (
            <MenuItem key={status} value={status}>
              <Checkbox checked={findDto.statuss.indexOf(status) > -1} color="primary"/>
              <ListItemText primary={t('stats_settings.status_elements.' + status)} />
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {false && <Grid item md={3} xs={12}>
        <InputLabel htmlFor="evt-staff" className="text-small select-margin">{t('stats_settings.resources')}</InputLabel>
        <Select
          value={findDto.resourceId != null ? findDto.resourceId : 0}
          onChange = {(event,newValue) => {
            var newVal = event.target.value;
            if (newVal == 0)
              updateFindDto({...findDto, resourceId: null})
            else
              updateFindDto({...findDto, resourceId: newVal})
          }}
          margin="none"
          fullWidth
          inputProps={{
            name: 'pkg-type',
            id: 'pkg-type',
          }}>
            <MenuItem value={0}>{t('stats_settings.all_resources')}</MenuItem>
            {resources.map((val, key) => val.privilege === 'INSTRUCTOR' ? <MenuItem key={key} value={val.id}>{val.name}</MenuItem> : '')}
        </Select>
      </Grid>}
    </Grid>
  );
}

// <MenuItem key={key} value={val.id}>{val.name}</MenuItem> : ''
