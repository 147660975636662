import React, { useState, useEffect } from 'react';
import axios from 'axios';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import RequestList from './RequestList'

import { withSnackbar, useSnackbar } from 'notistack';
import { useTranslation, withTranslation } from 'react-i18next';

export default function RequestsPage(props){

  let { securityUser, resources, packages } = props;
  const { t } = useTranslation(['requests']);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [requests, setRequests] = useState(false);
  // const [packages, setPackages] = useState([]);
  // const [resources, setResources] = useState([]);
  const [wannaAssign, setWannaAssign] = React.useState(false);

  useEffect(() => {
    refreshData();

    // axios.get(process.env.REACT_APP_API_URL + '/staff/place/' + securityUser.place.link + '/packages')
    // .then(res => {
    //   setPackages(res.data)},
    // err => {
    //   enqueueSnackbar(`Eroare la gasirea pachetelor`, {variant: 'error'});});
    //
    // axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + securityUser.place.link + '/users/resources', {})
    // .then(res => {
    //   setResources(res.data)},
    // err => {
    //   enqueueSnackbar(`Eroare la gasirea instructorilor`, {variant: 'error'});});
  }, [])

  const refreshData = () => {
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + securityUser.place.link + '/event/request', {statuss: ['REQUEST']})
      .then(res => {
        setRequests(res.data.eventPackages)});
  }

  return (
    <div>
      <CssBaseline />
      <Container maxWidth="lg">
        <Grid container spacing={3} className={"p-3"}>
          <Grid item xs={12} md={10}>
            <Typography variant="h4">
              {t('assign.title')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <RequestList
              requests={requests}
              place={securityUser.place}
              refreshData={() => refreshData()}
              packages={packages}
              resources={resources}
              wannaAssign={wannaAssign}
              setWannaAssign={setWannaAssign} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
