import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  padding: {
    padding: 0
  }
}));

export default function PanelFront() {
  const classes = useStyles();

  const { t } = useTranslation('support');

  return (
    <div className={classes.root}>

      <Typography variant="h5" className={"p-3"}>
        {t('panel_front.program.title')}
      </Typography>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.program.about')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_front.program.typo_1')}<br />
            {t('panel_front.program.typo_2')}<br />
            {t('panel_front.program.typo_3')}<br />
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.add_class.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_front.add_class.typo_1')}<br />
            {t('panel_front.add_class.typo_2')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.add_pkg.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_front.add_pkg.typo_1')}<br />
            {t('panel_front.add_pkg.typo_2')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.modify_class.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_front.modify_class.typo')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.class_history.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_front.class_history.typo')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Typography variant="h5" className={"p-3"}>
        {t('panel_front.users.title')}
      </Typography>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.users.about')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_front.users.typo')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.modify_user.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_front.modify_user.typo_1')}<br />
            {t('panel_front.modify_user.typo_2')}<br />
            {t('panel_front.modify_user.typo_3')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Typography variant="h5" className={"p-3"}>
        {t('panel_front.pakages.title')}
      </Typography>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.pakages.about')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_front.pakages.typo')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.modify_user.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_front.modify_user.typo_1')}<br />
            {t('panel_front.modify_user.typo_2')}<br />
            {t('panel_front.modify_user.typo_3')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <Typography variant="h5" className={"p-3"}>
      {t('panel_front.statistics.title')}
      </Typography>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_front.statistics.about')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
          {t('panel_front.statistics.typo')}
            <ul>
              <li>{t('panel_front.statistics.li_1')}</li>
              <li>{t('panel_front.statistics.li_2')}</li>
              <li>{t('panel_front.statistics.li_3')}</li>
            </ul>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
