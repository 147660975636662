import React, {useState, useEffect} from 'react';
import axios from 'axios';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import LibraryAddOutlinedIcon from '@material-ui/icons/LibraryAddOutlined';

import PkgList from './PkgList'
import PkgEdit from './PkgEdit'

import { useTranslation, withTranslation } from 'react-i18next'
import { withSnackbar, useSnackbar } from 'notistack';


export default function PackagesPage(props){

  let { securityUser } = props;
  const { t } = useTranslation(['packages', 'general']);

  const [packages, setPackages] = useState([]);
  const [packageToEdit, setPackageToEdit] = useState(undefined);

  // get all pkgs on mount
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + '/staff/place/' + securityUser.place.link + '/packages')
      .then(res => {
        setPackages(res.data)});}, []);

  return (
    <div>
      <CssBaseline />
      <Container maxWidth="lg">
        <Grid container className={"p-3"} spacing={3}>
          <Grid item xs={12} md={10}>
            <Typography variant="h4">
              {t('manage_packages')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} className={"text-right"}>
            <Tooltip title={t('add_pkg')} enterDelay={700}>
              <Button variant="outlined" color="primary" onClick={() => setPackageToEdit({type: securityUser.place.placeInfo.activities, locations: securityUser.place.placeInfo.locations, active: true, pkgType: 'SIMPLE', info: "{\"blocks\":[{\"key\":\"6bcph\",\"text\":\"\",\"type\":\"unstyled\",\"depth\":0,\"inlineStyleRanges\":[],\"entityRanges\":[],\"data\":{}}],\"entityMap\":{}}"})}>
                <LibraryAddOutlinedIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item className={"m-0"}>
          <PkgList
            pkgs={packages}
            setPackageToEdit={setPackageToEdit} />
        </Grid>
        {packageToEdit && packageToEdit.id}
      </Container>

      {packageToEdit && <PkgEdit
        open={packageToEdit ? true : false}
        pakage={packageToEdit}
        setPackages={setPackages}
        handleClose={() => setPackageToEdit(undefined)}
        place={securityUser.place}/>}
    </div>
  );
}
