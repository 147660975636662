import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  padding: {
    padding: 0
  }
}));

export default function PanelSms() {
  const { t } = useTranslation('support');
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_sms.presentation.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_sms.presentation.text1')}
            <ul>
              <li>{t('panel_sms.presentation.text2')}</li>
              <li>{t('panel_sms.presentation.text3')}</li>
            </ul>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_sms.installation.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_sms.installation.text1')}
            <ul>
              <li>{t('panel_sms.installation.text2')}</li>
              <li>{t('panel_sms.installation.text3')}</li>
            </ul>
            {t('panel_sms.installation.text4')}
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_sms.delete_messages.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_sms.delete_messages.text1')}
            <ul>
              <li>[startDate] - {t('panel_sms.delete_messages.text2')}</li>
              <li>[startHour] - {t('panel_sms.delete_messages.text3')}</li>
              <li>[staffName] - {t('panel_sms.delete_messages.text4')}</li>
              <li>[teamName] - {t('panel_sms.delete_messages.text5')}</li>
            </ul>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel className={classes.padding}>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header" >
          <Typography className={classes.heading}>{t('panel_sms.problems.title')}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            {t('panel_sms.problems.text1')}
            &nbsp;
            <a href="mailto:hello@snowbuddy.app?subject=Problema tehnica aplicatie?&body=Salut, Intampinam dificultati in instalarea aplicatiei SnowBuddy SMS %0d%0a%0d%0aUsername: %0d%0aVersiune soft: %0d%0aDescrierea problemei: %0d%0a%0d%0aMultumesc,%0d%0aO zi buna!">hello@snowbuddy.app</a>
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}
