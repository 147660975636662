import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// css related
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import theme from './assets/style/theme';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/style/style.scss';
import favicon from './assets/img/favicon.ico'

import { Helmet } from "react-helmet";
import { SnackbarProvider } from 'notistack';

//bugsnag
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

//internationalization
import i18n from './i18n/i18n'

var bugsnagClient = bugsnag('e3604d6ca24e8d2757e6859acec799f6')
bugsnagClient.use(bugsnagReact, React)
var ErrorBoundary = bugsnagClient.getPlugin('react')

//snackbar style
// const styles = {
//     success: { backgroundColor: '#42D4C3' },
//     error: { backgroundColor: '#EE6E72' },
//     warning: { backgroundColor: '#69FFED' },
//     info: { backgroundColor: '#69FFED' },
// };

const styles = {
    success: { backgroundColor: 'purple' },
    error: { backgroundColor: 'blue' },
    warning: { backgroundColor: 'green' },
    info: { backgroundColor: 'yellow' },
};

console.warn = console.error = () => {};

ReactDOM.render(
  <ThemeProvider theme={theme}>
  <CssBaseline />
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"/>
      <link rel="shortcut icon" href={favicon}/>

      <title>SnowBuddy</title>
      <meta name="description" content="Platforma pentru gestionarea scolilor de schi"/>
      <link rel="canonical" href="" />

    </Helmet>
    <ErrorBoundary>
      <SnackbarProvider maxSnack={5} autoHideDuration={3000} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} classes={{
        variantSuccess: styles.success,
        variantError: styles.error,
        variantWarning: styles.warning,
        variantInfo: styles.info,
        }}>
        <App />
      </SnackbarProvider>
    </ErrorBoundary>
  </div>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
