import React, { useState } from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container'
import MenuItem from '@material-ui/core/MenuItem'

import { EditorState, convertToRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html';
import MUIRichTextEditor from 'mui-rte'

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PlaceTermsEdit(props){

  const { t } = useTranslation(['settings','general']);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let { open, close, place, updatePlaceInfo } = props;

  const [content, setContent] = useState('');

  const save = () => {
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + place.link + '/info', {...place.placeInfo, localTerms: content})
      .then(
        res => {
          console.log('handleEditPlaceInfo request : ', res);
          enqueueSnackbar('Informatii actualizate cu succes.', {variant: 'success'});
          updatePlaceInfo(res.data);
          close();
        },
        error => {
          enqueueSnackbar('Eroare la actualizarea informatiilor. ' + error, {variant: 'error'});
          console.log('handleEditPlaceInfo request : ', error);
        }
      );
  }

  return (
    <Dialog fullScreen open={open} onClose={close} TransitionComponent={Transition}>
      <AppBar className={'position-relative'} color="secondary">
        <Grid container justify="center" alignItems="center">
          <Grid item xs={1}>
            <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6">
              {t('place_terms_edit.edit_terms_conds')}
            </Typography>
          </Grid>
          <Grid item xs={3} className={'text-right'}>
            <Button variant="outlined" className={'white-button'} onClick={save}>
              {t('general:buttons.save')}
            </Button>
          </Grid>
        </Grid>
      </AppBar>

      <Container maxWidth="md" className={"mt-4"}>
        <MUIRichTextEditor
            label={t('place_terms_edit.forum.terms_and_conds')}
            onSave={save}
            onChange = {(event) => {
              setContent(JSON.stringify(convertToRaw(event.getCurrentContent())));
            }}
            value={place.placeInfo.localTerms}
        />
        <br/><br/><br/><br/><br/><br/>
        <Grid item xs={12}>
        {t('place_terms_edit.forum.autocomplete.variables')}
            <ul>
              <li>{t('place_terms_edit.forum.autocomplete.client_name')}</li>
              <li>{t('place_terms_edit.forum.autocomplete.client_phone_number')}</li>
              <li>{t('place_terms_edit.forum.autocomplete.client_attendees')}</li>
              <li>{t('place_terms_edit.forum.autocomplete.client_details')}</li>
              <li>{t('place_terms_edit.forum.autocomplete.staff_name')}</li>
              <li>{t('place_terms_edit.forum.autocomplete.package_name')}</li>
            </ul>
        </Grid>
      </Container>

    </Dialog>
  );
};
