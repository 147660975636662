import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

import { useTranslation } from 'react-i18next'

export default function PkgItem(props) {

  const { t } = useTranslation('packages', 'general');

  return (
    <Grid container justify="flex-start" alignItems="center" className={"p-3"}>
      <Grid item xs={12} md={7} >
        <Typography variant="h6">
          {props.pkg.lordre}. {props.pkg.name}
        </Typography>
      </Grid>
      <Grid item xs={3} md={1}>
        {props.pkg.pkgType}
      </Grid>
      <Grid item xs={3} md={1}>
        {props.pkg.price} {t('pkg_item.currency')}
      </Grid>
      <Grid item xs={3} md={1} >
        {props.pkg.availability} x {props.pkg.timeUnit} {t('pkg_item.time')}
        {/*{props.pkg.availability} x {props.pkg.timeUnit} or{props.pkg.timeUnit > 1 ? 'e' : 'a'}*/}
      </Grid>
      <Grid item xs={3} md={1}>
      </Grid>
      <Grid item xs={3} md={1} className={'text-right'}>
        <Button variant="outlined" color="primary" onClick={() => props.setPackageToEdit(props.pkg)}>
          <EditOutlinedIcon />
        </Button>
      </Grid>
    </Grid>
  );
}
