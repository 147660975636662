import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import moment from 'moment';
import axios from 'axios';

import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import '../../../../node_modules/react-vis/dist/style.css';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';

import StaffItem from './StaffItem';

import {DATE_FORMAT, DATETIME_FORMAT} from 'react-big-scheduler'

import { useTranslation } from 'react-i18next'

export default function StaffList(props) {

  const { placeLink } = useParams();
  const { t } = useTranslation('reports');
  const { data, place, refreshData, findDto, cash, card, online, print, openAll, setOpenAll, setWannaEdit } = props;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {!print && <TableCell>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpenAll(!openAll)}
              >
                {openAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>}
            <TableCell>{t('instructor')}</TableCell>
            <TableCell  sx={{minWidth: '450px'}}>{t('package')}</TableCell>
            <TableCell align="right" sx={{minWidth: '160px'}}>{t('pay_method')}</TableCell>
            <TableCell align="right" sx={{minWidth: '160px'}}>{t('instructor')}</TableCell>
            <TableCell align="right" sx={{minWidth: '160px'}}>{t('total')}</TableCell>
            <TableCell align="right" sx={{minWidth: '160px'}}>{t('paid_till')}</TableCell>
            {!print && <TableCell align="right">{t('paid')}</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
        {data.resources.map((resource, i) => {
          if (!resource.groupOnly){
            return(
              <StaffItem
                staff={resource}
                place = { place }
                eventPkgs={data.events.get(resource.id)}
                findDto={findDto}
                refreshData={refreshData}
                key={i}
                print={print}
                openAll={openAll}
                setWannaEdit={setWannaEdit}/>
            )}
          }
        )}

        <TableRow>
          <TableCell></TableCell>
          {!print && <TableCell></TableCell>}
          <TableCell align="right">{t('total')}</TableCell>
          <TableCell align="right" sx={{minWidth: '160px'}}>{cash} / {card} / {online}</TableCell>
          <TableCell></TableCell>
          <TableCell align="right" sx={{minWidth: '160px'}}>{cash + card + online}</TableCell>
        </TableRow>
        </TableBody>
      </Table>
    </TableContainer>

  )

}
