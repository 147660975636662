import React, { useRef } from 'react';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import './style.scss';

export default function Header(props){
  return(
    <Container maxWidth="lg" className={'pdf-header'}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <img src={process.env.REACT_APP_MAPI_URL + '/public/images/' + props.place.link + '-logo'} alt="logo" width={150} heigh={150}/>
        </Grid>
        <Grid item xs={6} className={'text-right'}>
          <Typography component="h2">{props.place.name}</Typography>
          <Typography component="h4">{props.place.placeInfo.phone}</Typography>
          <Typography component="h4">{props.place.placeInfo.email}</Typography>
          <Typography component="h4">{props.place.placeInfo.address}</Typography>
          <Typography component="h4">{props.place.placeInfo.website}</Typography>
        </Grid>
      </Grid>
    </Container>
  )
};
