import React from 'react';
import axios from 'axios';
import { withSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import UserSchDay from './UserSchDay';

import { useTranslation } from 'react-i18next'

export default function UserSchedule(props){

  const days = [0, 1, 2, 3, 4, 5, 6];
  
  const { t } = useTranslation('users');

  return (
    <Grid container >

      <Grid container>
        <Grid item sm={6}>
          <Typography variant="h5" component="h3">
            {t('user_schedule.default_program')}
          </Typography>
        </Grid>
        <Grid item sm={6} className={"text-right"}>
          <FormControlLabel
            control={
              <Switch
              checked={props.selfSchedule}
              onClick={() => {props.handleSelfSchClick(!props.selfSchedule)}}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />} label={t('user_schedule.supervizor_can_edit')} labelPlacement="start" />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Paper>
          {days.map((day, i) => {
            return(
              <UserSchDay
                day={day}
                key={i}
                schedule={props.schedule}
                handleClick={(day, hour) => {props.handleSchClick(day, hour)}}
              />
            )
          })}
        </Paper>
      </Grid>
    </Grid>
  );
}
