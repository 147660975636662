import React from 'react'
import { Link } from 'react-router-dom'

// assets
import sbmp4 from '../../assets/img/sb.mp4';
import screenadmin from '../../assets/img/home/screen-admin.png'
import screenapp from '../../assets/img/home/screen-app.png'
import './HomePage.scss'

import Copyright from '../../components/general/footer/Copyright.js'

class TermsPage extends React.Component {

  render() {
    return (
      <div class="bg">
        <div class="container">
          <div class="welcome">
            <h2 class="title">
              Termeni si conditii
            </h2>
          </div>
          <div class="main">
            <section>
              <p>SnowBuddy este un produs al SC ISERVIT CBR SRL | CUI : RO32179541 | J12/2688/2013 | contact@iservit.ro</p>
              <p>Acesti termeni si conditii se aplica tuturor celor care doresc sa utilizeze platforma SnowBuddy, disponibila la
              adresa www.snowbuddy.app. Folosind aceasta platforma, se considera faptul ca sunteti de acord cu aceste prevederi.
              In cazul in care nu sunteti de acord cu ele, va rugam insistent sa nu folositi platforma. Ne rezervam dreptul de
              a modifica acesti termeni si conditii oricand dorim, cu conditia de a va notifica cu privire la aceasta.
              Toate modificarile acestor prevederi vor intra in vigoare in momentul in care acestea vor fi postate pe aceasta
              pagina. Va incurajam sa recititi periodic continutul acestei pagini, deoarece acesta influenteaza direct modul in
              care dumneavoastra puteti folosi platforma. Termenii si conditiile prezentate aici nu pot fi anulate sau modificate
              de catre dumneavoastra sub nici o forma.</p>
              <h3 class="subtitle">
                1. SnowBuddy este o platforma neutra
              </h3>
              <p>SnowBuddy este o aplicatie inovativa care doreste sa usureze gestiunea scolilor de schi si snowboard. Pe aceasta cale, mentionam faptul ca SnowBuddy NU este in sine o scoala de schi sau snowboard sau o entitate care poate facilitata activitati in scop recreational si NU se incadreaza la clasificarea "Antrenorii, instructorii si functionarii din domeniul sportului lucreaza cu sportivi amatori sau profesionisti pentru a creste performanta, incurajeaza o participare cat mai mare in sport si organizeaza si oficiaza evenimente sportive conform normelor stabilite".
              SnowBuddy este un bun digital care vine in ajutorul centrelor de schi si snowboard pentru o buna organizare interna a acestora. SnowBuddy, ca parte neutra, nu este implicata si nu va constitui o parte in eventualele tranzactii care se incheie cu ajutorul acestei platforme. Asadar, atragem atentia ca orice tranzactie realizata prin intermediul SnowBuddy va fi instituita strict intre centrele de schi si snowboard si clientii acestora. In consecinta, SnowBuddy nu are control asupra calitatii sau legalitatii tranzactiilor si nu este responsabila nici de abilitatea furnizorilor de a-si respecta angajamentele la standardele stipulate.</p>
              <h3 class="subtitle">
                2. Inregistrare. Apartenenta. Calitatea de membru
              </h3>
              <p>Inregistrarea in cadrul aplicatiei SnowBuddy este disponibila doar entitatilor juridice care indeplinesc conditiile legale de a furniza un serviciu in conformitate cu legislatia actuala si acreditate de catre Scoala Romana de Schi. Entitatea juridica se angajeaza sa respecte termenii si conditiile de utilizare a acestei platforme. Calitatea de membru SnowBuddy nu este transmisibila si nu poate fi vanduta catre alta entitate. Inregistrandu-te in SnowBuddy te angajezi sa completezi profilul cu informatiii adevarate, relevante la momentul actual. Fiecare cont creat este responsabil pentru activitatea acestuia in cadrul aplicatiei. In momentul de fata, crearea unui cont este contra cost. SnowBuddy isi rezerva dreptul a modifica in orice moment termenii si conditiile in ceea ce priveste inregistrarea unui cont.</p>
              <h3 class="subtitle">
                3 Utilizarea
              </h3>
              <p>SnowBuddy ofera un ghid de utilizare care se gaseste in sectiunea "Support & FAQ" din contul utilizatorilor care ofera informatii despre functionalitatile acesteia. SnowBuddy nu este responsabila de abilitatea furnizorilor de a utiliza platforma on-line care se gaseste la adresa "https://snowbuddy.app", aplicatia mobile "SnowBuddy" si aplicatia mobile pentru trimiterea SMS-urilor catre clienti "SnowBuddy SMS", toate aceste procedee fiind prezentate in prealabil.</p>
              <h3 class="subtitle">
                4. Activitati interzise
              </h3>
              <p>Vizitatorii si membrii acestui site nu au voie sa:</p>
              <ul>
                <li>Creeze un cont in numele altei persoane</li>
                <li>Foloseasca contul altui membru</li>
                <li>Ofere servicii ilegale sau intr-un mod ilegal</li>
                <li>Colecteze informatii despre membrii site-ului</li>
                <li>Foloseasca aplicatia in cazul in care contul a fost suspendat permanent sau temporar</li>
                <li>Foloseasca adresari sau cuvinte jignitoare</li>
                <li>Foloseasca informatii neconforme cu realitatea</li>
              </ul>
              <h3 class="subtitle">
                5. Termeni si conditii aditionale
              </h3>
              <p>Inregistrandu-va, sunteti de acord cu faptul ca noi avem dreptul de a introduce termeni si conditii aditionale care pot afecta modul in care dumneavoastra utilizati aplicatia. De asemenea, in cazul in care exista un conflict intre termenii si conditiile de fata si eventualele modificari aditionale, sunteti de acord ca modificarile aditionale au intaietate.</p>
              <h3 class="subtitle">
                6. Informatiile publicate de catre dumneavoastra
              </h3>
              <p>Platforma ofera ofera membrilor sai posibiliatea de a publica informatii in scopul de a promova si informa potentialii clienti in legatura cu serviciul oferit. Fiind de acord cu termenii si conditiile noastre, membrii se angajeaza sa furnizeze doar informatii adevarate si relevante la momentul actual, conforme cu realitatea. Detinatorul contului este singurul resposanbil in ceea ce priveste calitatea informatiilor publicate. SnowBuddy are dreptul dar nu si obligatia de a monitoriza informatiile publicate de catre membrii sai. De asemenea, SnowBuddy nu garanteaza ca informatiile sau identitatiile publicate de catre membrii sai sunt conforme cu realitatea, si astfel sugereaza ca aceste informatii sa fie verificate inainte de a actiona pe baza acestora. Asadar, SnowBuddy nu este responsabila pentru nici o eventuala pierdere suferita pe baza informatiilor publicate de catre membrii sai.</p>
              <p>In momentul in care va inregistrati, sunteti de acord ca informatiile publicate sa:</p>
              <ul>
                <li>Nu contina detalii personale care nu doriti sa fie facute publice</li>
                <li>Nu contina detalii personale ale altor persoane care ar putea duce la incalcarea dreptului la intimitatea personala</li>
                <li>Nu contina informatii care ar putea incalca legislatia in vigoare</li>
                <li>Nu contina informatii care incalca termenii si conditiile site-ului nostru</li>
                <li>Nu incalce drepturile de autor ale altor entitati</li>
                <li>Nu afiseze continut obscen, indecent, pornografic</li>
                <li>Nu foloseasca un limbaj licentios, defaimator, rasist</li>
                <li>Nu contina virusi, troieni, viermi, spyware sau orice fel de software sau limbaj de programare care ar putea interfera cu buna functionare a platformei SnowBuddy</li>
                <li>Nu testeze sau scaneze vulnerabilitatea sau securitatea site-ului nostru</li>
                <li>Nu fie folosite in scopul de a promova produse sau servicii care nu fac parte din obiectul serviciului inregistrat</li>
                <li>Nu contina link-uri catre alte site-uri in afara de cele personale</li>
                <li>Nu responsabilizeze SnowBuddy in nici un fel</li>
              </ul>
              <p>De asemenea, prin crearea unui cont, membrul se angajeaza ca informatiile sunt publicate cu buna credinta, asumandu-si responsabilitatea legala pentru continutul acestora, fara a responsabiliza in nici un fel aplicatia SnowBuddy.</p>
              <h3 class="subtitle">
                7. Frauda
              </h3>
              <p>SnowBuddy poate suspenda temporar sau inchide permanent orice cont care este suspect de desfasurarea unei activitatati frauduloase desfasurate in cadrul sau in legatura directa cu aplicatia SnowBuddy. Acesta masura se poate realiza fara nici un avertisment in prealabil. Actiunile frauduloase se refera, dar nu sunt limitate, la modul de utilizare sau informatiile prezentate prin intermediul platformei.</p>
              <h3 class="subtitle">
                8. Garantarea serviciilor oferite de catre furnizori
              </h3>
              <p>SnowBuddy sublineaza in mod expres faptul ca nu se garanteaza ca informatiile oferite de catre furnizori si toate informatiile aditionale din cadrul acestei aplicatii sunt adevarate, de actualitate, complete sau conforme cu realitatea. Asadar, SnowBuddy nu isi asuma nici un fel de responsabilitate in cazul in care informatiile oferite de catre furnizori nu indeplinesc cerintele dumneavoastra.</p>
              <h3 class="subtitle">
                9. Politica de confidentialitate
              </h3>
              <p>Platforma Plan Me respecta dreptul la confidentialitate a tuturor utilizatorilor sai, indiferent de modul in care aceasta este accesata (desktop sau mobile).</p>
              <h3 class="subtitle">
                10. Dreptul de a respinge sau a elimina membrii
              </h3>
              <p>Ne rezervam dreptul absolut de a respinge sau elimina membrii platformei SnowBuddy, in orice moment, pentru orice motiv sau fara nici un motiv, fara a va notifica in prealabil, cu mentiunea ca informatiile detinute pana in momentul eliminarii vor fi transmise in format electronic. Printre actiunile care ne pot determina sa va inlaturam calitatea de membru al platformei noastre amintim(dar nu sunt limitate la): incalcarea a cel putin unuia dintre termeni sau conditii, administrarea mai multor conturi care se refera la acelasi furnizor, adresare nepotrivita catre alt membru sau catre echipa SnowBuddy, etc …</p>
              <h3 class="subtitle">
                11. Conformitatea legala
              </h3>
              <p>Prin inscrierea in platforma SnowBuddy va asumati faptul ca serviciile pe care dumneavoastra le oferiti respecta in intregime legislatia Romaniei.</p>
              <h3 class="subtitle">
                12. Securitate
              </h3>
              <p>SnowBuddy foloseste practici standard care asigura buna functionare si securitatea informatiilor personale ale membrilor ei. Cu toate ca folosim diferite tehnici de a securiza continutul informational al platformei noastre impotriva accesului neautorizat, nu putem garanta securitatea intregului nostru sistem. De asemenea, nu putem garanta accesul securizat si neintrerupt la platforma noastra, deoarece buna functionare a ei poate fi intrerupta de alti factori care se regasesc in afara ariei noastre de control.</p>
              <h3 class="subtitle">
                12. Politica Cookies
              </h3>
              <p>Platforma SnowBuddy nu foloseste cookie-uri daca utilizatorul nu este logat. In momentul logarii se vor seta cookie-uri necesare sistemului de autentificare si autorizare si anume:</p>
              <ul>
                <li>authToken - identifica sesiunea curenta</li>
                <li>refreshToken - identifica sesiunea</li>
                <li>refreshing - identifica daca sesiunea se poate restabili</li>
              </ul>
              <h3 class="subtitle">
                13. Aspecte finale
              </h3>
              <p>Prezentul acord pentru folosirea platformei SnowBuddy nu poate fi modificat, interpretat sau anulat de catre nici o parte exterioara. El intra in vigoare in momentul in care dumneavoastra ati inceput sa folositi platforma noastra.</p>
              <br/><br/>
            </section>
          </div>
          <Copyright/>
        </div>
      </div>
      );
    }
  }

  export default TermsPage;
