import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';

import { useTranslation, withTranslation } from 'react-i18next'
import { withSnackbar, useSnackbar } from 'notistack';


export default function Package(props){

  const { t } = useTranslation(['requests', 'general', 'package']);
  let { eventPackage, packages, handleChange, selectPackage, place } = props

  const getPackage = id => {for (var key in packages) {if (packages[key].id === id) return packages[key];}}
  const getPackageType = id => {for (var key in packages) {if (packages[key].id === id) return packages[key].type;}}

  const activities = ['ski', 'snowboard', 'kids']

  useEffect(() => {
    // if (eventPackage.pakage.id == undefined && packages != undefined){
      // handleChange('pakage', packages[0]);
      selectPackage(packages[0]);
    // }
  }, [packages])

  // useEffect(() => {
  //   if (eventPackage.type == undefined && eventPackage.pakage.type)
  //     handleChange('type', eventPackage.pakage.type[0]);
  // }, [eventPackage.pakage])

  return (
    <Grid item xs={12}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6">
            {t('title')}: {eventPackage.pakage.name}
          </Typography>
          <Divider />
        </Grid>
        <Grid item md={place.placeInfo.locations.length > 1 ? 4 : 5} xs={12}>
          <InputLabel htmlFor="req-pkg" className="text-small select-margin">{t('assign.label.package')}</InputLabel>
          <Select
            value={eventPackage.pakage.id ? eventPackage.pakage.id : 0}
            onChange={(event) => {
              console.log(event.target.value)
              var pkg = getPackage(event.target.value);
              selectPackage(pkg);
            }}
            margin="none"
            fullWidth
            inputProps={{
              name: 'req-pkg',
              id: 'req-pkg',
            }}
          >
            {packages.map((val, key) => <MenuItem key={key} value={val.id}>{val.availability}x{val.timeUnit}h | {val.name}</MenuItem>)}
          </Select>
        </Grid>
        {place.placeInfo.locations.length > 1 && <Grid item md={2} xs={12}>
          <InputLabel htmlFor="req-evt-location" className="text-small select-margin">Locatie</InputLabel>
          <Select
            value={eventPackage.location ? eventPackage.location : ''}
            onChange = {(event, newValue) => {
              var newVal = event.target.value;
              handleChange('location', newVal);
            }}
            margin="none"
            fullWidth
            inputProps={{
              name: 'req-evt-location',
              id: 'req-evt-location',
            }}
            >
            {place.placeInfo.locations.map((a) => (<MenuItem key={a} value={a}>{a}</MenuItem>))}
          </Select>
        </Grid>}
        <Grid item md={place.placeInfo.locations.length > 1 ? 1 : 2} xs={12}>
          <InputLabel htmlFor="req-evt-type" className="text-small select-margin">{t('for')}</InputLabel>
          <Select
            value={eventPackage.type ? eventPackage.type : ''}
            onChange = {(event, newValue) => {
              var newVal = event.target.value;
              handleChange('type', newVal);
            }}
            margin="none"
            fullWidth
            inputProps={{
              name: 'req-evt-type',
              id: 'req-evt-type',
            }}
            >
            {eventPackage.pakage.type && eventPackage.pakage.type.map((a) => (<MenuItem key={a} value={a}>{a}</MenuItem>))}
          </Select>
        </Grid>
        <Grid item md={2} xs={12}>
          <InputLabel htmlFor="req-evt-status" className="text-small select-margin">{t('status')}</InputLabel>
          <Select
            value={eventPackage.status}
            onChange = {(event, newValue) => {
              var newVal = event.target.value;
              handleChange('status', newVal);
            }}
            margin="none"
            fullWidth
            inputProps={{
              name: 'req-evt-status',
              id: 'req-evt-status',
            }}
            >
            <MenuItem key={''} value={''}></MenuItem>
            <MenuItem key={'BOOKED'} value={'BOOKED'}>{t('assign.status.booked')}</MenuItem>
            <MenuItem key={'PAID'} value={'PAID'}>{t('assign.status.paid')}</MenuItem>
            <MenuItem key={'REQUEST'} value={'REQUEST'}>{t('assign.status.request')}</MenuItem>
            <MenuItem key={'OPEN'} value={'OPEN'}>{t('assign.status.open')}</MenuItem>
            <MenuItem key={'CANCELED'} value={'CANCELED'}>{t('assign.status.canceled')}</MenuItem>
          </Select>
        </Grid>
        <Grid item md={3} xs={12} className={'pow-checkbox'}>
          <FormControlLabel
          style={{margin:0}}
            control={
              <Checkbox
                checked={eventPackage.moreHours}
                onChange={(event, newValue) => {
                  handleChange('moreHours', !eventPackage.moreHours);}}
                color="primary"
              />
            }
            label={t('assign.more_hours')}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
