import React from 'react';

export default function EventItem(props) {
  let background = props.event.eventPackage === null ? '#42D4C3' :
                   props.event.eventPackage.status === 'BOOKED' ? '#EE6E72' :
                   props.event.eventPackage.status === 'REQUEST' ? '#FF9869' :
                   props.event.eventPackage.status === 'BUSY' ? '#5388AD' : '#90CDF9'
  return (
    <div
      className={(props.event.eventPackage === null) ? 'event-item' : 'round-all event-item'}
      key={props.event.id}
      style={{height: '30px', backgroundColor: background}}>

        <span style={{marginLeft: '10px', lineHeight: '30px', maxWidth: '95px', display: 'inline-block', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow:'ellipsis' }}>
          {props.event.eventPackage == null ? props.event.location : props.event.eventPackage.title}</span>

        {props.event.next ? <span style={{marginLeft: '10px', lineHeight: '35px', float: 'right' }}>*</span> : ''}
    </div>
  )
}
