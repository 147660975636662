import React, {useEffect, useState} from 'react';
import { Route, Link, Redirect } from 'react-router-dom'
import SockJsClient from 'react-stomp';
import { useTranslation } from 'react-i18next'

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Badge from '@material-ui/core/Badge';
import TranslateIcon from '@material-ui/icons/Translate';
import FaceIcon from '@material-ui/icons/Face';
import pineapple from '../../../assets/img/brand/med-icon.png'
import i18n from "i18next";
import Cookies from 'js-cookie';


export default function NavBar(props){

  const  { t } = useTranslation('placePage');
  let { base, securityUser, logOut } = props;
  const [notif, setNotif] = useState(false);
  const [heartBeat, setHeartBeat] = useState(false);

  const translateIcon = <TranslateIcon />;
  const userIcon = (<span><FaceIcon/> {securityUser.name}</span>);

  const [selectedLang, setSelectedLang] = useState(localStorage.getItem('language') || 'ro');

  useEffect(() => {
    setSelectedLang(localStorage.getItem('language') || 'ro');
  }, []);

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
    Cookies.set('language', language, { expires: 365 });
    localStorage.setItem('language', language);
  };
  return (
    <div>

      <Navbar bg="light" expand="lg">
        <Navbar.Brand>
          <a href="https://snowbuddy.app/ro/">
            <img
              src={pineapple}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="SnowBuddy logo"
            />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {securityUser.privilege === 'INSTRUCTOR' ? '' :
            <Nav className="mr-auto">
              <Link className="nav-link" to={`${base}/`}>{t('nav.program')}</Link>
              <Link className="nav-link" to={`${base}/clients`}>{t('nav.client')}</Link>
              <Link className="nav-link" to={`${base}/requests`} onClick={() => { setNotif(false) }}>{t('nav.reservations')}</Link>
              <Badge color="secondary" variant="dot" invisible={!notif} className={heartBeat ? 'me-heart' : ''}>
              </Badge>
              <Link className="nav-link" to={`${base}/camps`}>{t('nav.camps')}</Link>
              <Link className="nav-link" to={`${base}/users`}>{t('nav.users')}</Link>
              <Link className="nav-link" to={`${base}/packages`}>{t('nav.pakeges')}</Link>
              <Link className="nav-link" to={`${base}/stats`}>{t('nav.statistics')}</Link>
              <Link className="nav-link" to={`${base}/reports`}>{t('nav.reports')}</Link>
              <Link className="nav-link" to={`${base}/settings`}>{t('nav.settings')}</Link>
            </Nav>
          }

          <Nav className="ml-auto">
            <NavDropdown title={userIcon} id="dropdown-button-user" drop="left">
              {securityUser.privilege === 'INSTRUCTOR' ? '' : <NavDropdown.Item><Link className="nav-link" to={`${base}/support`}>{t('nav.dropdown.support')}</Link></NavDropdown.Item>}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => logOut()}>{t('nav.dropdown.logout')}</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={translateIcon} id="dropdown-button-language" drop="left">
              <NavDropdown.Item onClick={() => handleChangeLanguage('en')} >English</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleChangeLanguage('ro')} >Romanian</NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleChangeLanguage('es')} >Español</NavDropdown.Item>
              {/*<NavDropdown.Item>Romanian</NavDropdown.Item>*/}
            </NavDropdown>
          </Nav>

        </Navbar.Collapse>
      </Navbar>

      {(process.env.REACT_APP_SOCKET != 'false' && securityUser.place.link != '') ? <SockJsClient url={process.env.REACT_APP_WS_API_URL} topics={[`${process.env.REACT_APP_WS_TOPIC_PLACE}/${securityUser.place.link}`]}
        onMessage={(msg) => {
          // TODO get notifs number?
          setNotif(true)
          setHeartBeat(true);
          const timer = setTimeout(() => {
            setHeartBeat(false);
          }, 5500);
          return () => clearTimeout(timer);
          }}
        onConnect={ () => { console.log('place ws connected', `${process.env.REACT_APP_WS_TOPIC_PLACE}/${securityUser.place.link}`) } }
        onDisconnect={ () => { console.log('place ws connection err') } } /> : '' }
    </div>
  );
}
// ref={ (client) => { clientRef = client }}
