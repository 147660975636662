import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import moment from 'moment'

import { useTranslation } from 'react-i18next'

export default function EventPopover(props) {

  const  { t } = useTranslation('schedule');
  const { event } = props;

  return (
    <div style={{width: '300px', padding: '20px'}}>
      <Grid container>
        <Grid item xs={12}>
          <h5>{event.title}</h5>
        </Grid>
        { (event.status === 'BOOKED' || event.status === 'REQUEST' || event.status === 'PAID') ?
          <Grid item xs={12}>
            {event.pakage ? <span>{t('event_popover.has_the_package')} '{event.pakage.name} '</span> : ''}
            <span className={event.paid ? 'text-bold text-success' : 'text-bold text-error'}>
              {event.paid ? `${t('event_popover.paid')} - ${event.price} ${t('event_popover.currency')} ` : `${t('event_popover.unpaid')} `}</span>
            {t('event_popover.for')} <span className="text-bold">{event.attendees} {t('event_popover.persons')}
              {event.attendees === 1 ? `${t('event_popover.a')} ` : `${t('event_popover.e')} `}</span>
              {t('event_popover.phone_number')} {event.phone}.
              {event.bookedByMe ? `${t('event_popover.coach_reservation')} ` : ''} {event.next ? `${t('event_popover.class')}` : ''}
          </Grid>
          : '' }
        <Grid item xs={12}>
          <span className="header1-text">{moment(event.start).format('HH:mm')}</span><span className="help-text" style={{marginLeft: '8px'}}>{moment(event.start).format("DD.MM")}</span><span className="header2-text"  style={{marginLeft: '8px'}}>-</span><span className="header1-text" style={{marginLeft: '8px'}}>{moment(event.end).format('HH:mm')}</span><span className="help-text" style={{marginLeft: '8px'}}>{moment(event.end).format("DD.MM")}</span>
        </Grid>
      </Grid>
    </div>
  );
}
