import React, {useState, useEffect} from 'react';
import moment from 'moment';
import axios from 'axios';
import { useParams } from "react-router-dom";

import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { MuiPickersUtilsProvider,KeyboardDateTimePicker,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import PaymentIcon from '@material-ui/icons/Payment';
import Tooltip from '@material-ui/core/Tooltip';

import {DATE_FORMAT, DATETIME_FORMAT} from 'react-big-scheduler'
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next'

export default function PaidTill(props) {

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  let { staff, refreshData } = props;
  const { placeLink } = useParams();
  const { t } = useTranslation(['reports','general']);

  const [payDetails, setPayDetails] = useState(false);
  const [payTill, setPayTill] = useState(moment().hour(23).minute(0).second(0).format(DATETIME_FORMAT))
  const [wannaPay, setWannaPay] = useState(false);
  const [loading, setLoading] = useState(false);

  const handlePayTill = () => {
    setLoading(true);
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + placeLink + '/users/paid-till', {paidTill: payTill, id: staff.id})
    .then(res => {
      setLoading(false);
      refreshData();
      enqueueSnackbar(`${t('general:notifs.reports.success')}`, {variant: 'success'});
    },
    error => {
      enqueueSnackbar(`${t('general:notifs.reports.error')}` + error.response.errorMsg, {variant: 'error'});
      setLoading(false);
    });
  }

  return (
        <>{wannaPay ?
          <Button variant="contained" color="primary" onClick={handlePayTill}>
            {t('confirm')}
          </Button> :
          <Tooltip title={t('staff_paid')} enterDelay={700}>
            <Button variant="outlined" color="primary" style={{marginLeft: '40px'}} onClick={() => {
                setWannaPay(true);
                setTimeout(() => {
                  setWannaPay(false);
                }, 2000);}}>
              <PaymentIcon />
            </Button>
          </Tooltip>
        }</>
  );
}
