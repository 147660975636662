import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import HomePage from './pages/home/HomePage'
import TermsPage from './pages/home/TermsPage'
import OldHomePage from './pages/home/OldHomePage'
import PlacePage from './pages/place/PlacePage'
import LoginComponent from './pages/account/LoginComponent'
import Header from './components/general/header/Header'

import NotFound from './pages/NotFound'

class App extends React.Component {

  render() {

    return (
      //<Container maxWidth="lg">
        //<Box>
            <div>
              <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Header/>
                <Switch>
                  <Route exact path='/' component={LoginComponent} />
                  <Route path='/place' component={PlacePage} />
                  <Route path='*'> <Redirect to="/" /> </Route>
                </Switch>
              </BrowserRouter>
            </div>
        //<Copyright />
      //</Box>
    //</Container>
    )
  }
}

export default App
