import React, {useState, useEffect} from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MuiPickersUtilsProvider,KeyboardTimePicker,KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import LinearProgress from '@material-ui/core/LinearProgress';
import Divider from '@material-ui/core/Divider';
import PrintIcon from '@material-ui/icons/Print';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { useTranslation, withTranslation } from 'react-i18next'
import { withSnackbar, useSnackbar } from 'notistack';

import ThermoPrint from '../thermoPrint/ThermoPrint';

export default function Package(props){

  const { t } = useTranslation(['requests', 'general']);
  let { resources, place, eventPackage, event, handleSubmit, handleClose, handleFree, handleCancel, loading } = props

  const [askDelete, setAskDelete] = useState(false);

  const getStaff = id => {for (var key in resources) {if (resources[key].id === id) return resources[key];}}

  useEffect(() => {

  }, [])

  return (
    <AppBar className={'position-relative'}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={1}>
          <IconButton edge="start" color="inherit" onClick={() => handleClose()} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">
            {t('assign.title')} {eventPackage.id && eventPackage.id }
          </Typography>
        </Grid>

        {eventPackage.id >0 && <Grid item className={'text-right mr-3'}>
        {askDelete && eventPackage.id > 0 ?
          <Button variant="contained" color="secondary" onClick={() => handleCancel()} disabled={loading}>
            {t('assign.confirm.cancel')}
          </Button> :
          <Tooltip title={t('assign.cancel')} enterDelay={700}>
            <Button variant="outlined" className={'white-button'} onClick={() => {setAskDelete(true); setTimeout(() => {setAskDelete(false)}, 2000);}}>
              <DeleteOutlineOutlinedIcon />
            </Button>
          </Tooltip>}
        </Grid>}

        {event && event.id == undefined &&
        <Grid item className={'text-right mr-3'}>
          <Tooltip title={t('assign.free')} enterDelay={700}>
            <Button variant="outlined" className={'white-button'} disabled={loading} onClick={() => handleFree()}>
              {t("free")}
            </Button>
          </Tooltip>
        </Grid>}

        <Grid item className={'text-right mr-3'}>
          <Button variant="outlined" className={'white-button'} disabled={loading} onClick={() => handleSubmit()} disabled={eventPackage.status == 'CANCELED'}>
            {t('general:buttons.save')}
          </Button>
        </Grid>
      </Grid>
    </AppBar>


  );
}

// <Grid item className={'text-right mr-3'}>
//   <ThermoPrint
//     event={eventPackage}
//     staff={getStaff(eventPackage.resourceId)}
//     place={place} />
// </Grid>
