import React from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField'
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';

import { withSnackbar } from 'notistack';

import { useTranslation, withTranslation } from 'react-i18next'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class UserAdd extends React.Component {

  constructor(props) {
    super(props);
    this.state ={
      user : {
        level : 'instructor',
        type: 'ski',
        name : '',
        username : '',
        password : '',
        email : '',
        phone : '',
        category : '',
        rank : '',
        instructor: true
      },
      userError : {
        name : false,
      },
      loadingRequest: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!prevProps.open && this.props.open) {
      this.setState({ user : {
        level : 'instructor',
        type: 'ski',
        name : '',
        username : '',
        password : '',
        email : '',
        phone : '',
        category : '',
        rank : '',
        instructor: true
      }})
    }
  }

  handleClose() {
    this.props.handleCloseAddUser();
  }

  handleAddUser() {
    if(this.validateModel()){
      // check if username is valid | true - is valid | false - username exist
      this.setState({loadingRequest : true});
      axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.placeLink + '/users/check', {username : this.state.user.username})
        .then(
          res => {
            if (res.data) // if res.data = true - username is available - proceed to add user
              axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.placeLink + '/users', this.state.user)
                .then(
                  res => {
                    console.log('handleAdduser request : ', res);
                    this.props.enqueueSnackbar(`${this.props.t('general:notifs.user.edit_success')}`, {variant: 'success'});
                    this.props.refreshUsers(res.data);
                    this.setState({loadingRequest : false});
                  },
                  error => {
                    this.props.enqueueSnackbar(`${this.props.t('general:notifs.user.add_error')}` + error, {variant: 'error'});
                    this.setState({loadingRequest : false});
                  }
                );
            else{
              this.setState(prevState => ({userError : {...prevState.userError, usernameExist : true, username : true}}));
              this.setState({loadingRequest : false});
            }
          },
          error => {
            this.setState({loadingRequest : false});
          })
    }
  }

  render() {

    const { t } = this.props;

    return (
      <Dialog fullScreen open={this.props.open} onClose={() => this.handleClose()} TransitionComponent={Transition}>
        <AppBar className={'position-relative'} color="primary">
          <Grid container justify="center" alignItems="center">
            <Grid item xs={1}>
              <IconButton edge="start" color="inherit" onClick={() => this.handleClose()} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6">
                {t('user_edit_and_add.add_user')}
              </Typography>
            </Grid>
            <Grid item xs={3} className={'text-right'}>
              <Button variant="outlined" className={'white-button'} disabled={this.state.loadingRequest} onClick={() => this.handleAddUser()}>
                {t('general:buttons.save')}
              </Button>
            </Grid>
          </Grid>
        </AppBar>
        <LinearProgress value="100" variant={this.state.loadingRequest ? 'indeterminate' : 'determinate'} color="primary"/>
        <Container maxWidth="md" className={"mt-4"}>
          <form noValidate>
            <label>{t('user_edit_and_add.type.type_of_user')}</label>
            <Select
              value={this.state.user.level}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({user : {...prevState.user, level : newVal}}))
              }}
              margin="none"
              fullWidth
              inputProps={{
                name: 'usr-level',
                id: 'usr-level',
              }}>
              <MenuItem value={'manager'}>{t('user_edit_and_add.type.manager')}</MenuItem>
              <MenuItem value={'instructor'}>{t('user_edit_and_add.type.coach')}</MenuItem>
            </Select>
            <label>{t('user_edit_and_add.teaching.teaching_what')}</label>
            <Select
              value={this.state.user.type}
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({user : {...prevState.user, type : newVal}}))
              }}
              margin="none"
              fullWidth
              inputProps={{
                name: 'usr-type',
                id: 'usr-type',
              }}>
              <MenuItem value={'ski'}>{t('user_edit_and_add.teaching.ski')}</MenuItem>
              <MenuItem value={'snowboard'}>{t('user_edit_and_add.teaching.snowboarding')}</MenuItem>
            </Select>
            <TextField
              autoFocus
              margin="normal"
              required
              fullWidth
              id="usr-name"
              label={t('user_edit_and_add.name')}
              name="usr-name"
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({user : {...prevState.user, name : newVal}}))
              }}
              error={this.state.userError.name}
              helperText={this.state.userError.name ?  `${t('user_edit_and_add.name_helper_text')}` : ''}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="usr-phone"
              label={t('user_edit_and_add.phone_number')}
              name="usr-phone"
              type="tel"
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({user : {...prevState.user, phone : newVal}}))
              }}
              error={this.state.userError.phone}
              helperText={this.state.userError.phone ? `${t('user_edit_and_add.phone_number_helper_text')}` : ''}
            />

            <Grid container>
              <Grid item xs={9}>
                <Typography variant="h5">
                {t('user_edit_and_add.status.activ')}
                </Typography>
                <Typography variant="body2">
                {t('user_edit_and_add.status.description')}
                </Typography>
                <Divider />
              </Grid>
              <Grid item xs={3} className={"text-right"}>
                <Switch
                  checked={this.state.user.instructor}
                  onClick={() => this.setState(prevState => ({user : {...prevState.user, instructor: !this.state.user.instructor}}))}
                  value="instructor"
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </Grid>
            </Grid>

            {this.state.user.instructor ? <div>
            <TextField
              margin="normal"
              fullWidth
              required
              id="usr-category"
              label={t('user_edit_and_add.category')}
              name="usr-category"
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({user : {...prevState.user, category : newVal}}))
              }}
              error={this.state.userError.category}
              helperText={this.state.userError.category ? `${t('user_edit_and_add.category_helper_text')}` : ''}
            />
            <TextField
              margin="normal"
              fullWidth
              required
              id="usr-rank"
              label={t('user_edit_and_add.rank')}
              name="usr-rank"
              type="number"
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({user : {...prevState.user, rank : newVal}}))
              }}
              error={this.state.userError.rank}
              helperText={this.state.userError.rank ? `${t('user_edit_and_add.rank_helper_text')}` : ''}
            />
            <TextField
              margin="normal"
              fullWidth
              id="usr-percent"
              label={t('user_edit_and_add.percentage')}
              name="usr-percent"
              onChange = {(event,newValue) => {
                var newVal = event.target.value;
                this.setState(prevState => ({user : {...prevState.user, percent : newVal}}))
              }}
            /> </div>: ''}
          </form>
        </Container>
      </Dialog>
    );
  };

  validateModel(){
    let valid = true;
    this.setState({userError : {name : false}});

    if (this.state.user.name.length < 4 || this.state.user.name.length > 254){
      this.setState(prevState => ({userError : {...prevState.userError, name : true}}));
      valid = false;
    }
    if (this.state.user.phone.length < 10 || this.state.user.phone.length > 13){
      this.setState(prevState => ({userError : {...prevState.userError, phone : true}}));
      valid = false;
    }
    if (this.state.user.level === 'instructor' && (this.state.user.category.length < 1 || this.state.user.category.length > 10)){
      this.setState(prevState => ({userError : {...prevState.userError, category : true}}));
      valid = false;
    }
    if (this.state.user.level === 'instructor' && (this.state.user.rank.length < 1 || this.state.user.rank.length > 5)){
      this.setState(prevState => ({userError : {...prevState.userError, rank : true}}));
      valid = false;
    }
    console.log(this.state.user);
    console.log(this.state.userError);
    return valid;
  }

}

export default withSnackbar(withTranslation(['users','general'])(UserAdd));
