import React from 'react';

import Grid from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';

import UserItem from './UserItem'

class UserList extends React.Component {

  render() {
    return (
      <div>
        {this.props.users ? <Grid xs={12}>
          {this.props.users.map((u, i) => {
            return(
              <>
                {(u.level != 'dflt') ?
                <div key={u.username}>
                  <UserItem
                    user={u}
                    key={`${u.username}-item`}
                    onEditUser={() => this.props.onEditUser(u)}
                    onOtpLogin={() => this.props.onOtpLogin(u)}
                    refreshUsers={(users) => this.props.refreshUsers(users)}/>
                  <Divider key={`${u.username}-divider`} />
                </div>
                : ''}
              </>
            )
          })}
        </Grid> :
        <Grid item xs={12} className={"stats-flex text-center"}>
          <CircularProgress color="primary" size={40}/>
        </Grid>}
      </div>
    );
  }
}

export default UserList;
