import React, {useState} from 'react'

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Container from '@material-ui/core/Container'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';

import screenadmin from '../../assets/img/home/screen-admin.png'
import screenapp from '../../assets/img/home/screen-app.png'
import screeniframe from '../../assets/img/home/screen-iframe.png'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PresentPage(props){

  var [step, setStep] = useState(1);
  let maxStep = 4;
  const nextStep = () => {
    if (step === maxStep){
      setStep(1);
      props.handleClose();
    }else {
      setStep(step+1);
    }
  }

  const prevStep = () => {
    if (step != 1)
      setStep(step-1);
  }

  return(
    <Dialog fullScreen open={props.open} onClose={() => props.handleClose()} TransitionComponent={Transition}>
      <Grid item xs={1}>
        <IconButton edge="start" color="inherit" onClick={() => props.handleClose()} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Grid>
      <Container maxWidth="lg" className={'container-present'}>
        <Grid container justify="space-around" alignItems="center" className={'grid-c-present'}>
          <Grid item xs={1}>
            {step === 1 ? '' : <i onClick={prevStep} class="material-icons">keyboard_arrow_left</i>}
          </Grid>
          <Grid item xs={10} className={'full-heigh'}>
            <Slide direction="up" in={step === 1 ? true : false} mountOnEnter unmountOnExit>
              <Grid container direction="row" justify="space-around" alignItems="center" className={'full-heigh'}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Aplicatia are 3 module principale
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <i class="material-icons text-success big">computer</i>
                  <Typography variant="h6">
                    Un modul pentru <span className={'text-error'}>administratori</span>
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <i class="material-icons text-success big">phone_iphone</i>
                  <Typography variant="h6">
                    Un modul pentru <span className={'text-error'}>instructori</span>
                  </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                  <i class="material-icons text-success big">format_shapes</i>
                  <Typography variant="h6">
                    Un modul pentru <span className={'text-error'}>rezervari online</span>
                  </Typography>
                </Grid>
              </Grid>
            </Slide>

            <Slide direction="down" in={step === 2 ? true : false} mountOnEnter unmountOnExit>
              <Grid container direction="row" justify="space-around" alignItems="center" className={'full-heigh'}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Modulul <span className={'text-success'}>administartor</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div class="img">
                      <img src={screenadmin} alt="Screen admin"/>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <h5>Pentru un management excelent al scolilor de schi.</h5>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li><span class="checked">&#10004;</span>Vizualizarea programului</li>
                    <li><span class="checked">&#10004;</span>Gestionarea instructorilor</li>
                    <li><span class="checked">&#10004;</span>Notificari pentru orele noi adaugate</li>
                    <li><span class="checked">&#10004;</span>Managementul pachetelor si al monitor</li>
                    <li><span class="checked">&#10004;</span>Rapoarte si statistici</li>
                  </ul>
                </Grid>
              </Grid>
            </Slide>

            <Slide direction="left" in={step === 3 ? true : false} mountOnEnter unmountOnExit>
              <Grid container direction="row" justify="space-around" alignItems="center" className={'full-heigh'}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Modulul <span className={'text-success'}>instructor</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div class="img">
                      <img src={screenapp} alt="Screen app"/>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <h5>Permite gestionarea individuala a programului printr-o aplicatie disponibila pentru <a href="https://play.google.com/store/apps/details?id=snowbuddy.app">Android</a> si <a href="https://itunes.apple.com/us/app/snowbuddy/id1449318224?ls=1&mt=8">IOS</a></h5>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li><span class="checked">&#10004;</span>Verifica programul</li>
                    <li><span class="checked">&#10004;</span>Adauge sau sa modifica orele proprii</li>
                    <li><span class="checked">&#10004;</span>Primesc notificati cand apare o noua ora sau o modificare</li>
                    <li><span class="checked">&#10004;</span>Seteaza un program predefinit</li>
                  </ul>
                </Grid>
              </Grid>
            </Slide>

            <Slide direction="right" in={step === 4 ? true : false} mountOnEnter unmountOnExit>
              <Grid container direction="row" justify="space-around" alignItems="center" className={'full-heigh'}>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    Modulul <span className={'text-success'}>rezervare online</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div class="img">
                      <img src={screeniframe} alt="Screen iframe"/>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <h5>Permite integrarea unui formular online in pagina web a scolii</h5>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li><span class="checked">&#10004;</span>7 pasi predefiniti pentru a rezerva o ora online</li>
                    <li><span class="checked">&#10004;</span>Notificari prin SMS si email</li>
                    <li><span class="checked">&#10004;</span>Compatibil cu orice platforma web (exceptand retelele de socializare)</li>
                  </ul>
                </Grid>
              </Grid>
            </Slide>

          </Grid>
          <Grid item xs={1}>
            <i onClick={nextStep} class="material-icons">keyboard_arrow_right</i>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  )
}
