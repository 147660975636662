import React from 'react';

import Grid from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';

import ClientItem from './ClientItem'

export default function ClientsList(props){

  let { placeLink, clients, getClient } = props;

  return (
    <div>
    { clients ?
    <Grid xs={12}>
      {clients.map((c, i) => {
        return(
          <div key={i}>
            <ClientItem
              client={c}
              key={i}
              placeLink={placeLink}
              getClient={(client) => getClient(client)}
              />
            <Divider />
          </div>
        )}
      )}
    </Grid> : ''}
  </div>
  );
}
