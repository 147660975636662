import React from 'react';
import axios from 'axios';
import { withSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

import { useTranslation } from 'react-i18next'

export default function UserSchDay(props){

  const { t } = useTranslation('users');

  const daysName = [`${t('days.monday')}`, `${t('days.tuesday')}`, `${t('days.wednesday')}`, `${t('days.thursday')}`, `${t('days.friday')}`, `${t('days.saturday')}`, `${t('days.sunday')}` ];
  const hours = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];

  const contains = (day, hour) => {
    if (props.schedule[day] == undefined)
      console.log(day, hour)
    return props.schedule[day].includes(hour)
  }

  const handleClick = (day, hour) => {
    console.log(day,hour);
  }

  return (
    <Grid item xs={12}>
      <Typography component="h5">
        {daysName[props.day]}
      </Typography>
      <Grid container justify="space-around" direction="row">
        {hours.map((hour, i) => {
          return(
            <Button variant="outlined" className={contains(props.day, hour) ? 'secondary' : 'light'} color="secondary" key={i} onClick={() => {props.handleClick(props.day, hour)}}>
              {hour}
            </Button>
          )
        })}
      </Grid>
    </Grid>
  );
}
