import React from 'react';
import moment from 'moment';
import axios from 'axios';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import {DATETIME_FORMAT} from '../schedule/utils/index.js'

import AreaStats from './AreaStats';
import LineStats from './LineStats';
import RadialStats from './RadialStats';

import { withTranslation } from 'react-i18next'

class StatsPage extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      findParams: {
        statsDaysNo: 30,
        date: moment().format(DATETIME_FORMAT)
      },
      attendeesStats: false,
      eventsStats: false,
      incomeStats: false,
      packagesStats: false
    }
  }

  componentDidMount(){

    // this.setState({eventsStats: [{"x":"2019-10-04","y":2,"label":null,"angle":null},{"x":"2019-10-05","y":1,"label":null,"angle":null},{"x":"2019-10-06","y":2,"label":null,"angle":null},{"x":"2019-10-07","y":1,"label":null,"angle":null}]});
    // this.setState({attendeesStats: [{"x":"2019-10-04","y":13,"label":null,"angle":null},{"x":"2019-10-05","y":1,"label":null,"angle":null},{"x":"2019-10-06","y":6,"label":null,"angle":null},{"x":"2019-10-07","y":1,"label":null,"angle":null}]});
    // this.setState({incomeStats: [{"x":"2019-10-04","y":12.0,"label":null,"angle":null}]});
    // this.setState({packagesStats: [{"x":null,"y":null,"label":"Ora snowboard","angle":5},{"x":null,"y":null,"label":"Ora ski","angle":1}]});

    // axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/stats/events', this.state.findParams)
    // .then(res => {
    //   this.setState({eventsStats: res.data});
    //   console.log(res.data)
    // });
    // axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/stats/attendees', this.state.findParams)
    // .then(res => {
    //   this.setState({attendeesStats: res.data});
    //   console.log(res.data)
    // });
    // axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/stats/income', this.state.findParams)
    // .then(res => {
    //   this.setState({incomeStats: res.data});
    //   console.log(res.data)
    // });
    // axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/stats/packages', this.state.findParams)
    // .then(res => {
    //   this.setState({packagesStats: res.data});
    //   console.log(res.data)
    // });
  }

  render() {

    const { t } = this.props;

    return (
      <Container maxWidth="lg">
        <Grid container spacing={3} className={"p-3"}>
          <Grid item xs={12} md={10}>
            <Typography variant="h4">
              {t('stats_page.statistics')}
            </Typography>
          </Grid>

        </Grid>
        <div className="container">
          <h5>{t('stats_page.greeting')}</h5>
          <h6>{t('stats_page.here_will_be')}</h6>
          <p>{t('stats_page.some_ideas')}</p>
          <ul>
            <li>{t('stats_page.idea_1')}</li>
            <li>{t('stats_page.idea_2')}</li>
            <li>{t('stats_page.idea_3')}</li>
            <li>{t('stats_page.idea_4')}</li>
          </ul>
          <p>{t('stats_page.opinions')} <a href="mailto:hello@snowbuddy.app?subject=Feedback statistici&body=Salut, referitor la statistici noi consideram utile urmatoarele informatii: %0d%0a%0d%0aFeedback general: %0d%0a%0d%0aMultumesc,%0d%0aOzzi buna!">hello@snowbuddy.app</a></p>
        </div>
      </Container>
    );
  }
}


export default withTranslation(['stats'])(StatsPage);

// { this.state.attendeesStats && this.state.eventsStats ? <AreaStats
//   data={this.state.eventsStats}
//   data2={this.state.attendeesStats}
//   date={this.state.findParams.date}
//   daysNo={this.state.findParams.statsDaysNo}
//   title={t('stats_page.taught_classes_participants')}
//   size={12}/> :
//   <Grid item xs={12} className={"stats-flex text-center"}>
//     <CircularProgress color="primary" size={40}/>
//   </Grid>}
//
// { this.state.packagesStats ? <RadialStats
//   data={this.state.packagesStats}
//   date={this.state.findParams.date}
//   daysNo={this.state.findParams.statsDaysNo}
//   title={t('stats_page.pakages')}
//   size={6}/> :
//   <Grid item xs={6} className={"stats-flex text-center"}>
//     <CircularProgress color="primary" size={40}/>
//   </Grid>}
//
// { this.state.incomeStats ? <LineStats
//   data={this.state.incomeStats}
//   date={this.state.findParams.date}
//   daysNo={this.state.findParams.statsDaysNo}
//   percent={0}
//   title={t('stats_page.receipts')}
//   size={6}/> :
//   <Grid item xs={6} className={"stats-flex text-center"}>
//     <CircularProgress color="primary" size={40}/>
//   </Grid>}
//

//
// <LineStats
//   data={data}
//   date={this.state.findParams.date}
//   daysNo={this.state.findParams.statsDaysNo}
//   title={'Ore anulate'}
//   size={6}/>
