import React, {useState, useEffect} from 'react';
import { Route, Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import SockJsClient from 'react-stomp';

import SchedulePage from './schedule/SchedulePage'
import ClientsPage from './clients/ClientsPage'
import EventUserPage from './schedule/user/EventUserPage'
import RequestsPage from './requests/RequestsPage'
import CampsPage from './requests/CampsPage'
import UsersPage from './users/UsersPage'
import PackagesPage from './packages/PackagesPage'
import StatsPage from './stats/StatsPage'
import SettingsPage from './settings/SettingsPage'
import SupportPage from './support/SupportPage'
import ReportsPage from './reports/ReportsPage'
import NavBar from '../../components/general/header/NavBar';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack';

//cookies
import Cookies from 'js-cookie'

import { Menu, MenuItem, Button } from '@material-ui/core'

export default function PlacePage(props){

  const { t } = useTranslation(['placePage']);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [securityUser, setSecurityUser] = useState(undefined);
  const [packages, setPackages] = useState([])
  const [resources, setResources] = useState([])
  const [redirect, setRedirect] = useState('')

  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + `/staff/place`)
      .then(res => {
        setSecurityUser(res.data);
        if (res.data.level.match('instructor'))
          setRedirect({ pathname: '/place/' + res.data.place.link + '/schedule/user', state: { userId: res.data.id } });
        else
          setRedirect('/place/' + res.data.place.link);
      },
      err => {
        //this.handleLogOut();
      });

  }, []);

  useEffect(() => {
    if (securityUser){
      if (securityUser.level.match('admin') || securityUser.level.match('manager')){
        getPackages();
        getResources();
      }

      if (securityUser.level.match('instructor'))
        setRedirect({ pathname: '/place/' + securityUser.place.link + '/schedule/user', state: { userId: securityUser.id } });
      else
        setRedirect('/place/' + securityUser.place.link);
    }
  }, [securityUser])

  const getPackages = () => {
    axios.get(process.env.REACT_APP_API_URL + '/staff/place/' + securityUser.place.link + '/packages')
    .then(res => {
      setPackages(res.data)},
    err => {
      enqueueSnackbar(`Eroare la gasirea pachetelor`, {variant: 'error'});});}

  const getResources = () => {
    axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + securityUser.place.link + '/users/resources', {})
    .then(res => {
      setResources(res.data)},
    err => {
      enqueueSnackbar(`Eroare la gasirea instructorilor`, {variant: 'error'});});}

  const handleLogOut = () => {
    axios.get(process.env.REACT_APP_AUTH_URL + `/logmeout`)
      .then(res => {
        enqueueSnackbar('Deconectare cu succes.', { variant: 'success' })
        setRedirect('/');
        setSecurityUser(undefined);
      },
        error => {
          enqueueSnackbar('Deconectare esuata.', { variant: 'error' })
        });
  }

  const renderRedirect = () => {
    if ((redirect !== '' && props.location.pathname === '/place') || redirect === '/')
      return <Redirect to={redirect} />
  }

  const PrivateRoute = process.env.REACT_APP_PROFILE == "prod" ?
  ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      (securityUser.level.match('admin|manager'))
        ? <Component {...props}
            securityUser={securityUser}
            packages={packages}
            resources={resources} />
        : <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
    )} />
  ) :
  ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      <Component {...props}
        securityUser={securityUser}
        packages={packages}
        resources={resources} />
    )} />
  )

  const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
      (securityUser.level.match('admin|manager|instructor'))
        ? <Component {...props}
            securityUser={securityUser}
            packages={packages} />
        : <Redirect to={{
          pathname: '/',
          state: { from: props.location }
        }} />
    )} />
  )

  return (
    <>{securityUser && <div className={'bg'} collapseOnSelect>

      <NavBar
        base={redirect}
        securityUser={securityUser}
        logOut={() => handleLogOut()} />

      <PrivateRoute path={`/place/:placeLink`} exact component={SchedulePage} />
      <PrivateRoute path={`/place/:placeLink/clients`} exact component={ClientsPage} />
      <PrivateRoute path={`/place/:placeLink/requests`} exact component={RequestsPage} />
      <PrivateRoute path={`/place/:placeLink/camps`} exact component={CampsPage} />
      <ProtectedRoute path={`/place/:placeLink/schedule/user`} exact component={EventUserPage} />
      <PrivateRoute path={`/place/:placeLink/users`} exact component={UsersPage} />
      <PrivateRoute path={`/place/:placeLink/packages`} exact component={PackagesPage} />
      <PrivateRoute path={`/place/:placeLink/stats`} exact component={StatsPage} />
      <PrivateRoute path={`/place/:placeLink/settings`} exact component={SettingsPage} />
      <PrivateRoute path={`/place/:placeLink/support`} exact component={SupportPage} />
      <PrivateRoute path={`/place/:placeLink/reports`} exact component={ReportsPage} />

      {renderRedirect()}

    </div>}</>
  );
}
