import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';

import moment from 'moment'

import { useTranslation } from 'react-i18next'

export default function EventPopover(props) {

  const  { t } = useTranslation('schedule');

  const [deleteEvent, setDeleteEvent] = useState(false);

  return (
    <div style={{width: '300px'}}>
      <Grid container>
        <Grid item xs={12}>
          <h5>{props.event.eventPackage == null ? props.event.location : props.event.eventPackage.title}</h5>
        </Grid>
        { props.event.eventPackage != null ?
          <Grid item xs={12}>
            {props.event.eventPackage.pakage ? <span> {props.event.eventPackage.pakage.name} </span> : ''}
            <span className={props.event.eventPackage.status == 'PAID' ? 'text-bold text-success' : 'text-bold text-error'}>
              / {props.event.eventPackage.status == 'PAID' ? `${t('event_popover.paid')} ` : `${t('event_popover.unpaid')} `}</span>
              / {props.event.eventPackage.attendees} {t('event_popover.persons')}
              {props.event.location && <span> / {props.event.location}<br/></span> }
              {props.event.eventPackage.next ? ` / ${t('event_popover.class')}` : ''}
          </Grid>
          : '' }
        <Grid item xs={12}>
          <span className="header1-text">{moment(props.event.start).format('HH:mm')}</span><span className="help-text" style={{marginLeft: '8px'}}>{moment(props.event.start).format("DD.MM")}</span><span className="header2-text"  style={{marginLeft: '8px'}}>-</span><span className="header1-text" style={{marginLeft: '8px'}}>{moment(props.event.end).format('HH:mm')}</span><span className="help-text" style={{marginLeft: '8px'}}>{moment(props.event.end).format("DD.MM")}</span>
        </Grid>
        {props.event.status == 'FREE' && <Grid item xs={12}>
          <span className="sch-popup-btn" onClick={() => {props.editEvent(props.event)}}>{t('event_popover.edit_btn')}</span>
          {
            deleteEvent ?
            <span className={'text-bold'}>{t('event_popover.sure')}  <span className="sch-popup-btn text-error" onClick={() => {props.cancelEvent(props.event)}}> {t('event_popover.yes')} </span>
                              <span className="sch-popup-btn" onClick={() => {setDeleteEvent(false)}}> {t('event_popover.no')} </span>
            </span> :
            <span className="sch-popup-btn text-error" onClick={() => {props.event.status == 'FREE' ? props.cancelEvent(props.event) : setDeleteEvent(true)}}>{t('event_popover.cancel_btn')}</span>
          }
        </Grid>}
      </Grid>
    </div>
  );
}

// long descr. text
// { props.event.eventPackage != null ?
//   <Grid item xs={12}>
//     {props.event.eventPackage.pakage ? <span>{t('event_popover.has_the_package')} {props.event.eventPackage.pakage.name} </span> : ''}
//     <span className={props.event.eventPackage.status == 'PAID' ? 'text-bold text-success' : 'text-bold text-error'}>
//       {props.event.eventPackage.status == 'PAID' ? `${t('event_popover.paid')} ` : `${t('event_popover.unpaid')} `}</span>
//       {t('event_popover.for')} {props.event.eventPackage.attendees} {t('event_popover.persons')}{props.event.eventPackage.attendees === 1 ? `${t('event_popover.a')} ` : `${t('event_popover.e')} `}
//       {props.event.location && <span> la {props.event.location}<br/></span> }
//       {props.event.eventPackage.next ? `${t('event_popover.class')}` : ''}
//   </Grid>
//   : '' }

// edit / delete btn
// <Grid item xs={12}>
//   <span className="sch-popup-btn" onClick={() => {props.editEvent(props.event)}}>{t('event_popover.edit_btn')}</span>
//   {
//     deleteEvent ?
//     <span className={'text-bold'}>{t('event_popover.sure')}  <span className="sch-popup-btn text-error" onClick={() => {props.cancelEvent(props.event)}}> {t('event_popover.yes')} </span>
//                       <span className="sch-popup-btn" onClick={() => {setDeleteEvent(false)}}> {t('event_popover.no')} </span>
//     </span> :
//     <span className="sch-popup-btn text-error" onClick={() => {props.event.status == 'FREE' ? props.cancelEvent(props.event) : setDeleteEvent(true)}}>{t('event_popover.cancel_btn')}</span>
//   }
// </Grid>
