import React from 'react';
import axios from 'axios';

import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { withSnackbar, useSnackbar } from 'notistack';

import UserList from './UserList'
import UserAdd from './UserAdd'
import UserEdit from './UserEdit'
import UserOtp from './UserOtp'

import { withTranslation } from 'react-i18next'

class UsersPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      users: false,
      onAddUserModal: false,
      onEditUserModal: false,
      onDeleteUserModal: false,
      onOtpModal: false,
      userToEdit: {},
      otpDetails: '',
      otpSeconds: 120,
      interval: null
    }
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/users')
      .then(res => {
        this.setState({users : res.data});
      });
  }

  refreshUsers = (newUsers) => {
    this.setState({users : newUsers, onAddUserModal : false, onEditUserModal : false, onDeleteUserModal: false});
  }

  onAddUser(){
    this.setState({onAddUserModal : true});
  }

  onEditUser(u) {
    this.setState({userToEdit : u, onEditUserModal : true});
  }

  onOtpLogin(u){
    if (this.state.userToEdit == u && this.state.otpSeconds > 0)
      this.setState({onOtpModal: true})
    else
      axios.post(process.env.REACT_APP_API_URL + '/staff/place/' + this.props.match.params.placeLink + '/users/otp', u)
        .then(res => {
          this.setState({otpDetails: res.data, userToEdit: u, onOtpModal: true, otpSeconds: 120});
          clearInterval(this.state.interval);
          var interval = setInterval(() => {
            if(this.state.otpSeconds > 0)
              this.setState({otpSeconds: this.state.otpSeconds - 1, interval: interval})
            else {
              clearInterval(this.state.interval);
              this.setState({otpSeconds: 120, onOtpModal: false, otpDetails: '', interval: null})
            }
          }, 1000);
        },
        error => {
          this.props.enqueueSnackbar(`${this.props.t('general:notifs.user.otp')}` + error.response.data.error, {variant: 'error'});
        });
  }

  render() {

    const { t } = this.props;

    return (
      <div>
        <CssBaseline />
        <Container maxWidth="lg">
          <Grid container className={"m-0 p-3"}>
            <Grid item xs={12} md={10}>
              <Typography variant="h4">
                {t('users_page.manage_users')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} className={"text-right"}>
              <Tooltip title={t('users_page.add_user')} enterDelay={700}>
                <Button variant="outlined" color="primary" onClick={() => this.onAddUser()}>
                  <PersonAddOutlinedIcon />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item className={"m-0"}>
            <UserList
              users={this.state.users}
              onEditUser={(u) => this.onEditUser(u)}
              onOtpLogin={(u) => this.onOtpLogin(u)}
              refreshUsers={(newUsers) => this.refreshUsers(newUsers)} />
          </Grid>
        </Container>

        <UserAdd
          open={this.state.onAddUserModal}
          refreshUsers={(newUsers) => this.refreshUsers(newUsers)}
          handleCloseAddUser={() => this.setState({onAddUserModal : false})}
          placeLink={this.props.match.params.placeLink}/>

        <UserEdit
          open={this.state.onEditUserModal}
          refreshUsers={(newUsers) => this.refreshUsers(newUsers)}
          handleCloseEditUser={() => this.setState({onEditUserModal : false})}
          placeLink={this.props.match.params.placeLink}
          user={this.state.userToEdit}/>

        <UserOtp
          open={this.state.onOtpModal}
          handleClose={() => this.setState({onOtpModal : false, otpSeconds: 0})}
          otpDetails={this.state.otpDetails}
          user={this.state.userToEdit}
          seconds={this.state.otpSeconds} />

      </div>
    );
  }
}

export default withSnackbar(withTranslation(['users', 'general'])(UsersPage));
